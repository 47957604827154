import { MouseEventHandler } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { AppBar } from '../components/appbar/AppBar';
import { BrowserCompatibility } from '../components/browser-compatibility/BrowserCompatibility';
import { CompositionPanel } from '../components/composition-panel/CompositionPanel';
import { LeftSidebar } from '../components/left-sidebar/LeftSidebar';
import { Modals } from '../components/modals/Modals';
import { PlaybackAndActionsPanel } from '../components/playback-and-actions-panel/PlaybackAndActionsPanel';
import { Popups } from '../components/popups/Popups';
import { RendererPanel } from '../components/renderer-panel/RendererPanel';
import { RightSidebar } from '../components/right-sidebar/RightSidebar';
import { AppState } from '../redux/AppState';
import { deselectAllLayersThunk } from '../redux/thunks/layersThunk';

export const StudioPage = () => {
  const spaceDown = useSelector((state: AppState) => state.app.spaceDown);
  const dispatch = useDispatch();

  const noDeselect = ['element', 'element-controls', 'focus-layer', 'react-contexify', 'no-deselect-layer'];

  const checkIfMeOrParentHasClass: any = (node: any, lookingFor: string[]) => {
    const classList = node.classList;
    if (!classList) return false;

    let found = false;
    for (let i = 0; i < lookingFor.length; i++) {
      found = classList.contains(lookingFor[i]);
      if (found) {
        break;
      }
    }

    if (found) {
      return true;
    } else {
      const parent = node.parentNode;
      if (parent) {
        return checkIfMeOrParentHasClass(parent, lookingFor);
      } else {
        return false;
      }
    }
  };

  const handleMouseDown: MouseEventHandler<HTMLDivElement> = (e) => {
    if (!checkIfMeOrParentHasClass(e.target, noDeselect) && !spaceDown) {
      dispatch(deselectAllLayersThunk());
    }
  };

  return (
    <div className="app-layout" onMouseDown={handleMouseDown}>
      <Helmet>
        <title>Loopic App | Start creating CasparCG HTML graphics</title>
      </Helmet>
      <Modals />
      <Popups />
      <BrowserCompatibility />
      <AppBar />
      <RendererPanel />
      <PlaybackAndActionsPanel />
      <LeftSidebar />
      <RightSidebar />
      <CompositionPanel />
    </div>
  );
};
