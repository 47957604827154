import classNames from 'classnames';
import React, { useState } from 'react';
import arrowDown from '../../../img/icons/dropdown-arrow.svg';
import { SearchBox } from './SearchBox';

export type DropdownItem = {
  id?: string | number;
  value: string;
  highlighted?: boolean;
};

type PropsType = {
  allItems: DropdownItem[];
  hideSearch?: boolean;
  selectedItemId?: string | number;
  onClick: (id: string | number | undefined) => void;
  placeholder: string;
  maxResults?: number;
  dropdownNo: number;
  noneItem: DropdownItem;
  error?: string | false;
};

const Dropdown = (props: PropsType) => {
  const [query, setQuery] = useState('');
  const [isOpen, setOpen] = useState(false);

  function toggle() {
    setOpen(!isOpen);
    setQuery('');
  }

  function handleSearch(e: React.ChangeEvent<HTMLInputElement>) {
    const query = e.target.value;
    setQuery(query);
  }

  const filterForMe = () => {
    let counter = 0;
    const filtered = props.allItems.filter((item) => {
      const check = item.value.toUpperCase().search(query.toUpperCase());

      if (check >= 0) {
        if (props.maxResults) {
          ++counter;
          if (counter - 1 <= props.maxResults) {
            return true;
          } else {
            return false;
          }
        }
        return true;
      }
      return false;
    });

    return filtered;
  };

  return (
    <div
      className={classNames({
        dropdown: true,
        'dropdown-search': true,
        open: isOpen,
        closed: !isOpen,
        error: props.error,
      })}
    >
      <ResultsItem
        data={props.allItems.find((item) => item.id === props.selectedItemId) || props.noneItem}
        onClick={toggle}
        selected={true}
      />

      {isOpen && (
        <div className="dropdown-search__rest">
          {!props.hideSearch && (
            <SearchBox value={query} onChange={handleSearch} size="s" placeholder={props.placeholder} onBlur={() => toggle()} />
          )}
          <div className="search-results" style={{ maxHeight: props.dropdownNo * 2.8 + 'rem' }}>
            {filterForMe().map((item) => (
              <ResultsItem
                key={item.id}
                highlighted={item.highlighted}
                data={item}
                onClick={(id) => {
                  props.onClick(id);
                  toggle();
                }}
                selected={false}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

type ResultsDropdownItem = {
  data: DropdownItem;
  selected: boolean;
  highlighted?: boolean;
  onClick: (id: string | number | undefined) => void;
};

const ResultsItem = (props: ResultsDropdownItem) => {
  return (
    <div
      className={classNames({ 'search-results__item': true, highlighted: props.highlighted })}
      onClick={(e) => {
        props.onClick(props.data.id);
      }}
      onMouseDown={(e) => e.preventDefault()}
    >
      <div className="label">
        <span>{props.data.value}</span>
      </div>
      {props.selected && (
        <div className="arrow">
          <img src={arrowDown} alt="Dropdown menu" />
        </div>
      )}
    </div>
  );
};

export { Dropdown };
