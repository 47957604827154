import { Properties } from '../../models/composition';
import { PropertyId } from '../PropertySettingsService';

export class ClassicCSSConstructor {
  properties;

  constructor(properties: Properties) {
    this.properties = properties;
  }

  getValue(frame: number, propertyId: PropertyId) {
    const property = this.properties[propertyId];
    if (!property) return;

    const value = property.calculatedKeyframes && property.calculatedKeyframes[frame] && property.calculatedKeyframes[frame].value;
    return value;
  }

  getValueWithoutRepeat(frame: number, propertyId: PropertyId) {
    const currentValue = this.getValue(frame, propertyId);
    if (frame > 0) {
      const prevValue = this.getValue(frame - 1, propertyId);
      if (prevValue !== currentValue) {
        return currentValue;
      } else {
        return;
      }
    } else {
      return currentValue;
    }
  }

  getSmartValue(frame: number, propertyId: PropertyId, withoutRepeat: boolean) {
    if (withoutRepeat) {
      return this.getValueWithoutRepeat(frame, propertyId);
    }
    return this.getValue(frame, propertyId);
  }

  setCalculatedStylesheetSmartValue(frame: number, calculatedStylesheet: any, forExport: boolean) {
    for (const propId in this.properties) {
      const propertyId = propId as PropertyId;
      const property = this.properties[propertyId];
      if (!property) break;

      const shouldSkip = ['filterBlur', 'filterBrightness', 'filterContrast', 'filterGrayscale', 'scaleX', 'scaleY'];
      if (shouldSkip.indexOf(propertyId) > -1) {
        continue;
      }

      const value = this.getSmartValue(frame, propertyId, forExport);

      if (typeof value !== 'undefined') {
        let valueToSet = value;
        
        /**
         * Fixes font family bug.
         * If the font family contains multiple words, it must be under quotes!
         */
        if (propertyId === "fontFamily") {
          valueToSet = `"${valueToSet}"`;
        }

        let propertyIdToSet: string = propertyId;

        if (forExport) {
          if (propertyId === 'width' || propertyId === 'height' || propertyId === 'fontSize') {
            valueToSet = valueToSet + 'px';
          } else if (propertyId === 'x') {
            propertyIdToSet = 'left';
            valueToSet = valueToSet + 'px';
          } else if (propertyId === 'y') {
            propertyIdToSet = 'top';
            valueToSet = valueToSet + 'px';
          }
        }
        calculatedStylesheet[frame] = { ...calculatedStylesheet[frame], [propertyIdToSet]: valueToSet };
      }
    }
  }
}
