import { useDispatch, useSelector } from 'react-redux';
import { duplicateLayersThunk } from '../../../redux/thunks/layersThunk';
import { ImportService } from '../../../services/ImportService';
import { NewProjectDemo } from '../../../data/NewProjectDemo';
import { carefullyLoadProjectThunk } from '../../../redux/thunks/projectThunk';
import { setPopupAC, toggleShowTutorialAC } from '../../../redux/reducers/appReducer';
import { MenuBarItem } from './MenuBarItem';
import { redoThunk, saveAsThunk, saveThunk, undoThunk } from '../../../redux/thunks/keyboardShortcutsThunk';
import { isProjectRedoableSelector, isProjectUndoableSelector } from '../../../redux/selectors/projectSelector';

export const MenuBar = () => {
  const undoImpossible = useSelector(isProjectUndoableSelector);
  const redoImpossible = useSelector(isProjectRedoableSelector);
  const dispatch = useDispatch();

  return (
    <ul className="dropdown-menubar to-right">
      <MenuBarItem label="File">
        <MenuBarItem
          label="New"
          onClick={() => {
            dispatch(carefullyLoadProjectThunk(NewProjectDemo));
          }}
        />
        <MenuBarItem
          label="Open"
          onClick={() => {
            ImportService.handleImportProject(dispatch);
          }}
        />
        <MenuBarItem
          label="Save"
          shortcut="CTRL+S"
          onClick={() => {
            dispatch(saveThunk());
          }}
        />
        <MenuBarItem
          label="Save As"
          shortcut="CTRL+SHIFT+N"
          onClick={() => {
            dispatch(saveAsThunk());
          }}
        />
      </MenuBarItem>

      <MenuBarItem label="Edit">
        <MenuBarItem
          label="Undo"
          shortcut="CTRL+Z"
          disabled={undoImpossible}
          onClick={() => {
            dispatch(undoThunk());
          }}
        />
        <MenuBarItem
          label="Redo"
          shortcut="CTRL+Y"
          disabled={redoImpossible}
          onClick={() => {
            dispatch(redoThunk());
          }}
        />
        <MenuBarItem label="Duplicate layer(s)" onClick={() => dispatch(duplicateLayersThunk())} />
      </MenuBarItem>

      {/* <MenuBarItem label="Settings">
        <MenuBarItem label="CasparCG Exporter" onClick={() => dispatch(setPopupAC({ popupId: 'casparcgExporterSettings', open: true }))} />
      </MenuBarItem> */}

      <MenuBarItem label="Help">
        <MenuBarItem label="Start tutorial" onClick={() => dispatch(toggleShowTutorialAC(true))} />
        <MenuBarItem label="Keyboard shortcuts" onClick={() => dispatch(setPopupAC({ popupId: 'keyboardShortcuts', open: true }))} />
        <MenuBarItem label="Documentation" onClick={() => (window as any).open('https://www.loopic.io/docs', '_blank').focus()} />
        <MenuBarItem label="About" onClick={() => dispatch(setPopupAC({ popupId: 'about', open: true }))} />
      </MenuBarItem>
    </ul>
  );
};
