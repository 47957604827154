type PropsType = {
  error?: string | false;
  children: React.ReactNode;
};

export const InputErrorWrapper = (props: PropsType) => {
  return (
    <div className="input-error-wrapper">
      {props.children}
      {props.error && (
        <div className="error-tooltip">
          <div className="triangle" />
          <div className="content">
            <div className="icon"></div>
            <div className="message">{props.error}</div>
          </div>
        </div>
      )}
    </div>
  );
};
