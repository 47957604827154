import classNames from 'classnames';
import { Tooltip } from '../tooltip/Tooltip';

type PropsType = {
  name: string;
  label: string;
  value: string | number | undefined;
  options: { name: string; label: string; icon: string }[];
  onChange: (newVal: string) => void;
};

export const PropertiesRadioGroup = (props: PropsType) => {
  return (
    <div className="properties-radio-group">
      <div className="properties-radio-group__options">
        {props.options.map((option) => (
          <button
            data-tip={option.label}
            key={option.name}
            onClick={() => props.onChange(option.name)}
            className={classNames({ selected: props.value === option.name })}
          >
            <img src={option.icon} alt={option.label} />
          </button>
        ))}
      </div>
      <Tooltip />
    </div>
  );
};
