import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { removeKeyframeAC } from '../../redux/reducers/projectReducer';
import { PropertyId } from '../../services/PropertySettingsService';

type PropsType = {
  layerId?: number;
  propertyId?: PropertyId;
  keyframeId?: number;
  empty?: boolean;
  onClick?: () => void;
};

export const KeyframeSymbol = (props: PropsType) => {
  const dispatch = useDispatch();
  const [isFocused, setFocused] = useState(false);

  useEffect(() => {
    if (!props.keyframeId) {
      return;
    }

    const downCb = (event: KeyboardEvent) => {
      if (event.code === 'Delete' && isFocused) {
        if (props.keyframeId && props.layerId && props.propertyId) {
          dispatch(
            removeKeyframeAC({
              layerId: props.layerId,
              propertyId: props.propertyId,
              keyframeId: props.keyframeId,
            }),
          );
        }
      }
    };

    document.addEventListener('keydown', downCb);
    return () => {
      document.removeEventListener('keydown', downCb);
    };
  });

  return (
    <button
      className={classNames({ 'keyframe-symbol': true, 'keyframe-symbol--empty': props.empty })}
      onClick={props.onClick}
      onFocus={() => setFocused(true)}
      onBlur={() => setFocused(false)}
      tabIndex={props.keyframeId ? 0 : undefined}
    />
  );
};
