import { Project } from '../models/project';

export const NewProjectDemo: Project = {
  name: 'Untitled project',
  resources: {
    fonts: [],
    lastFontId: 0,
    imageSequences: [],
    lastImageSequenceId: 0,
    images: [],
    lastImageId: 0,
    videos: [],
    lastVideoId: 0,
    svgs: [],
    lastSvgId: 0,
  },
  lastCompositionId: 1,
  activeCompositionId: 1,
  compositions: [
    {
      id: 1,
      name: 'New composition',
      width: 1920,
      height: 1080,
      activeFrame: 0,
      workspaceDuration: 50,
      duration: 100,
      fps: 30,
      scale: 20,
      background: '#fff',
      compositionPanelHeight: 275,
      layerControlsWidth: 275,
      lastLayerId: 0,
      lastKeyframeId: 0,
      lastActionId: 0,
      actions: [],
      layers: [],
      selectedLayers: [],
      compositionAction: '',
    },
  ],
};
