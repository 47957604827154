type PropsType = {
  name: string;
  label: string;
  checked: boolean;
  onChange: (hilited: boolean) => void;
};

export const CheckboxWithLabel = (props: PropsType) => {
  return (
    <div className="checkbox-input-wrapper">
      <label>
        <input type="checkbox" name="toggle" checked={props.checked} onChange={() => props.onChange(!props.checked)} />
        <span className="checkmark-box">
          <span className="checkmark icon-checkmark"></span>
        </span>
        <span className="label">{props.label}</span>
      </label>
    </div>
  );
};
