import { Tutorial } from './Tutorial';

export const WelcomeTutorial = () => {
  const steps = [
    {
      domNode: document.querySelector('.add-new-layer'),
      text: 'Welcome to Loopic! This is where eveything starts - here you create all kinds of layers.',
      position: 'right',
    },
    {
      domNode: document.querySelector('.composition-panel'),
      text: "This is the composition's timeline - here you control all layers, change properties, create animations, etc.",
      position: 'top',
    },
    {
      domNode: document.querySelector('.render-section'),
      text: 'This is your current composition render preview. This is where you see what your animation looks like.',
      position: 'bottom',
    },
    {
      domNode: document.querySelector('.playback-controls'),
      text: 'Here you control the playback, and toggle looping.',
      position: 'bottom',
    },
    {
      domNode: document.querySelector('.right-sidebar'),
      text: 'Here you find settings for current active composition or for selected layer(s).',
      position: 'left',
    },
    {
      domNode: document.querySelector('#casparcg-panel'),
      text: 'This is where you export the template for CasparCG.',
      position: 'left',
    },
    {
      domNode: document.querySelector('#resources'),
      text: 'Here are all your compositions, images, image sequences, SVGs, fonts, etc.',
      position: 'right',
    },
    {
      domNode: document.querySelector('.appbar'),
      text: 'Finally, using the menu bar you can save and load project, undo/redo, and find helpful materials.',
      position: 'bottom',
    },
  ];

  return <Tutorial steps={steps} />;
};
