import { UserType } from '../../models/user';
import { AuthService } from '../AuthService';
import { LoginResponse } from './AuthApi';
import { destroy, get, post, put } from './config';

type CreateUserRequest = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
};

type UpdateUserRequest = {
  firstName?: string;
  lastName?: string;
  email?: string;
};

type UpdatePasswordType = {
  oldPassword: string;
  password: string;
};

type CreateUserResponse = LoginResponse;

export type UserResponse = UserType;

export class UsersApi {
  static create = async (params: CreateUserRequest) => await post<CreateUserResponse>('/users', params);

  static update = async (userId: string, params: UpdateUserRequest) =>
    await put<UserResponse>('/users/' + userId, params, AuthService.getAuthHeader());

  static updatePassword = async (userId: string, params: UpdatePasswordType) =>
    await post<{}>(`/users/${userId}/update-password`, params, AuthService.getAuthHeader());

  static findOne = async (id: string) => await get<UserResponse>('/users/' + id, AuthService.getAuthHeader());

  static delete = async (id: string) => await destroy('/users/' + id, AuthService.getAuthHeader());
}
