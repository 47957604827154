import logo from '../../img/logos/logo-dark-bg.png';

export const DesktopOnly = () => {
  return (
    <div id="desktop-only">
      <div className="content">
        <img src={logo} alt="Loopic logo" />
        <div className="title">Sorry to inform you, but Loopic works only on larger screens.</div>
      </div>
    </div>
  );
};
