type PropsType = {
  icon: string;
  label: string;
};

export const ContextMenuIconItem = (props: PropsType) => {
  return (
    <div className="icon-item">
      <div className="icon-item__img">
        <img src={props.icon} alt={props.label} />
      </div>
      <div className="icon-item__label">{props.label}</div>
    </div>
  );
};
