import { useSelector } from 'react-redux';
import successIcon from '../../img/notifications/success.svg';
import errorIcon from '../../img/notifications/error.svg';
import classNames from 'classnames';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { AppState } from '../../redux/AppState';
import { removeNotificationAC } from '../../redux/reducers/notificationReducer';

export const NotificationsWrapper = ({ children }: { children: React.ReactNode }) => {
  const notifications = useSelector((state: AppState) => state.notifications);

  return (
    <div className="notifications-wrapper">
      <div className="notifications">
        {notifications.map((notification) => {
          return <Notification key={notification.id} id={notification.id} message={notification.message} type={notification.type} />;
        })}
      </div>
      {children}
    </div>
  );
};

const Notification = ({ id, message, type }: { id: number; message: string; type: 'success' | 'error' }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const myTimeout = setTimeout(() => dispatch(removeNotificationAC(id)), 8000);
    return () => clearTimeout(myTimeout);
  }, [id]);

  return (
    <div
      className={classNames({
        notification: true,
        ['notification--' + type]: true,
      })}
    >
      {type === 'success' && <img className="notification__icon" src={successIcon} alt="Success" />}
      {type === 'error' && <img className="notification__icon" src={errorIcon} alt="Error" />}
      <div className="notification__message">{message}</div>
    </div>
  );
};
