import { Layer } from '../models/composition';
import { Project } from '../models/project';
import { calculatePropertyKeyframes } from '../services/calculateLayerOptions';
import { allProperties, PropertyId, PropertySettingsService } from '../services/PropertySettingsService';

export const getActiveComposition = (project: Project) => {
  const activeId = project.activeCompositionId;
  return project.compositions.find((comp) => comp.id === activeId)!;
};

/**
 * Function checks all layer properties and if some are missing, default ones are applied.
 * @param layers Layers that will be inspected
 * @returns Layers with all the property values
 */
export const getLayerWithDefaultOrCalculatedValues = (layer: Layer): Layer => {
  const filteredProperties = PropertySettingsService.getAllPropertiesForType(layer.element.type);
  for (const propId in filteredProperties) {
    const propertyId = propId as PropertyId;
    const propertySettings = allProperties[propertyId];
    const propertyValues = {
      keyframes: [],
      value: propertySettings.default,
    };

    // * If the property does not exist at all
    if (!layer.properties[propertyId]) {
      layer.properties[propertyId] = {
        ...propertyValues,
        calculatedKeyframes: calculatePropertyKeyframes(layer, propertyId, propertyValues),
      };
    }
    // * If the property exists, but does not have calculated keyframes
    else if (layer.properties[propertyId]) {
      layer.properties[propertyId]!.calculatedKeyframes = calculatePropertyKeyframes(layer, propertyId, layer.properties[propertyId]!);
    }
  }

  return layer;
};

export const getLayersWithDefaultOrCalculatedValues = (layers: Layer[]): Layer[] => {
  return layers.map((layer) => {
    return getLayerWithDefaultOrCalculatedValues(layer);
  });
};

const incrementLastDigits = (input: string, separator?: string) => {
  const digits = [];
  for (let i = input.length - 1; i >= 0; i--) {
    const currentChar = input[i];
    if (!isNaN(parseInt(currentChar))) {
      digits.unshift(input[i]);
    } else {
      break;
    }
  }

  if (digits.length > 0) {
    // Numbers are found
    const numbersString = digits.join('');
    const newId = parseInt(numbersString) + 1;
    const sliced = input.slice(0, input.length - digits.length);
    return sliced + newId.toString();
  } else {
    // No numbers found
    if (separator) {
      return input + separator + '2';
    }
    return input + '2';
  }
};

export const constructNewDynamicId = (dynamicId: string) => {
  if (!dynamicId) return '';
  return incrementLastDigits(dynamicId);
};

export const constructNewLayerName = (name: string) => {
  return incrementLastDigits(name, ' ');
};
