import { Composition, Layer } from '../../../models/composition';
import { Project } from '../../../models/project';
import { actionsJSBuilder } from './actionsJSBuilder';

export type TempCompFormat = {
  id: number;
  domNodeQueryString: string;
  startFrame: number;
  duration: number;
};

export const getAllUsedCompositions = (
  project: Project,
  composition: Composition,
  prevPath?: string,
  layer?: Layer,
  startFrame = 0,
): TempCompFormat[] => {
  let myPath = layer ? `.layer-${layer.id} .cmp-${composition.id}` : `.cmp-${composition.id}`;
  if (prevPath) {
    myPath = prevPath + ' ' + myPath;
  }
  const myStartFrame = layer ? startFrame + layer.startFrame : 0;

  let comps: TempCompFormat[] = [
    {
      id: composition.id,
      domNodeQueryString: myPath,
      startFrame: myStartFrame,
      duration: composition.workspaceDuration,
    },
  ];

  for (const layer of composition.layers) {
    if (layer.element.type === 'COMPOSITION' && !layer.guide && layer.visible) {
      const compId = layer.element.compositionId;
      const found = project.compositions.find((comp) => comp.id === compId);
      if (!found) return [];
      comps = [...comps, ...getAllUsedCompositions(project, found, myPath, layer, myStartFrame)];
    }
  }
  return comps;
};

export const coreJSBuilder = (project: Project, composition: Composition) => {
  let compsArray = '';
  let idx = 0;
  let finalString = '';

  const allUsedComps = getAllUsedCompositions(project, composition);

  let outroFrame;
  for (const action of composition.actions) {
    if (action.outro) {
      outroFrame = action.frame;
    }
  }

  for (const comp of allUsedComps) {
    if (idx !== 0) compsArray += ',';
    idx++;

    compsArray += `{
        id: ${comp.id},
        domNode: document.querySelector("${comp.domNodeQueryString}"),
        startFrame: ${comp.startFrame},
        duration: ${comp.duration}
      }`;
  }

  finalString += `
    let currentFrame = -1;
    const outroFrame = ${outroFrame};
    const compositions = [${compsArray}];
  `;

  finalString += `
    function base64ToArrayBuffer(base64) {
      var binary_string = window.atob(base64);
      var len = binary_string.length;
      var bytes = new Uint8Array(len);
      for (var i = 0; i < len; i++) {
        bytes[i] = binary_string.charCodeAt(i);
      }
      return bytes.buffer;
    }
  `;

  finalString += `
    let lastFrame = null;
    function getFrame(frameNumber) {
      for (const cmp of compositions) {
        const cmpFrameNumber = frameNumber - cmp.startFrame;

        if (cmpFrameNumber < 0) {
          cmp.domNode.classList.add("hide");
        } else if (cmpFrameNumber > cmp.duration) {
          cmp.domNode.classList.add("hide");
        } else {
          if (cmp.domNode.classList.contains("hide")) {
            cmp.domNode.classList.remove("hide");
          }

          if (lastFrame === frameNumber - 1) {
            cmp.domNode.classList.add("f-" + cmpFrameNumber);

          } else {

            for (let i = 0; i < cmp.duration; i++) {
              if (cmp.domNode.classList.contains("f-" + i)) {
                cmp.domNode.classList.remove("f-" + i);
              }
            }

            for (let i = 0; i <= cmpFrameNumber; i++) {
              cmp.domNode.classList.add("f-" + i);
            }
          }
        }
      }
      lastFrame = frameNumber;
    }
  `;

  finalString += `
    let fpsInterval, startTime, now, then, elapsed;
    let stopped = true;
    let backwards = false;

    function animate(timestamp) {
      now = Date.now();
      elapsed = now - then;

      if (elapsed > fpsInterval) {
        // console.log(currentFrame);
        then = now - (elapsed % fpsInterval);

        if (!backwards) {
          ++currentFrame;
        } else {
          --currentFrame;
        }

        goto(currentFrame);
        
      }
      if (stopped) return;

	    if (backwards && currentFrame < 0 && loopic.flags.stop) {
        destroyGraphic();
      } else if (currentFrame < ${composition.workspaceDuration}) {
        window.requestAnimationFrame(animate);
      } else if (loopic.flags.stop) {
        destroyGraphic();
      }
    }

    function destroyGraphic() {
      console.info("Graphic destroyed");
      if (window.remove) {
        window.remove();
      }
    }


    function playAnimation(arg) {
      document.getElementById("render").classList.add("played");

      if (arg && arg.backwards) {
        backwards = true;
      } else {
        backwards = false;
      }

      fpsInterval = ${1000 / composition.fps};
      then = Date.now();
      startTime = then;
      stopped = false;
      animate();
    }


    function stopAnimation() {
      stopped = true;
    }

    function goto(frameNumber) {
      getFrame(frameNumber);
      currentFrame = frameNumber;
      ${actionsJSBuilder(composition.actions)}
    }

    function gotoAndPlay(frameNumber) {
      goto(frameNumber);
      playAnimation();
    }
  `;

  // If the resolution is wrong, show it
  finalString += `
    if (CASPARCG_ENV && (window.innerWidth !== ${composition.width} || window.innerHeight !== ${composition.height})) {
      console.log("INVALID RESOLUTION - Set the output to ${composition.width}x${composition.height}");
      document.body.innerHTML += '<div id="invalid-resolution">INVALID RESOLUTION<br />Set the output to ${composition.width}x${composition.height}</div>'
    }
  `;

  finalString += `
    ${composition.compositionAction}
  `;

  return finalString;
};
