import { RefObject, useRef } from 'react';
import { useSelector } from 'react-redux';
import { compSelector } from '../../../redux/selectors/compositionSelector';
import { projectSelector } from '../../../redux/selectors/projectSelector';
import { VideoElement } from './VideoElement';

type PropsType = {
  id: number;
  styles: any;
  videoId: number;
  activeFrame: number;
  elementRef: RefObject<HTMLDivElement>;
};

export const VideoRenderElement = (props: PropsType) => {
  const composition = useSelector(compSelector);
  const videos = useSelector(projectSelector).resources.videos;
  const videoRef = useRef<HTMLVideoElement>(null);

  const video = videos.find((video) => video.id === props.videoId);
  if (!video) return <></>;

  const originalFPS = composition.fps;
  const originalTimePerFrame = 1 / originalFPS;

  if (videoRef.current) {
    const ct = originalTimePerFrame * props.activeFrame;
    videoRef.current.currentTime = ct;
  }

  return (
    <div className={`element element-${props.id} video`} style={props.styles} ref={props.elementRef}>
      <VideoElement videoRef={videoRef} content={video.content} />
    </div>
  );
};
