import { Dropdown } from '../common/dropdown/Dropdown';
import { PropertiesSection } from '../common/properties/PropertiesSection';

import fontSize from '../../img/attributes/fontSize.svg';
import lineHeight from '../../img/attributes/lineHeight.svg';
import letterSpacing from '../../img/attributes/letterSpacing.svg';

import fontWeightNormal from '../../img/attributes/fontWeight-normal.png';
import fontWeightBold from '../../img/attributes/fontWeight-bold.png';

import fontStyleNormal from '../../img/attributes/fontStyle-normal.png';
import fontStyleItalic from '../../img/attributes/fontStyle-italic.png';
import fontStyleOblique from '../../img/attributes/fontStyle-oblique.png';

import fontVariantNormal from '../../img/attributes/fontVariant-normal.png';
import fontVariantSmallCaps from '../../img/attributes/fontVariant-small-caps.png';

import textAlignLeft from '../../img/attributes/textAlign-left.svg';
import textAlignCenter from '../../img/attributes/textAlign-center.svg';
import textAlignRight from '../../img/attributes/textAlign-right.svg';
import textAlignJustify from '../../img/attributes/textAlign-justify.svg';

import textTransformNone from '../../img/attributes/textTransform-none.png';
import textTransformUppercase from '../../img/attributes/textTransform-uppercase.png';
import textTransformLowercase from '../../img/attributes/textTransform-lowercase.png';
import textTransformCapitalize from '../../img/attributes/textTransform-capitalize.png';
import textDecorationNone from '../../img/attributes/textDecoration-none.png';
import textDecorationUnderline from '../../img/attributes/textDecoration-underline.png';
import textDecorationLineThrough from '../../img/attributes/textDecoration-line-through.png';
import textDecorationOverline from '../../img/attributes/textDecoration-overline.png';
import { PropertyNumber } from '../common/inputs/PropertyNumber';
import { PropertiesRadioGroup } from '../common/inputs/PropertiesRadioGroup';
import { PropertyId } from '../../services/PropertySettingsService';
import { InputColor } from '../common/inputs/InputColor';

import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../redux/AppState';
import { FontService } from '../../services/FontService';
import { CheckboxWithLabel } from '../common/inputs/CheckboxWithLabel';
import { toggleTextElementAutoShrinkAC } from '../../redux/reducers/projectReducer';
import { projectSelector } from '../../redux/selectors/projectSelector';

type PropsType = {
  layerId: number;
  disableAutoShrink: boolean;
  onSetPropertyValue: (propertyId: PropertyId, value: any) => void;
  onGetPropertyValue: (propertyId: PropertyId) => string | number | undefined;
};

export const TextPropertiesSection = (props: PropsType) => {
  const projectFonts = useSelector(projectSelector).resources.fonts;
  const dispatch = useDispatch();

  /**
   * Get latest fonts
   */
  const allFonts = [...FontService.websafeFonts];
  projectFonts.forEach((font) => {
    allFonts.push({ id: font.name, value: font.name, highlighted: true });
  });

  return (
    <PropertiesSection label="Text properties">
      <div className="properties-row">
        <Dropdown
          allItems={allFonts}
          dropdownNo={3}
          selectedItemId={props.onGetPropertyValue('fontFamily')}
          noneItem={{ id: '0', value: 'None' }}
          onClick={(id: string | number | undefined) => {
            const found = allFonts.find((font) => font.id === id)!;
            props.onSetPropertyValue('fontFamily', found.value);
          }}
          placeholder="Search fonts"
        />
      </div>

      <div className="properties-row">
        <InputColor
          label="Text color"
          name="color"
          value={props.onGetPropertyValue('color')}
          onChange={(color) => props.onSetPropertyValue('color', color)}
        />
      </div>
      {/* <div className="properties-row">
        <InputColor
          label="Background color"
          name="backgroundColor"
          value={props.onGetPropertyValue('backgroundColor')}
          onChange={(color) => props.onSetPropertyValue('backgroundColor', color)}
        />
      </div> */}

      <div className="properties-row">
        <CheckboxWithLabel
          checked={props.onGetPropertyValue('whiteSpace') === 'normal'}
          name="autoWidth"
          label="Multiline"
          onChange={(checked) => {
            if (checked) props.onSetPropertyValue('whiteSpace', 'normal');
            else props.onSetPropertyValue('whiteSpace', 'nowrap');
          }}
        />
        <CheckboxWithLabel
          checked={props.disableAutoShrink}
          name="autoWidth"
          label="Disable Auto-shrink"
          onChange={(checked) => {
            dispatch(toggleTextElementAutoShrinkAC({ layerId: props.layerId, disable: !checked }));
          }}
        />
      </div>

      <div className="properties-row">
        <PropertyNumber
          name="fontSize"
          label="Font size"
          onSubmit={(value) => props.onSetPropertyValue('fontSize', value)}
          icon={fontSize}
          value={props.onGetPropertyValue('fontSize')}
        />
        <PropertiesRadioGroup
          label="Text align"
          name="textAlign"
          value={props.onGetPropertyValue('textAlign')}
          onChange={(newVal) => props.onSetPropertyValue('textAlign', newVal)}
          options={[
            { name: 'left', label: 'Left', icon: textAlignLeft },
            { name: 'center', label: 'Center', icon: textAlignCenter },
            { name: 'right', label: 'Right', icon: textAlignRight },
            { name: 'justify', label: 'Justify', icon: textAlignJustify },
          ]}
        />
      </div>
      <div className="properties-row">
        <PropertiesRadioGroup
          label="Text decoration"
          name="textDecoration"
          value={props.onGetPropertyValue('textDecoration')}
          onChange={(newVal) => props.onSetPropertyValue('textDecoration', newVal)}
          options={[
            { name: 'none', label: 'None', icon: textDecorationNone },
            { name: 'overline', label: 'Overline', icon: textDecorationOverline },
            { name: 'line-through', label: 'Line-through', icon: textDecorationLineThrough },
            { name: 'underline', label: 'Underline', icon: textDecorationUnderline },
          ]}
        />
        <PropertyNumber
          name="lineHeight"
          label="Line height"
          onSubmit={(value) => props.onSetPropertyValue('lineHeight', value)}
          icon={lineHeight}
          value={props.onGetPropertyValue('lineHeight')}
        />
        <PropertyNumber
          name="letterSpacing"
          label="Letter spacing"
          onSubmit={(value) => props.onSetPropertyValue('letterSpacing', value)}
          icon={letterSpacing}
          value={props.onGetPropertyValue('letterSpacing')}
        />
      </div>

      {/* Font Style */}
      <div className="properties-row">
        <PropertiesRadioGroup
          label="Font style"
          name="fontStyle"
          value={props.onGetPropertyValue('fontStyle')}
          onChange={(newVal) => props.onSetPropertyValue('fontStyle', newVal)}
          options={[
            { name: 'normal', label: 'Normal', icon: fontStyleNormal },
            { name: 'italic', label: 'Italic', icon: fontStyleItalic },
            { name: 'oblique', label: 'Oblique', icon: fontStyleOblique },
          ]}
        />
        <PropertiesRadioGroup
          label="Font weight"
          name="fontWeight"
          value={props.onGetPropertyValue('fontWeight')}
          onChange={(newVal) => props.onSetPropertyValue('fontWeight', newVal)}
          options={[
            { name: 'normal', label: 'Normal', icon: fontWeightNormal },
            { name: 'bold', label: 'Bold', icon: fontWeightBold },
          ]}
        />
        <PropertiesRadioGroup
          label="Font variant"
          name="fontVariant"
          value={props.onGetPropertyValue('fontVariant')}
          onChange={(newVal) => props.onSetPropertyValue('fontVariant', newVal)}
          options={[
            { name: 'normal', label: 'Normal', icon: fontVariantNormal },
            { name: 'small-caps', label: 'Small caps', icon: fontVariantSmallCaps },
          ]}
        />
      </div>

      <div className="properties-row">
        <PropertiesRadioGroup
          label="Text decoration"
          name="textTransform"
          value={props.onGetPropertyValue('textTransform')}
          onChange={(newVal) => props.onSetPropertyValue('textTransform', newVal)}
          options={[
            { name: 'none', label: 'None', icon: textTransformNone },
            { name: 'uppercase', label: 'Uppercase', icon: textTransformLowercase },
            { name: 'lowercase', label: 'Lowercase', icon: textTransformCapitalize },
            { name: 'capitalize', label: 'Capitalize', icon: textTransformUppercase },
          ]}
        />
      </div>
    </PropertiesSection>
  );
};
