import { Properties } from '../../models/composition';
import { SingleCSSConstructor } from './SingleCSSConstructor';

export class FilterCSSConstructor extends SingleCSSConstructor {
  blur;
  contrast;
  brightness;
  grayscale;

  constructor(properties: Properties) {
    super();
    this.blur = properties['filterBlur'];
    this.contrast = properties['filterContrast'];
    this.brightness = properties['filterBrightness'];
    this.grayscale = properties['filterGrayscale'];
  }

  getValue(frame: number) {
    const filterBlurValue = this.blur?.calculatedKeyframes && this.blur?.calculatedKeyframes[frame]?.value;
    const filterContrastValue = this.contrast?.calculatedKeyframes && this.contrast?.calculatedKeyframes[frame]?.value;
    const filterBrightnessValue = this.brightness?.calculatedKeyframes && this.brightness?.calculatedKeyframes[frame]?.value;
    const filterGrayscaleValue = this.grayscale?.calculatedKeyframes && this.grayscale?.calculatedKeyframes[frame]?.value;
    const filterValue = `blur(${filterBlurValue}px) contrast(${filterContrastValue}) brightness(${filterBrightnessValue}) grayscale(${filterGrayscaleValue})`;

    return filterValue;
  }
}
