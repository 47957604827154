import eyeOpen from '../../../img/icons/eye-open.svg';
import eyeClosed from '../../../img/icons/eye-closed.svg';
import { MouseEventHandler } from 'react';
import { Tooltip } from '../../common/tooltip/Tooltip';

type PropsType = {
  onClick: MouseEventHandler<HTMLDivElement>;
  visible: boolean;
};

export const VisibilityToggle = (props: PropsType) => {
  return (
    <>
      <div
        className="layer-toggle"
        onClick={props.onClick}
        style={{ opacity: props.visible ? 1 : 0.5 }}
        data-tip={props.visible ? 'Hide' : 'Show'}
      >
        {props.visible ? <img src={eyeOpen} alt="Visible" /> : <img src={eyeClosed} alt="Not visible" />}
      </div>
      <Tooltip />
    </>
  );
};
