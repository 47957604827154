import { Layer } from '../../../models/composition';
import { Video } from '../../../models/resources';
import { ColorsService } from '../../../services/ColorsService';
import { UtilsService } from '../../../services/UtilsService';
import { getActiveComposition } from '../../helpers';
import { setModalAC } from '../../reducers/appReducer';
import { addLayerAC, addVideoAC, removeVideoAC } from '../../reducers/projectReducer';
import { projectSelector } from '../../selectors/projectSelector';
import { ConflictItemType, ResourceThunksInterface } from './ResourceThunks.interface';
import { ResourceUtils } from './ResourceUtils';

export const VideoResourceThunks: ResourceThunksInterface = {
  import: () => {
    return async (dispatch, getState) => {
      const state = getState();
      const project = projectSelector(state);

      const pickerOpts = {
        types: [
          {
            description: 'Videos',
            accept: {
              'video/type': ['.webm'],
            },
          },
        ],
        excludeAcceptAllOption: true,
        multiple: false,
      };

      const loaded = await ResourceUtils.loadFile(pickerOpts);
      const newVideo: Video = {
        id: project.resources.lastVideoId + 1,
        name: loaded.file.name,
        content: loaded.content,
      };
      dispatch(addVideoAC(newVideo));

      dispatch(
        setModalAC({
          title: 'Video requirements',
          icon: 'warning',
          asHtml: true,
          content: `Video must have the smallest possible key-frame distance (1) and must be in the same frame rate as the composition. Otherwise, you may experience performance issues. <a href="https://www.loopic.io/docs/tutorials/using-videos" target="_blank" rel="noreferrer">Click here to read more.</a>`,
          controls: [{ label: 'OK', onClick: () => dispatch(setModalAC(undefined)) }],
        }),
      );
    };
  },

  toLayer: (id: number) => {
    return async (dispatch, getState) => {
      const state = getState();
      const project = projectSelector(state);
      const composition = getActiveComposition(project);

      const video = project.resources.videos.find((video) => video.id === id);
      if (!video) return;

      const sizeAndDuration = await UtilsService.getVideoSizeAndDuration(video.content);
      const timePerFrame = 1 / composition.fps;

      const newLayer: Layer = {
        id: composition.lastLayerId + 1,
        name: 'New video layer',
        startFrame: 0,
        duration: sizeAndDuration.duration / timePerFrame,
        color: ColorsService.getElementColorId('IMAGE'),
        visible: true,
        locked: false,
        guide: false,
        element: {
          type: 'VIDEO',
          dynamicId: '',
          videoId: id,
        },
        properties: {
          width: {
            value: sizeAndDuration.width,
            keyframes: [],
          },
          height: {
            value: sizeAndDuration.height,
            keyframes: [],
          },
        },
        openPropertyGroups: [],
        open: false,
      };
      dispatch(addLayerAC(newLayer));
    };
  },

  newLayer: () => {
    return async (dispatch, getState) => {
      const state = getState();
      const project = projectSelector(state);

      await Promise.all([dispatch(VideoResourceThunks.import())]);
      dispatch(VideoResourceThunks.toLayer(project.resources.lastVideoId + 1));
    };
  },

  remove: (id: number) => {
    return async (dispatch, getState) => {
      const state = getState();
      const project = projectSelector(state);
      const compositions = project.compositions;

      const conflictItems: ConflictItemType[] = [];
      for (const composition of compositions) {
        for (const layer of composition.layers) {
          if (layer.element.type === 'VIDEO') {
            if (layer.element.videoId === id) {
              conflictItems.push({ compositionId: 1, layerId: layer.id, name: layer.name });
            }
          }
        }
      }

      dispatch(ResourceUtils.remove('video', id, conflictItems, removeVideoAC));
    };
  },
};
