import { RefObject, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { AppState } from '../../../../redux/AppState';
import { compDurationSelector } from '../../../../redux/selectors/compositionSelector';
import { TfsActions } from './TfsActions';
import { TfsButtons } from './TfsButtons';
import { TfsDuration } from './TfsDuration';
import { TfsLine } from './TfsLine';

type PropsType = {
  tfsRef: RefObject<HTMLDivElement>;
  timelineRef: RefObject<HTMLDivElement>;
};

export const TimelineFrameSelector = (props: PropsType) => {
  const compDuration = useSelector(compDurationSelector);
  const currentFrameHandleRef = useRef<HTMLDivElement>(null);
  const [scrollLeft, setScrollLeft] = useState(0);

  return (
    <div
      className="timeline-frame-selector"
      ref={props.tfsRef}
      onScroll={(e) => {
        const sl = (e as any).target.scrollLeft;
        setScrollLeft(sl);
      }}
    >
      <TfsActions />
      <TfsButtons duration={compDuration} currentFrameHandleRef={currentFrameHandleRef} scrollLeft={scrollLeft} />
      <TfsDuration />
      <TfsLine handleRef={currentFrameHandleRef} scrollLeft={scrollLeft} />
    </div>
  );
};
