import { pure } from 'recompose';
import { RefObject } from 'react';

type PropsType = {
  videoRef: RefObject<HTMLVideoElement>;
  content: string;
};

export const VideoElement = pure((props: PropsType) => {
  return (
    <video ref={props.videoRef} autoPlay={false}>
      <source src={props.content} type="video/webm" />
    </video>
  );
});
