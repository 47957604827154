import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../../redux/AppState';
import { ActionFlag } from '../../../common/inputs/ActionFlag';
import { DraggableTimeline } from '../../../DraggableTimeline';
import { setActionFrameAC } from '../../../../redux/reducers/projectReducer';
import { compScaleSelector, compSelector } from '../../../../redux/selectors/compositionSelector';

export const TfsActions = () => {
  const compScale = useSelector(compScaleSelector);
  const compActions = useSelector(compSelector).actions;
  const dispatch = useDispatch();

  const handleActionDrag = (actionId: number) => {
    return (newSelectedFrame: number, startFrame: number) => {
      if (newSelectedFrame >= 0) dispatch(setActionFrameAC({ actionId: actionId, frame: newSelectedFrame }));
      else dispatch(setActionFrameAC({ actionId: actionId, frame: 0 }));
    };
  };

  return (
    <div className="timeline-frame-selector__actions">
      {compActions?.map((action) => {
        return (
          <DraggableTimeline key={`draggable-action-${action.id}`} onDrag={handleActionDrag(action.id)}>
            <ActionFlag
              key={`action-${action.id}`}
              id={action.id}
              outro={action.outro}
              style={{
                left: action.frame * compScale + 1,
                width: compScale - 1,
              }}
            />
          </DraggableTimeline>
        );
      })}
    </div>
  );
};
