import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FullLoader } from './components/common/loaders/FullLoader';
import { NotificationsWrapper } from './components/notifications/NotificationsWrapper';
import { NewProjectDemo } from './data/NewProjectDemo';
import { DesktopOnly } from './components/desktop-only/DesktopOnly';
import { AppState } from './redux/AppState';
import { removeFullPageLoaderAC, setFullPageLoaderAC } from './redux/reducers/appReducer';
import { loadProjectThunk } from './redux/thunks/projectThunk';
import { AppRouter } from './routers/AppRouter';
import { AuthService } from './services/AuthService';
import { KeyboardService } from './services/KeyboardService';
import { UtilsService } from './services/UtilsService';
import { WelcomeTutorial } from './components/tutorial/WelcomeTutorial';
import { KeyboardShortcuts } from './components/KeyboardShortcuts';

export const App = () => {
  const appTouched = useSelector((state: AppState) => state.app.touched);
  const showTutorial = useSelector((state: AppState) => state.app.showTutorial);
  const dispatch = useDispatch();
  const [accountLoaded, setAccountLoaded] = useState(false);

  useEffect(() => {
    const cb = (e: BeforeUnloadEvent) => {
      if (appTouched) {
        e.returnValue = 'There are unsaved changes';
      }
    };

    window.addEventListener('beforeunload', cb);

    return () => {
      window.removeEventListener('beforeunload', cb);
    };
  }, [appTouched]);

  useEffect(() => {
    KeyboardService.init();
    UtilsService.initDispatch(dispatch);

    const fetchData = async () => {
      await AuthService.autoLogin();
      dispatch(removeFullPageLoaderAC());
      setAccountLoaded(true);
    };

    dispatch(setFullPageLoaderAC({ visible: true, title: 'Loading project' }));
    dispatch(loadProjectThunk(NewProjectDemo));

    if (AuthService.getAccessToken()) {
      dispatch(setFullPageLoaderAC({ visible: true, title: 'Loading account' }));
      fetchData();
    } else {
      dispatch(removeFullPageLoaderAC());
      setAccountLoaded(true);
    }
  }, []);

  return (
    <NotificationsWrapper>
      <DesktopOnly />
      <FullLoader />
      <KeyboardShortcuts />
      {accountLoaded && <AppRouter />}
      {showTutorial && <WelcomeTutorial />}
    </NotificationsWrapper>
  );
};
