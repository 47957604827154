import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { Layer } from '../../../../models/composition';
import { toggleOpenPropertyGroupThunk } from '../../../../redux/thunks/layersThunk';
import { PropertyLayoutGroup } from '../../../../services/PropertySettingsService';
import { PropertiesLayoutItems } from './LCLayer';

export const LCPropertyGroup = ({ layer, propertyLayoutGroup }: { layer: Layer; propertyLayoutGroup: PropertyLayoutGroup }) => {
  const dispatch = useDispatch();
  const isOpen = layer.openPropertyGroups.findIndex((opg) => opg === propertyLayoutGroup.id) >= 0;

  if (propertyLayoutGroup.types && !propertyLayoutGroup.types.find((pst) => pst === layer.element.type)) {
    return <></>;
  }

  return (
    <div className="lc-property-group">
      <div
        className="header"
        onClick={() => {
          dispatch(toggleOpenPropertyGroupThunk(layer.id, propertyLayoutGroup.id, !isOpen));
        }}
      >
        <div className="title">{propertyLayoutGroup.title}</div>
        <div className={classNames({ arrow: true, open: isOpen })} />
      </div>
      {isOpen && (
        <div className="items">
          <PropertiesLayoutItems layer={layer} items={propertyLayoutGroup.items} />
        </div>
      )}
    </div>
  );
};
