import { Layer } from '../../../models/composition';
import { Image, ImageSequence } from '../../../models/resources';
import { ColorsService } from '../../../services/ColorsService';
import { UtilsService } from '../../../services/UtilsService';
import { getActiveComposition } from '../../helpers';
import { addImageSequenceAC, addLayerAC, removeImageSequenceAC } from '../../reducers/projectReducer';
import { projectSelector } from '../../selectors/projectSelector';
import { ConflictItemType, ResourceThunksInterface } from './ResourceThunks.interface';
import { ResourceUtils } from './ResourceUtils';

export const ImageSequenceResourceThunks: ResourceThunksInterface = {
  import: () => {
    return async (dispatch, getState) => {
      const state = getState();
      const project = projectSelector(state);

      const pickerOpts: OpenFilePickerOptions = {
        types: [
          {
            description: 'Images',
            accept: {
              'image/type': ['.png', '.jpeg', '.jpg'],
            },
          },
        ],
        excludeAcceptAllOption: true,
        multiple: true,
      };
      const fileHandles = await window.showOpenFilePicker(pickerOpts);
      const loadedImages: Image[] = [];

      let cntr = 0;

      for (const fileHandle of fileHandles) {
        const singleFile = await fileHandle.getFile();

        const image = await new Promise<string>((resolve, reject) => {
          const fr = new FileReader();
          fr.onload = async () => {
            resolve(fr.result as string);
          };
          fr.readAsDataURL(singleFile);
        });

        loadedImages.push({
          id: ++cntr,
          name: singleFile.name,
          type: singleFile.type,
          lastModified: singleFile.lastModified,
          size: singleFile.size,
          content: image,
        });
      }

      const newImageSequence: ImageSequence = {
        id: project.resources.lastImageSequenceId + 1,
        name: 'New image sequence',
        images: loadedImages,
      };

      dispatch(addImageSequenceAC(newImageSequence));
    };
  },

  toLayer: (id: number) => {
    return async (dispatch, getState) => {
      const state = getState();
      const project = projectSelector(state);
      const composition = getActiveComposition(project);

      const foundIS = project.resources.imageSequences.find((is) => is.id === id);
      if (!foundIS) return;
      const imageIds = foundIS.images.map((li) => li.id);
      const imgSize = await UtilsService.getImageSize(foundIS.images[0].content);
      const newLayer: Layer = {
        id: composition.lastLayerId + 1,
        name: foundIS.name,
        startFrame: 0,
        duration: foundIS.images.length,
        color: ColorsService.getElementColorId('IMAGE_SEQUENCE'),
        visible: true,
        locked: false,
        guide: false,
        element: {
          type: 'IMAGE_SEQUENCE',
          dynamicId: '',
          imageSequenceId: id,
          imageIds,
        },
        properties: {
          width: {
            value: imgSize.width,
            keyframes: [],
          },
          height: {
            value: imgSize.height,
            keyframes: [],
          },
        },
        openPropertyGroups: [],
        open: false,
      };
      dispatch(addLayerAC(newLayer));
    };
  },

  newLayer: () => {
    return async (dispatch, getState) => {
      const state = getState();
      const project = projectSelector(state);

      await Promise.all([dispatch(ImageSequenceResourceThunks.import())]);
      dispatch(ImageSequenceResourceThunks.toLayer(project.resources.lastImageSequenceId + 1));
    };
  },

  remove: (id: number) => {
    return async (dispatch, getState) => {
      const state = getState();
      const project = projectSelector(state);

      const conflictItems: ConflictItemType[] = [];
      for (const composition of project.compositions) {
        for (const layer of composition.layers) {
          if (layer.element.type === 'IMAGE_SEQUENCE') {
            if (layer.element.imageSequenceId === id) {
              conflictItems.push({ compositionId: composition.id, layerId: layer.id, name: layer.name });
            }
          }
        }
      }

      dispatch(ResourceUtils.remove('image sequence', id, conflictItems, removeImageSequenceAC));
    };
  },
};
