import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../redux/AppState';
import { Inforow } from '../common/inputs/Inforow';
import { InputWrapper } from '../common/inputs/InputWrapper';
import { SmartBtn } from '../common/inputs/SmartBtn';
import classNames from 'classnames';
import { UsersApi } from '../../services/api/UsersApi';
import { TopContentBottomControls } from '../common/layouts/top-content-bottom-controls/TopContentBottomControls';
import { AuthService } from '../../services/AuthService';
import { setModalAC, setPopupAC } from '../../redux/reducers/appReducer';
import { setNotificationAC } from '../../redux/reducers/notificationReducer';

export const DeleteAccount = () => {
  const user = useSelector((state: AppState) => state.user);
  const dispatch = useDispatch();
  const [formError, setFormError] = useState('');
  const [isLoading, setLoading] = useState(false);

  const handleDelete = async () => {
    const deleteControl = {
      label: 'Delete',
      onClick: async () => {
        setLoading(true);
        dispatch(setModalAC(undefined));
        try {
          await UsersApi.delete(user.id);

          dispatch(setNotificationAC({ message: 'Account successfully deleted', type: 'success' }));
          dispatch(setPopupAC({ popupId: 'account', open: false }));
          AuthService.logout();
          setLoading(false);
        } catch (error) {
          console.log(error);
          dispatch(setNotificationAC({ message: 'An error occured', type: 'error' }));
          setFormError('An error occured');
          setLoading(false);
        }
      },
    };
    const cancelControl = {
      label: 'Cancel',
      autoFocus: true,
      onClick: () => dispatch(setModalAC(undefined)),
    };

    dispatch(
      setModalAC({
        title: 'Delete confirmation',
        content: `You will not be able to reverse your account back. Are you sure you want to delete your Loopic account?`,
        icon: 'warning',
        controls: [cancelControl, deleteControl],
      }),
    );
  };

  return (
    <TopContentBottomControls>
      <div className="top-content">
        Deleting your account will remove all account information from Loopic. All deleted data{' '}
        <b>
          <u>can not</u>
        </b>{' '}
        be reversed back.
      </div>
      <div className="bottom-content">
        {formError && <Inforow type="error" message={formError} />}
        <SmartBtn onClick={handleDelete} theme="danger" type="submit" className="submit" label="Delete account" loading={isLoading} />
      </div>
    </TopContentBottomControls>
  );
};
