import { Composition } from '../../../models/composition';
import { Project } from '../../../models/project';
import { RendererService } from '../../../services/RendererService';
import { getAllUsedCompositions, TempCompFormat } from '../js/coreJSBuilder';

/**
 * Function calculates entire CSS for each frame of animation.
 * @param project Project
 * @param composition Composition
 * @returns Generated CSS
 */
export const frameCSSBuilder = (project: Project, composition: Composition) => {
  let layersCss = '';

  const allUsedComps = getAllUsedCompositions(project, composition);
  const filteredComps: TempCompFormat[] = [];

  allUsedComps.forEach((comp) => {
    if (!filteredComps.find((temp) => temp.id === comp.id)) {
      filteredComps.push(comp);
    }
  });

  for (const filteredComp of filteredComps) {
    const foundComp = project.compositions.find((comp) => comp.id === filteredComp.id);
    if (!foundComp) continue;

    for (const layer of foundComp.layers) {
      if (layer.guide || !layer.visible) continue;

      const layerStyles = RendererService.getCSSStylesheet(layer, true);

      let layerCss = '';

      // Set initial styles, this fixes auto-squeeze bug
      // .f-${0}
      if (layer.startFrame > 0) {
        const layerStyle = layerStyles[0];
        layerCss += `.cmp-${foundComp.id}.f-${0} > .layer-${layer.id} > .element { ${convertStylesToCss({
          ...layerStyle,
          visibility: '',
        })} } \n    `;
      }

      for (let i = 0; i < layer.duration; i++) {
        const activeStyleFrame = i;
        const compFrameNumber = layer.startFrame + i;

        const layerStyle = layerStyles[activeStyleFrame];

        // Check if there is even a need for exporting class
        if (Object.keys(layerStyle).length > 0) {
          layerCss += `.cmp-${foundComp.id}.f-${compFrameNumber} > .layer-${layer.id} > .element { ${convertStylesToCss(
            layerStyle,
          )} } \n    `;
        }

        if (layer.element.type === 'IMAGE_SEQUENCE') {
          layerCss += `.cmp-${foundComp.id}.f-${compFrameNumber}:not(.f-${compFrameNumber + 1}) > .layer-${
            layer.id
          } > .element > .scroller { transform: translateY(-${activeStyleFrame * 1080}px) } \n    `;
        }
      }

      layersCss += layerCss;
    }
  }

  return layersCss;
};

const convertStylesToCss = (styles: any) => {
  let cssStyles = '';
  for (const propId of Object.keys(styles)) {
    let normalizedPropId = propId.replace(/[A-Z]/g, (match) => `-${match.toLowerCase()}`);

    cssStyles += `${normalizedPropId}: ${styles[propId]};`;
  }
  return cssStyles;
};
