import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserType } from '../../models/user';

const intialState = {} as UserType;

export type UserState = UserType;

const userSlice = createSlice({
  name: 'user',
  initialState: intialState,
  reducers: {
    setUserAC: (state, { payload }: PayloadAction<UserType>) => {
      state.id = payload.id;
      state.firstName = payload.firstName;
      state.lastName = payload.lastName;
      state.email = payload.email;
      state.role = payload.role;
      state.createdAt = payload.createdAt;
      state.updatedAt = payload.updatedAt;
    },
    removeUserAC: (state, { payload }: PayloadAction) => {
      state = {} as UserType;
      return state;
    },
  },
});

export const { setUserAC, removeUserAC } = userSlice.actions;
export const userReducer = userSlice.reducer;
