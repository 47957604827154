import React, { MouseEventHandler, RefObject } from 'react';
import { useSelector } from 'react-redux';
import { AppState } from '../redux/AppState';
import { compScaleSelector } from '../redux/selectors/compositionSelector';

type PropsType = {
  children: React.ReactElement;
  timelineFrameSelectorRef?: RefObject<HTMLDivElement>;
  onDrag: (newSelectedFrame: number, dragStartFrame: number) => void;
  onDragStart?: () => void;
  onDragEnd?: () => void;
};

export const DraggableTimeline = (props: PropsType) => {
  const compScale = useSelector(compScaleSelector);
  let startFrame: number | undefined = undefined;
  let previousFrame: number | undefined = undefined;

  const tfs = document.getElementsByClassName('timeline-frame-selector')[0];
  const bounds = tfs?.getBoundingClientRect();
  const scrollLeft = tfs?.scrollLeft;

  const moveHandler = (ev: MouseEvent) => {
    if (!bounds) {
      console.error('Could not detect bounds in DraggableTimeline');
      return;
    }

    const oLeft = bounds.left;
    const newSelectedFrame = Math.floor((scrollLeft + ev.clientX - oLeft) / compScale);

    if (startFrame === undefined) startFrame = newSelectedFrame;

    if (previousFrame !== newSelectedFrame) {
      previousFrame = newSelectedFrame;
      props.onDrag(newSelectedFrame, startFrame);
    }
  };

  const handleMouseDown: MouseEventHandler<HTMLDivElement> = (e) => {
    document.addEventListener('mousemove', moveHandler);
    document.addEventListener('mouseup', handleMouseUp);
    if (props.onDragStart) props.onDragStart();
  };

  const handleMouseUp = () => {
    document.removeEventListener('mousemove', moveHandler);
    document.removeEventListener('mouseup', handleMouseUp);
    startFrame = undefined;
    if (props.onDragEnd) props.onDragEnd();
  };

  return React.cloneElement(props.children, { onMouseDown: handleMouseDown });
};
