import chrome from '../../img/browsers/chrome.svg';
import edge from '../../img/browsers/edge.svg';
import { isChrome, isEdgeChromium } from 'react-device-detect';

export const BrowserCompatibility = () => {
  if (isChrome || isEdgeChromium) return <></>;

  return (
    <div className="browser-compatibility">
      <span>
        Your browser is not compatible with Loopic. Please use <b>Chrome</b> or <b>Edge</b>.
      </span>
      <a href="https://www.google.com/chrome/" target="_blank" rel="noreferrer">
        <img src={chrome} alt="Chrome" />
      </a>
      <a href="https://www.microsoft.com/en-us/edge" target="_blank" rel="noreferrer">
        <img src={edge} alt="Edge" />
      </a>
    </div>
  );
};
