import classNames from 'classnames';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import { useSelector } from 'react-redux';
import { Layer } from '../../../../models/composition';
import { compLCWSelector } from '../../../../redux/selectors/compositionSelector';
import { getLayerByIdSelector, isLayerSelectedSelector } from '../../../../redux/selectors/layersSelector';
import { allProperties, LayoutItem, propertiesLayout } from '../../../../services/PropertySettingsService';
import { LCLayerHeader } from './LCLayerHeader';
import { LCProperty } from './LCProperty';
import { LCPropertyGroup } from './LCPropertyGroup';

type PropsType = { layerId: number; dragHandleProps?: DraggableProvidedDragHandleProps };

export const LCLayer = ({ layerId, dragHandleProps }: PropsType) => {
  const layer = useSelector(getLayerByIdSelector(layerId));
  const isLayerSelected = useSelector(isLayerSelectedSelector(layerId));
  const compLcw = useSelector(compLCWSelector);

  if (!layer) return <></>;

  return (
    <div
      className={classNames({ 'lc-layer': true, 'focus-layer': true, selected: isLayerSelected })}
      style={{ width: compLcw }}
      tabIndex={0}
    >
      <LCLayerHeader dragHandleProps={dragHandleProps} layer={layer} />
      {layer.open && <PropertiesLayoutItems layer={layer} items={propertiesLayout} />}
    </div>
  );
};

export const PropertiesLayoutItems = ({ layer, items }: { layer: Layer; items: LayoutItem[] }) => {
  return (
    <>
      {items.map((item) => {
        if (typeof item === 'string') {
          return (
            <LCProperty
              key={item}
              layer={layer}
              propertyId={item}
              propertyData={layer.properties[item]}
              propertySettings={allProperties[item]}
            />
          );
        } else {
          return <LCPropertyGroup key={item.id} layer={layer} propertyLayoutGroup={item} />;
        }
      })}
    </>
  );
};
