import { Item, ItemParams, Menu, Separator, Submenu } from 'react-contexify';
import 'react-contexify/dist/ReactContexify.css';
import { useDispatch, useSelector } from 'react-redux';
import { changeLayerColorAC } from '../../redux/reducers/projectReducer';
import {
  duplicateLayersThunk,
  carefullyRemoveLayersThunk,
  toggleLayersGuideThunk,
  reverseLayersThunk,
} from '../../redux/thunks/layersThunk';
import { ContextMenuIconItem } from './ContextMenuIconItem';
import {
  alignHorizontallyCenterThunk,
  alignHorizontallyLeftThunk,
  alignHorizontallyRightThunk,
  alignVerticallyTopThunk,
  alignVerticallyCenterThunk,
  alignVerticallyBottomThunk,
} from '../../redux/thunks/alignmentThunk';

import alignmentHLeft from '../../img/icons/alignment-h-left.svg';
import alignmentHCenter from '../../img/icons/alignment-h-center.svg';
import alignmentHRight from '../../img/icons/alignment-h-right.svg';
import alignmentVTop from '../../img/icons/alignment-v-top.svg';
import alignmentVCenter from '../../img/icons/alignment-v-center.svg';
import alignmentVBottom from '../../img/icons/alignment-v-bottom.svg';
import { ColorId } from '../../services/ColorsService';
import { hasManySelectedLayersSelector } from '../../redux/selectors/layersSelector';

export const LAYER_CONTEXT_MENU_ID = 'LAYER-CONTEXT-MENU-ID';

export const LayerContextMenu = () => {
  const manySelected = useSelector(hasManySelectedLayersSelector);
  const dispatch = useDispatch();

  const handleDuplicateLayer = ({ props }: ItemParams<{ id: number }>) => {
    if (props && props.id) dispatch(duplicateLayersThunk());
  };

  const handleChangeColor = (color: ColorId) => {
    return ({ props }: ItemParams<{ id: number }>) => {
      if (props && props.id) dispatch(changeLayerColorAC({ layerId: props.id, color: color }));
    };
  };

  const handleReverseLayer = (color: ColorId) => {
    return ({ props }: ItemParams<{ id: number }>) => {
      if (props && props.id) dispatch(changeLayerColorAC({ layerId: props.id, color: color }));
    };
  };

  return (
    <Menu id={LAYER_CONTEXT_MENU_ID}>
      <Item onClick={() => dispatch(toggleLayersGuideThunk(true))}>Set as {manySelected ? 'guides' : 'guide'}</Item>
      <Item onClick={handleDuplicateLayer}>Duplicate {manySelected ? 'layers' : 'layer'}</Item>
      <Submenu label="Align layers" disabled={!manySelected}>
        <Item onClick={() => dispatch(alignHorizontallyLeftThunk())}>
          <ContextMenuIconItem icon={alignmentHLeft} label="Left" />
        </Item>
        <Item onClick={() => dispatch(alignHorizontallyCenterThunk())}>
          <ContextMenuIconItem icon={alignmentHCenter} label="Center" />
        </Item>
        <Item onClick={() => dispatch(alignHorizontallyRightThunk())}>
          <ContextMenuIconItem icon={alignmentHRight} label="Right" />
        </Item>
        <Separator />
        <Item onClick={() => dispatch(alignVerticallyTopThunk())}>
          <ContextMenuIconItem icon={alignmentVTop} label="Top" />
        </Item>
        <Item onClick={() => dispatch(alignVerticallyCenterThunk())}>
          <ContextMenuIconItem icon={alignmentVCenter} label="Center" />
        </Item>
        <Item onClick={() => dispatch(alignVerticallyBottomThunk())}>
          <ContextMenuIconItem icon={alignmentVBottom} label="Bottom" />
        </Item>
      </Submenu>
      <Submenu label="Change color">
        <Item onClick={handleChangeColor('RED')}>Red</Item>
        <Item onClick={handleChangeColor('GREEN')}>Green</Item>
        <Item onClick={handleChangeColor('BLUE')}>Blue</Item>
        <Item onClick={handleChangeColor('ORANGE')}>Orange</Item>
      </Submenu>
      <Item onClick={() => dispatch(reverseLayersThunk())}>Reverse {manySelected ? 'layers' : 'layer'}</Item>
      <Separator />
      <Item onClick={() => dispatch(carefullyRemoveLayersThunk())}>Delete {manySelected ? 'layers' : 'layer'}</Item>
    </Menu>
  );
};
