import { RefObject, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveFrameAC } from '../../../../redux/reducers/projectReducer';
import { compLCWSelector, compScaleSelector } from '../../../../redux/selectors/compositionSelector';

type PropsType = {
  scrollLeft: number;
  duration: number;
  currentFrameHandleRef: RefObject<HTMLDivElement>;
};

export const TfsButtons = ({ currentFrameHandleRef, scrollLeft, duration }: PropsType) => {
  const compScale = useSelector(compScaleSelector);
  const dispatch = useDispatch();
  const [frames, setFrames] = useState<number[]>([]);

  useEffect(() => {
    let temp = [];
    for (let i = 0; i < duration; i++) {
      temp.push(i);
    }
    setFrames(temp);
  }, [duration]);

  const handleFrameClick = (frame: number) => {
    // * Trigger mousedown event of frame selector line!

    if (currentFrameHandleRef.current) {
      currentFrameHandleRef.current.dispatchEvent(
        new MouseEvent('mousedown', {
          view: window,
          bubbles: true,
          cancelable: true,
          buttons: 1,
        }),
      );
    }

    dispatch(setActiveFrameAC({ activeFrame: frame }));
  };

  return (
    <div className="timeline-frame-selector__btns no-deselect-layer">
      {frames.map((frame) => (
        <button key={`selector-btn-${frame}`} style={{ width: compScale }} onMouseDown={() => handleFrameClick(frame)}>
          {compScale >= 0 && frame % 5 === 0 && <div className="label">{frame}</div>}
        </button>
      ))}
      {compScale >= 10 &&
        frames.map((frame) => <TfsButtonsSeparator key={`separator-${frame}`} frame={frame} scale={compScale} scrollLeft={scrollLeft} />)}
    </div>
  );
};

type SeparatorType = {
  frame: number;
  scale: number;
  scrollLeft: number;
};
export const TfsButtonsSeparator = ({ frame, scale, scrollLeft }: SeparatorType) => {
  const left = -scrollLeft + frame * scale;
  return <div key={`selector-separator-${frame}`} className="separator" style={{ left: left }} />;
};
