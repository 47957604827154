import { RefObject } from 'react';
import { useSelector } from 'react-redux';
import { projectSelector } from '../../../redux/selectors/projectSelector';

type PropsType = {
  id: number;
  styles: any;
  imageSequenceId: number;
  imageIds: number[];
  activeFrame: number;
  elementRef: RefObject<HTMLDivElement>;
};

export const ImageSequenceRenderElement = (props: PropsType) => {
  const imageSequences = useSelector(projectSelector).resources.imageSequences;

  const targetIS = imageSequences.find((is) => is.id === props.imageSequenceId);
  if (!targetIS) return <></>;

  const height = props.styles.height;

  const translateY = `translateY(-${props.activeFrame * height}px)`;

  return (
    <div className={`element element-${props.id} image-sequence`} style={props.styles} ref={props.elementRef}>
      <div style={{ transform: translateY }}>
        {props.imageIds.map((imgId, idx) => {
          const targetImg = targetIS.images.find((im) => im.id === imgId);
          if (!targetImg) return <></>;

          return (
            <img
              className="image-sequence-frame"
              key={`layer-${props.id}-frame-${idx}`}
              alt={`Frame ${idx}`}
              draggable={false}
              style={
                {
                  // height: idx === props.activeFrame ? 'inherit' : '1px',
                  // width: idx === props.activeFrame ? 'inherit' : '1px',
                }
              }
              src={targetImg.content}
            />
          );
        })}
      </div>
    </div>
  );
};
