import { useSelector } from 'react-redux';
import blueEclipse from '../../../img/loaders/blue-eclipse.svg';
import { AppState } from '../../../redux/AppState';

export const FullLoader = () => {
  const fpl = useSelector((state: AppState) => state.app.fullPageLoader);

  if (!fpl.visible) return <></>;

  return (
    <div className="full-loader">
      <div className="content">
        <img src={blueEclipse} alt="Loading" />
        <span className="title">{fpl.title}</span>
        {fpl.subtitle && <span className="subtitle">{fpl.subtitle}</span>}
      </div>
    </div>
  );
};
