import classNames from 'classnames';

type PropsType = {
  id?: string;
  label: string;
  loading?: boolean;
  type?: 'submit' | 'button';
  className?: string;
  theme?: 'primary' | 'warning' | 'danger';
  onClick?: () => any;
};

export const SmartBtn = (props: PropsType) => {
  return (
    <button
      id={props.id}
      onClick={props.onClick}
      type={props.type}
      className={classNames({
        btn: true,
        smart: true,
        loading: props.loading,
        [props.className!]: props.className,
        primary: !props.theme,
        [props.theme!]: props.theme,
      })}
      disabled={props.loading}
    >
      {props.loading && <SpinnerPulse />}
      {props.label}
    </button>
  );
};

const SpinnerPulse = () => (
  <div className="loader">
    <div className="loadingio-spinner-pulse-0f0use9xt5gt">
      <div className="ldio-yl0iikb1teo">
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  </div>
);
