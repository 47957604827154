import { useEffect, useRef, useState } from 'react';
import { ColorPicker } from '../color-picker/ColorPicker';
import { InputWrapper } from './InputWrapper';

type PropsType = {
  name: string;
  label: string;
  value: string | number | undefined;
  onChange: (color: string) => void;
};

export const InputColor = (props: PropsType) => {
  const [isOpen, setOpen] = useState(false);
  const nodeRef = useRef(null);

  useEffect(() => {
    document.addEventListener('mousedown', (e: any) => {
      if (!e.path) {
        setOpen(false);
        return;
      }
      const found = e.path.find((clicked: any) => clicked === nodeRef.current);
      if (!found) {
        setOpen(false);
      }
    });
  }, []);

  const color = typeof props.value === 'string' ? props.value : '#000000';

  return (
    <InputWrapper label={props.label} name={props.name}>
      <div className="color-pattern" style={{ backgroundColor: color }} onClick={() => setOpen(!isOpen)} />
      <div className="color-holder" ref={nodeRef}>
        {isOpen && <ColorPicker color={color} onChange={props.onChange} />}
      </div>
    </InputWrapper>
  );
};
