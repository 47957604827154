import { useDispatch, useSelector } from 'react-redux';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-javascript';
import 'ace-builds/src-noconflict/theme-monokai';
import 'ace-builds/src-noconflict/ext-language_tools';

import { AppState } from '../../redux/AppState';
import { Popup } from '../common/popup/Popup';
import { Action } from '../../models/composition';
import { useState } from 'react';
import { setActionCodeAC } from '../../redux/reducers/projectReducer';
import { setPopupAC } from '../../redux/reducers/appReducer';
import { compSelector } from '../../redux/selectors/compositionSelector';

export const ActionEditor = () => {
  const composition = useSelector(compSelector);
  const actionId = useSelector((state: AppState) => state.app.actionIdEditor);
  const dispatch = useDispatch();
  const [codeContent, setCodeContent] = useState('');
  const [changed, setChanged] = useState(false);
  const [foundAction, setFoundAction] = useState<Action | undefined>();

  const handleOnChange = (value: string) => {
    setCodeContent(value);
    setChanged(true);
  };

  const handleSaveSubmit = () => {
    if (!actionId) return;
    dispatch(setActionCodeAC({ actionId: actionId, code: codeContent }));
    setChanged(false);
  };

  const handleSaveAndCloseSubmit = () => {
    if (!actionId) return;
    dispatch(setActionCodeAC({ actionId: actionId, code: codeContent }));
    dispatch(setPopupAC({ popupId: 'actionEditor', open: false }));
  };

  if (!actionId) return <></>;

  if (!foundAction) {
    for (const action of composition.actions) {
      if (action.id === actionId) {
        setFoundAction(action);
        setCodeContent(action.code);
        break;
      }
    }
  }

  if (!foundAction) return <></>;

  return (
    <Popup id="actionEditor" title={`Action editor${changed ? ' - NOT SAVED' : ''}`}>
      <div className="editor">
        <div className="editor__code">
          <AceEditor
            mode="javascript"
            value={codeContent}
            theme="monokai"
            name="action-editor-input"
            onChange={handleOnChange}
            editorProps={{ $blockScrolling: true }}
            setOptions={{
              enableBasicAutocompletion: true,
              enableLiveAutocompletion: true,
              enableSnippets: true,
              showLineNumbers: true,
              tabSize: 2,
              fontSize: 14,
            }}
          />
        </div>
        <div className="editor__controls">
          <button autoFocus className="btn large" onClick={handleSaveSubmit}>
            Save
          </button>
          <button autoFocus className="btn large" onClick={handleSaveAndCloseSubmit}>
            Save and Close
          </button>
        </div>
      </div>
    </Popup>
  );
};
