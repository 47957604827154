import { RefObject } from 'react';
import { useSelector } from 'react-redux';
import { AppState } from '../../../redux/AppState';
import { projectSelector } from '../../../redux/selectors/projectSelector';

type PropsType = {
  id: number;
  styles: any;
  svgId: number;
  activeFrame: number;
  elementRef: RefObject<HTMLDivElement>;
};

export const SvgRenderElement = (props: PropsType) => {
  const svgs = useSelector(projectSelector).resources.svgs;

  const svg = svgs.find((svg) => svg.id === props.svgId);
  if (!svg) return <></>;

  return (
    <div
      className={`element element-${props.id} svg`}
      style={props.styles}
      ref={props.elementRef}
      dangerouslySetInnerHTML={{ __html: svg.content }}
    ></div>
  );
};
