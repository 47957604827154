type PropsType = {
  leftChildren: React.ReactNode;
  rightChildren: React.ReactNode;
};

export const AuthLayout = (props: PropsType) => {
  return (
    <div className="auth-layout">
      <div className="left">{props.leftChildren}</div>
      <div className="right">{props.rightChildren}</div>
    </div>
  );
};
