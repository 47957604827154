export class ParserService {
  static convertToNativeType = (input: string | number) => {
    if (typeof input === 'string') {
      const parsed = parseFloat(input);
      if (isNaN(parsed)) {
        // It's a string
        return input;
      } else {
        return parsed;
      }
    } else {
      // It's type is already a number
      return input;
    }
  };
}
