import { Dispatch } from 'react';
import { Project } from '../models/project';
import { removeFullPageLoaderAC, setFullPageLoaderAC, setLastSavedAC, toggleTouchedAC } from '../redux/reducers/appReducer';
import { loadProjectThunk } from '../redux/thunks/projectThunk';
import { ExportService } from './ExportService';

export class ImportService {
  static async handleImportProject(dispatch: Dispatch<any>) {
    const fileHandles = await (window as any).showOpenFilePicker(ExportService.options);
    const importFileHandle = fileHandles[0];

    const singleFile = await importFileHandle.getFile();
    const fileName = singleFile.name;

    const fr = new FileReader();

    // Set this file handle as handle for saving changes
    ExportService.projectFileHandle = importFileHandle;

    fr.onload = function () {
      const projectString = fr.result as string;
      const project = JSON.parse(projectString) as Project;
      project.name = fileName;
      dispatch(setFullPageLoaderAC({ visible: true, title: 'Loading project', subtitle: '' }));
      setTimeout(() => {
        dispatch(loadProjectThunk(project));
        dispatch(setLastSavedAC(new Date().getTime()));
        dispatch(toggleTouchedAC(false));
        dispatch(removeFullPageLoaderAC());
      }, 100);
    };
    fr.readAsText(singleFile);
  }
}
