import ReactTooltip from 'react-tooltip';

export const Tooltip = () => {
  return (
    <ReactTooltip
      className="tooltip"
      delayShow={250}
      effect="solid"
      offset={{ top: -4 }}
      backgroundColor={'#1c1f2d'}
      arrowColor={'#1c1f2d'}
    />
  );
};
