import { Dispatch } from '@reduxjs/toolkit';

type ImageSize = {
  width: number;
  height: number;
};

type VideoSizeAndDuration = {
  width: number;
  height: number;
  duration: number;
};

export class UtilsService {
  static dispatch: Dispatch<any>;

  static initDispatch(dispatch: Dispatch<any>) {
    UtilsService.dispatch = dispatch;
  }

  static getImageSize(image: string) {
    return new Promise<ImageSize>((resolve) => {
      const element = document.createElement('img');
      element.setAttribute('src', image);

      // element.style.display = 'none';
      document.body.appendChild(element);

      element.addEventListener('load', () => {
        const size = {
          width: element.naturalWidth,
          height: element.naturalHeight,
        };
        resolve(size);
        document.body.removeChild(element);
      });
    });
  }

  static getVideoSizeAndDuration(video: string) {
    return new Promise<VideoSizeAndDuration>((resolve) => {
      const videoElement = document.createElement('video');
      videoElement.setAttribute('autoplay', 'false');

      const sourceElement = document.createElement('source');
      sourceElement.src = video;
      videoElement.appendChild(sourceElement);

      document.body.appendChild(videoElement);

      videoElement.addEventListener('loadeddata', () => {
        const sAndD = {
          width: videoElement.videoWidth,
          height: videoElement.videoHeight,
          duration: videoElement.duration,
        };
        resolve(sAndD);
        document.body.removeChild(videoElement);
      });
    });
  }
}
