import { useSelector } from 'react-redux';
import { AppState } from '../../redux/AppState';
import { CasparCGExporterSettings } from './CasparCGExporterSettings';
import { ActionEditor } from './ActionEditor';
import { AccountPopup } from './AccountPopup';
import { FeedbackPopup } from './FeedbackPopup';
import { CompositionActionEditor } from './CompositionActionEditor';
import { WelcomePopup } from './WelcomePopup';
import { useLocation } from 'react-router-dom';
import { AboutPopup } from './about/AboutPopup';
import { KeyboardShortcutsPopup } from './keyboard-shortcuts/KeyboardShortcutsPopup';

export const Popups = () => {
  const popup = useSelector((state: AppState) => state.app.popup);
  const location = useLocation();

  if (location.pathname !== '/') {
    return <></>;
  } else {
    return (
      <div>
        {popup.casparcgExporterSettings && <CasparCGExporterSettings />}
        {popup.actionEditor && <ActionEditor />}
        {popup.compositionActionEditor && <CompositionActionEditor />}
        {popup.account && <AccountPopup />}
        {popup.feedback && <FeedbackPopup />}
        {popup.welcome && <WelcomePopup />}
        {popup.about && <AboutPopup />}
        {popup.keyboardShortcuts && <KeyboardShortcutsPopup />}
      </div>
    );
  }
};
