import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { appReducer, toggleTouchedAC } from './reducers/appReducer';
import { notificationsReducer } from './reducers/notificationReducer';
import { projectReducer } from './reducers/projectReducer';
import { userReducer } from './reducers/userReducer';
import undoable, { excludeAction } from 'redux-undo';

/**
 * These actions will not impact project save warning (app.touched).
 */
const nonTouchableActions = [
  'notifications/setNotificationAC',
  'notifications/removeNotificationAC',
  'user/setUserAC',
  'user/removeUserAC',
  'app/setPopupAC',
  'app/setModalAC',
  'app/toggleTouchedAC',
  'app/setFullPageLoaderAC',
  'app/removeFullPageLoaderAC',
  'app/setLastSaved',
  'project/setProjectAC',
  'project/setCompositionAC',
  'project/setActiveFrameAC',
  'project/selectLayerAC',
  'project/deselectAllLayersAC',
];

/**
 *
 * These actions will not be undoable.
 */
const nonUndoableActions = ['project/setActiveFrameAC', 'project/selectLayerAC', 'project/deselectAllLayersAC'];

const reducer = combineReducers({
  user: userReducer,
  project: undoable(projectReducer, { filter: excludeAction(nonUndoableActions) }),
  app: appReducer,
  notifications: notificationsReducer,
});

/**
 * Middleware that detects if an action requires project save warning (app.touched).
 */
const touchDetectorMiddleware = (storeAPI: any) => (next: any) => (action: any) => {
  const state = storeAPI.getState();

  if (!state.app.touched && nonTouchableActions.indexOf(action.type) < 0) {
    storeAPI.dispatch(toggleTouchedAC(true));
  }

  let result = next(action);
  return result;
};

export default configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(touchDetectorMiddleware),
  devTools: true,
});
