import classNames from 'classnames';

type PropsType = {
  type: 'warning' | 'error';
  message: string;
};

export const Inforow = (props: PropsType) => {
  return (
    <div className={classNames({ inforow: true, error: props.type === 'error', warning: props.type === 'warning' })}>
      <div className="icon"></div>
      <div className="message">{props.message}</div>
    </div>
  );
};
