export enum PASSWORD_ERRORS {
  MIN_8_CHARS = '0',
  LETTERS_AND_NUMBERS = '1',
  PASSWORD_MATCH = '3',
}

export const validatePassword = (values: { password: string; passwordConfirmation: string }) => {
  const password = values.password;
  const passwordConfirmation = values.passwordConfirmation;
  let passwordErrors = '';
  let passwordsNotEqual = false;

  let errors: any = {};

  if (!password) {
    passwordErrors += PASSWORD_ERRORS.MIN_8_CHARS + PASSWORD_ERRORS.PASSWORD_MATCH;
    errors.password = 'Password is required';
    return errors;
  }

  if (password.length < 8 || password.length > 20) {
    passwordErrors += PASSWORD_ERRORS.MIN_8_CHARS;
    errors.password = 'Password must be between 8 and 20 characters long';
    return errors;
  }

  if (!/\d/.test(password)) {
    passwordErrors += PASSWORD_ERRORS.LETTERS_AND_NUMBERS;
    errors.password = 'Password must be contain at least one number';
    return errors;
  }

  if (!/[a-zA-Z]/.test(password)) {
    passwordErrors += PASSWORD_ERRORS.LETTERS_AND_NUMBERS;
    errors.password = 'Password must be contain at least one letter';
    return errors;
  }

  if (password !== passwordConfirmation) {
    passwordErrors += PASSWORD_ERRORS.PASSWORD_MATCH;
    passwordsNotEqual = true;
    errors.passwordConfirmation = 'Passwords do not match';
    return errors;
  }

  // If there are some errors
  if (passwordErrors.length > 0) {
    errors.password = passwordErrors;
    if (passwordsNotEqual || !password) errors.passwordConfirmation = true;
  }

  return errors;
};
