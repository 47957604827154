let fpsInterval: number, now: number, then: number, elapsed: number;
let stop = true;
let callbackFunction: any;

export function animate() {
  now = Date.now();
  elapsed = now - then;

  if (elapsed > fpsInterval) {
    if (elapsed > fpsInterval * 2) console.error('Framerate dropped');
    then = now - (elapsed % fpsInterval);
    callbackFunction();
  }
  if (stop) return;
  (window.requestAnimationFrame as any)(animate);
}

export function startAnimation(fps: number, cb: any) {
  callbackFunction = cb;
  fpsInterval = 1000 / fps;
  then = Date.now();
  stop = false;
  animate();
}

export function stopAnimation() {
  stop = true;
}
