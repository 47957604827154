import classNames from 'classnames';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../redux/AppState';
import menubarLogo from '../../img/logos/logo-symbol.png';
import { compSelector } from '../../redux/selectors/compositionSelector';
import { projectSelector } from '../../redux/selectors/projectSelector';
import { AuthBar } from './authbar/AuthBar';
import { MenuBar } from './menubar/MenuBar';

type MenuBarProps = {};

export const AppBar = (props: MenuBarProps) => {
  const app = useSelector((state: AppState) => state.app);
  const project = useSelector(projectSelector);

  const [lastSaveDiff, setLastSaveDiff] = useState<number | undefined>(undefined);

  /**
   * Display when was last save made
   */
  useEffect(() => {
    const cb = (handle?: number) => {
      if (!app.lastSaved) {
        setLastSaveDiff(undefined);
      } else {
        const diff = moment(moment()).diff(app.lastSaved, 'minutes');
        setLastSaveDiff(diff);
      }
    };
    cb();
    const interval = setInterval(cb, 10000);

    return () => {
      clearInterval(interval);
    };
  }, [app.lastSaved]);

  const [hide, setHide] = useState(false);

  return (
    <div
      className={classNames({ appbar: true, hideDropdown: hide, 'no-deselect-layer': true })}
      onMouseUp={(e) => {
        if ((e.target as any).parentNode.parentNode.classList.contains('disabled')) {
          // Button is disabled, ignore it
        } else {
          setHide(true);
        }
      }}
      onMouseMove={() => {
        setHide(false);
      }}
    >
      <img className="logo" src={menubarLogo} alt="Loopic" />

      <MenuBar />

      <div className="project-meta">
        <div className={classNames({ 'project-meta__title': true, touched: app.touched })}>{project.name}</div>
        {app.touched && lastSaveDiff === undefined && <div className="project-meta__last-saved">- NOT SAVED</div>}
        {app.touched && lastSaveDiff !== undefined && <div className="project-meta__last-saved">- last saved {lastSaveDiff} min ago</div>}
      </div>

      <AuthBar />
    </div>
  );
};
