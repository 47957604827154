import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { AppState } from '../../redux/AppState';
import { compHeightSelector, compWidthSelector } from '../../redux/selectors/compositionSelector';
import { RendererControlsSection } from './RendererControlsSection';
import { RenderSection } from './RenderSection';

export const RendererPanel = () => {
  const compositionWidth = useSelector(compWidthSelector);
  const compositionHeight = useSelector(compHeightSelector);

  const [renderPosition, setRenderPosition] = useState<{ x: number; y: number }>({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);

  const renderSectionRef = useRef<HTMLDivElement>(null);
  const renderRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    handleFitCanvas();
  }, [compositionWidth, compositionHeight]);

  /**
   * Function fits the canvas in the center of the render section.
   */
  const handleFitCanvas = () => {
    if (!renderSectionRef.current) return;
    if (!compositionWidth || !compositionHeight) return;

    const renderSectionElBounds = renderSectionRef.current.getBoundingClientRect();

    // Detect zoom level
    const verticalZoomLevel = renderSectionElBounds.height / compositionHeight;
    const horizontalZoomLevel = renderSectionElBounds.width / compositionWidth;
    const newZoom = verticalZoomLevel < horizontalZoomLevel ? verticalZoomLevel : horizontalZoomLevel;
    handleZoom(newZoom);

    // Detect center position
    const horizontalSpaceBetween = renderSectionElBounds.width - compositionWidth * newZoom;
    const verticalSpaceBetween = renderSectionElBounds.height - compositionHeight * newZoom;

    const deltaWidth = compositionWidth - compositionWidth * newZoom;
    const deltaHeight = compositionHeight - compositionHeight * newZoom;
    setRenderPosition({ x: -deltaWidth / 2 + horizontalSpaceBetween / 2, y: -deltaHeight / 2 + verticalSpaceBetween / 2 });
  };

  /**
   * Function zooms render element in our out.
   * Calculates origin by the center of the render section.
   * Changes both zoom and position to keep everything perfectly in center.
   * @param newZoom New zoom level
   * @returns
   */
  const handleZoom = (newZoom: number) => {
    if (newZoom < 0.05 || newZoom > 2) return;
    if (!renderSectionRef.current || !renderRef.current) return;

    const renderSectionElBounds = renderSectionRef.current.getBoundingClientRect();
    const renderElBounds = renderRef.current.getBoundingClientRect();

    // Center of RENDER-SECTION
    const centerX = renderSectionElBounds.x + renderSectionElBounds.width / 2;
    const centerY = renderSectionElBounds.y + renderSectionElBounds.height / 2;

    // Center in the RENDER element
    const left = centerX - renderElBounds.x;
    const top = centerY - renderElBounds.y;

    // Scale
    let finalX = left / zoom;
    let finalY = top / zoom;

    // More magic
    const oldDistanceX = (compositionWidth / 2 - finalX) * zoom;
    const oldDistanceY = (compositionHeight / 2 - finalY) * zoom;
    const newDistanceX = (compositionWidth / 2 - finalX) * newZoom;
    const newDistanceY = (compositionHeight / 2 - finalY) * newZoom;
    const deltaX = newDistanceX - oldDistanceX;
    const deltaY = newDistanceY - oldDistanceY;

    setRenderPosition({ x: renderPosition.x + deltaX, y: renderPosition.y + deltaY });
    setZoom(newZoom);
  };

  return (
    <div id="main-renderer-panel" className="renderer-panel">
      <RendererControlsSection
        currentZoom={zoom}
        onZoom={(factor: number) => {
          handleZoom(factor);
        }}
        onFitCanvas={handleFitCanvas}
      />
      <RenderSection
        zoom={zoom}
        onZoom={handleZoom}
        renderPosition={renderPosition}
        onSetRenderPosition={(x, y) => setRenderPosition({ x, y })}
        renderSectionRef={renderSectionRef}
        renderRef={renderRef}
      />
    </div>
  );
};
