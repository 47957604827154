import { Helmet } from 'react-helmet';
import { PopupPageLayout } from '../components/common/layouts/popup-page-layout/PopupPageLayout';
import { LoginPopup } from '../components/popups/LoginPopup';
import { LOOPIC_CONFIG } from '../config/LOOPIC_CONFIG';
import { SeoService } from '../services/SeoService';

export const LoginPage = () => (
  <PopupPageLayout>
    <Helmet>
      <title>{SeoService.getPageTitle('Log in')}</title>
    </Helmet>
    <LoginPopup disableClose={!LOOPIC_CONFIG.allowAccessWithoutRegistration} />
  </PopupPageLayout>
);
