import { Font } from '../../../models/resources';

export const fontsJSBuilder = (fonts: Font[]) => {
  let fontsString = '';
  for (const font of fonts) {
    const loadString = `
      new FontFace("${font.name}", base64ToArrayBuffer("${font.content}")).load().then((loadFace) => document.fonts.add(loadFace));
    `;
    fontsString += loadString;
  }

  return fontsString;
};
