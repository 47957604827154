import { PropertyData } from '../models/composition';
import { Project } from '../models/project';
import { EasingService } from './EasingService';
import { PropertyId } from './PropertySettingsService';

export class ProjectCompatibility {
  static makeSureItsCompatible(project: Project) {
    // Make sure all comps have selectedLayers array
    for (const comp of project.compositions) {
      if (!comp.selectedLayers) {
        comp.selectedLayers = [];
      }
    }

    // Make old projects without workspaceDuration work
    for (const comp of project.compositions) {
      if (!comp.workspaceDuration) {
        comp.workspaceDuration = comp.duration;
        comp.duration = 200;
      }
    }

    // Make sure there is activeCompositionId property
    if (project.activeCompositionId === undefined) project.activeCompositionId = 1;

    // Make sure all keyframes have easings
    const linear = EasingService.findByPresetName('linear');
    const easing = {
      presetName: linear.presetName,
      p1x: linear.p1x,
      p1y: linear.p1y,
      p2x: linear.p2x,
      p2y: linear.p2y,
    };
    for (const comp of project.compositions) {
      for (const layer of comp.layers) {
        for (const propId in layer.properties) {
          const propertyId = propId as PropertyId;
          const propertyData = layer.properties[propertyId]!;
          const keyframes = propertyData.keyframes;

          for (const kf of keyframes) {
            if (!kf.easing) {
              kf.easing = easing;
            }
          }
        }
      }
    }
  }
}
