import { useDispatch, useSelector } from 'react-redux';
import { ImageResourceThunks } from '../../redux/thunks/resources/ImageResourceThunks';
import { VideoResourceThunks } from '../../redux/thunks/resources/VideoResourceThunks';
import { compSelector } from '../../redux/selectors/compositionSelector';
import { projectSelector } from '../../redux/selectors/projectSelector';
import { changeCompositionThunk, newCompositionThunk } from '../../redux/thunks/compositionThunk';
import { FontService } from '../../services/FontService';
import { PanelLayout } from '../common/panel-layout/PanelLayout';
import { CompositionContextMenu, COMPOSITION_CONTEXT_MENU_ID } from '../context-menu/CompositionContextMenu';
import { FontContextMenu, FONT_CONTEXT_MENU_ID } from '../context-menu/FontContextMenu';
import { ImageContextMenu, IMAGE_CONTEXT_MENU_ID } from '../context-menu/ImageContextMenu';
import { ImageSequenceContextMenu, IMAGE_SEQUENCE_CONTEXT_MENU_ID } from '../context-menu/ImageSequenceContextMenu';
import { VideoContextMenu, VIDEO_CONTEXT_MENU_ID } from '../context-menu/VideoContextMenu';
import { ResourceSection } from './ResourceSection';
import { ImageSequenceResourceThunks } from '../../redux/thunks/resources/ImageSequenceResourceThunks';

const replaceLastDigits = (data: string) => {
  let dataCopy = data;
  let foundNumber = false;

  const replaceChar = (data: string, index: number, replacement: string) => {
    return data.substr(0, index) + replacement + data.substr(index + replacement.length);
  };

  for (let i = dataCopy.length - 1; i >= 0; i--) {
    const currentChar = dataCopy[i];
    const parsed = parseInt(currentChar);
    if (isNaN(parsed)) {
      if (foundNumber) break;
    } else {
      if (!foundNumber) foundNumber = true;
      dataCopy = replaceChar(dataCopy, i, '#');
    }
  }
  return dataCopy;
};

export const ResourcesPanel = () => {
  const project = useSelector(projectSelector);
  const composition = useSelector(compSelector);
  const dispatch = useDispatch();
  const projectFonts = project?.resources?.fonts;

  return (
    <PanelLayout id="resources" title="Resources">
      <ResourceSection
        title="Compositions"
        type="compositions"
        controls={[{ label: 'Create new composition', onClick: () => dispatch(newCompositionThunk()), type: 'plus' }]}
        onItemClick={(id: number) => {
          dispatch(changeCompositionThunk(id));
        }}
        contextMenuId={COMPOSITION_CONTEXT_MENU_ID}
        content={project.compositions?.map((pComp) => ({ id: pComp.id, name: pComp.name, active: pComp.id === composition.id }))}
      />
      <ResourceSection
        title="Images"
        type="images"
        controls={[{ label: 'Import new image', onClick: () => dispatch(ImageResourceThunks.import()), type: 'plus' }]}
        contextMenuId={IMAGE_CONTEXT_MENU_ID}
        content={project.resources?.images.map((item) => {
          return { id: item.id, name: item.name, active: false, hoverable: true };
        })}
      />
      <ResourceSection
        title="Image Sequences"
        type="image-sequences"
        controls={[{ label: 'Import new image sequence', onClick: () => dispatch(ImageSequenceResourceThunks.import()), type: 'plus' }]}
        contextMenuId={IMAGE_SEQUENCE_CONTEXT_MENU_ID}
        content={project.resources?.imageSequences.map((is) => {
          // Check filename
          const firstName = is.images[0].name;
          return { id: is.id, name: replaceLastDigits(firstName), active: false, hoverable: true };
        })}
      />
      {/* <ResourceSection
        title="Videos"
        type="videos"
        controls={[{ label: 'Import new video', onClick: () => dispatch(VideoResourceThunks.import()), type: 'plus' }]}
        contextMenuId={VIDEO_CONTEXT_MENU_ID}
        content={project.resources?.videos.map((item) => {
          return { id: item.id, name: item.name, active: false, hoverable: true };
        })}
      /> */}
      <ResourceSection
        title="Fonts"
        type="fonts"
        controls={[{ label: 'Import new font', onClick: () => FontService.handleImportFont(dispatch, projectFonts), type: 'plus' }]}
        contextMenuId={FONT_CONTEXT_MENU_ID}
        content={project?.resources?.fonts?.map((font) => ({ id: font.id, name: font.name, hoverable: true }))}
      />
      <VideoContextMenu />
      <CompositionContextMenu />
      <ImageContextMenu />
      <ImageSequenceContextMenu />
      <FontContextMenu />
    </PanelLayout>
  );
};
