import { Item, ItemParams, Menu } from 'react-contexify';
import 'react-contexify/dist/ReactContexify.css';
import { useDispatch } from 'react-redux';
import { ImageResourceThunks } from '../../redux/thunks/resources/ImageResourceThunks';

export const IMAGE_CONTEXT_MENU_ID = 'IMAGE-CONTEXT-MENU-ID';

export const ImageContextMenu = () => {
  const dispatch = useDispatch();

  const handleDelete = ({ props }: ItemParams<{ id: number }>) => {
    if (props && props.id) dispatch(ImageResourceThunks.remove(props.id));
  };

  const importAsLayer = ({ props }: ItemParams<{ id: number }>) => {
    if (props && props.id) dispatch(ImageResourceThunks.toLayer(props.id));
  };

  return (
    <Menu id={IMAGE_CONTEXT_MENU_ID}>
      <Item onClick={importAsLayer}>Import as layer</Item>
      <Item onClick={handleDelete}>Delete image</Item>
    </Menu>
  );
};
