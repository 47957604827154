import { RefObject, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { useSelector } from 'react-redux';
import { Layer } from '../../models/composition';
import { AppState } from '../../redux/AppState';
import { ElementControls } from './ElementControls';
import { CompositionRenderElement } from './renders/CompositionRenderElement';
import { DragRenderElement } from './renders/DragRenderElement';
import { ImageLoaderRenderElement } from './renders/ImageLoaderRenderElement';
import { ImageRenderElement } from './renders/ImageRenderElement';
import { ImageSequenceRenderElement } from './renders/ImageSequenceRenderElement';
import { ShapeRenderElement } from './renders/ShapeRenderElement';
import { TextRenderElement } from './renders/TextRenderElement';
import { SvgRenderElement } from './renders/SvgRenderElement';
import { compActiveFrameSelector } from '../../redux/selectors/compositionSelector';
import { VideoRenderElement } from './renders/VideoRenderElement';

type PropsType = {
  layer: Layer;
  styles: any;
  /**
   * zoom and renderSectionRef are optional because
   * they are only used for element dragging and controlling
   * which is not used for nested compositing.
   */
  nested: boolean;
  globalFrameOffset: number;
  zoom?: number;
  renderSectionRef?: RefObject<HTMLDivElement>;
};

export const RenderLayer = ({ layer, zoom, renderSectionRef, styles, nested, globalFrameOffset }: PropsType) => {
  const playMode = useSelector((state: AppState) => state.app.playMode);
  const compositionActiveFrame = useSelector(compActiveFrameSelector);
  const [elCtrlRefreshFlag, setElCtrlRefreshFlag] = useState(false);
  const [isHover, setHover] = useState(false);

  const elRef = useRef(null);

  let domEl;

  const activeFrameOnLayer = compositionActiveFrame - layer.startFrame - globalFrameOffset;

  if (!styles) return <></>;

  const activeFrameStyles = styles[activeFrameOnLayer];
  if (!activeFrameStyles) <></>;

  // Layer not active yet
  if (activeFrameOnLayer < 0) {
    return <></>;
  }

  // Layer not active anymore
  if (activeFrameOnLayer >= layer.duration) {
    return <></>;
  }

  if (layer.element.type === 'COMPOSITION') {
    domEl = (
      <CompositionRenderElement
        id={layer.id}
        globalFrameOffset={globalFrameOffset + layer.startFrame}
        compositionId={layer.element.compositionId}
        styles={{ ...activeFrameStyles, top: null, left: null }}
        elementRef={elRef}
      />
    );
  } else if (layer.element.type === 'TEXT') {
    domEl = (
      <TextRenderElement
        id={layer.id}
        styles={{ ...activeFrameStyles, top: null, left: null }}
        content={layer.element.content}
        elementRef={elRef}
      />
    );
  } else if (layer.element.type === 'IMAGE_SEQUENCE') {
    domEl = (
      <ImageSequenceRenderElement
        id={layer.id}
        styles={{ ...activeFrameStyles, top: null, left: null }}
        imageSequenceId={layer.element.imageSequenceId}
        imageIds={layer.element.imageIds}
        activeFrame={activeFrameOnLayer}
        elementRef={elRef}
      />
    );
  } else if (layer.element.type === 'VIDEO') {
    domEl = (
      <VideoRenderElement
        id={layer.id}
        styles={{ ...activeFrameStyles, top: null, left: null }}
        videoId={layer.element.videoId}
        activeFrame={activeFrameOnLayer}
        elementRef={elRef}
      />
    );
  } else if (layer.element.type === 'IMAGE') {
    domEl = (
      <ImageRenderElement
        id={layer.id}
        styles={{ ...activeFrameStyles, top: null, left: null }}
        imageId={layer.element.imageId}
        activeFrame={activeFrameOnLayer}
        elementRef={elRef}
      />
    );
  } else if (layer.element.type === 'SVG') {
    domEl = (
      <SvgRenderElement
        id={layer.id}
        styles={{ ...activeFrameStyles, top: null, left: null }}
        svgId={layer.element.svgId}
        activeFrame={activeFrameOnLayer}
        elementRef={elRef}
      />
    );
  } else if (layer.element.type === 'IMAGE_LOADER') {
    domEl = (
      <ImageLoaderRenderElement
        id={layer.id}
        styles={{ ...activeFrameStyles, top: null, left: null }}
        size={layer.element.size}
        placeholderId={layer.element.placeholderId}
        elementRef={elRef}
      />
    );
  } else if (layer.element.type === 'SHAPE') {
    domEl = <ShapeRenderElement id={layer.id} styles={{ ...activeFrameStyles, top: null, left: null }} elementRef={elRef} />;
  }

  // Verify render section reference for the purpose of ElementControls portal
  if (!renderSectionRef?.current && nested === false) {
    console.error('Reference for render section is invalid');
    return <></>;
  }

  /**
   * Performance improvement - don't render element controls if playing
   */
  const portalChild =
    !playMode && zoom !== undefined && nested === false ? (
      <ElementControls
        hover={isHover}
        refreshFlag={elCtrlRefreshFlag}
        zoom={zoom}
        width={activeFrameStyles.width}
        height={activeFrameStyles.height}
        x={activeFrameStyles.x}
        y={activeFrameStyles.y}
        layer={layer}
        elementRef={elRef}
      />
    ) : (
      <></>
    );

  return (
    <div className="layer focus-layer" key={layer.id} tabIndex={0}>
      <div onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
        <DragRenderElement
          onDrag={() => {
            setElCtrlRefreshFlag(!elCtrlRefreshFlag);
          }}
          zoom={zoom}
          x={activeFrameStyles.x}
          y={activeFrameStyles.y}
          layerId={layer.id}
          disabled={layer.locked || nested}
        >
          {domEl}
        </DragRenderElement>
      </div>
      {renderSectionRef?.current && ReactDOM.createPortal(portalChild, renderSectionRef.current)}
    </div>
  );
};
