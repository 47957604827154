import { Popup } from '../common/popup/Popup';
import { InputWrapper } from '../common/inputs/InputWrapper';
import { SmartBtn } from '../common/inputs/SmartBtn';
import { Inforow } from '../common/inputs/Inforow';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { useState } from 'react';
import { setPopupAC } from '../../redux/reducers/appReducer';
import { AppState } from '../../redux/AppState';
import { FeedbackApi } from '../../services/api/FeedbackApi';
import { setNotificationAC } from '../../redux/reducers/notificationReducer';

export const FeedbackPopup = () => {
  const user = useSelector((state: AppState) => state.user);
  const dispatch = useDispatch();
  const [formError, setFormError] = useState('');

  return (
    <Popup id="feedback" title="Feedback">
      <Formik
        initialValues={{
          email: user.email || '',
          feedback: '',
        }}
        validationSchema={Yup.object({
          email: Yup.string().email('Email must be valid').required('Email is required'),
          feedback: Yup.string().required('Feedback is required'),
        })}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            const res = await FeedbackApi.sendFeedback({
              email: values.email,
              feedback: values.feedback,
            });
            dispatch(setNotificationAC({ message: 'Feedback successfully sent', type: 'success' }));
            dispatch(setPopupAC({ popupId: 'login', open: false }));
            setSubmitting(false);
          } catch (error) {
            setSubmitting(false);
            console.log(error);
            setFormError('An error occured');
          }
        }}
      >
        {({ isSubmitting, errors, isValid, touched }) => (
          <Form className="human-form auth">
            <h1>
              <b>Leave</b> feedback
            </h1>
            <p>
              We need your help. Tell us what you like, what you don't like, what you would like to improve, what you think is missing, how
              the Loopic concept can be improved... Really, feel free to send anything what's on your mind.
            </p>
            <InputWrapper label="Email (you may be contacted)" labelPosition="top" name="email" error={touched.email && errors.email}>
              <Field
                type="text"
                name="email"
                autoComplete="username"
                className={classNames({ classic: true, error: touched.email && errors.email })}
              />
            </InputWrapper>
            <InputWrapper label="Feedback" labelPosition="top" name="feedback" error={touched.feedback && errors.feedback}>
              <Field
                component="textarea"
                name="feedback"
                className={classNames({ classic: true, error: touched.feedback && errors.feedback })}
              />
            </InputWrapper>
            {formError && <Inforow type="error" message={formError} />}
            <SmartBtn className="submit" type="submit" label="Send feedback" loading={isSubmitting} />
          </Form>
        )}
      </Formik>
    </Popup>
  );
};
