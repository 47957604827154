import { ChangeEventHandler, FocusEventHandler, FormEventHandler, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Layer, PropertyData } from '../../../../models/composition';
import { AppState } from '../../../../redux/AppState';
import { PropertyHelper } from '../../../../redux/helpers/PropertyHelper';
import { compSelector } from '../../../../redux/selectors/compositionSelector';
import { removeKeyframeThunk, setPropertyValueThunk } from '../../../../redux/thunks/layersThunk';
import { PropertyId, PropertySettings } from '../../../../services/PropertySettingsService';
import { KeyframeSymbol } from '../../KeyframeSymbol';

type PropsType = { layer: Layer; propertyId: PropertyId; propertyData?: PropertyData; propertySettings: PropertySettings };

export const LCProperty = ({ layer, propertyId, propertyData, propertySettings }: PropsType) => {
  const composition = useSelector(compSelector);
  const dispatch = useDispatch();

  const [isFocused, setFocused] = useState(false);
  const [newValue, setNewValue] = useState<string>('');

  const searchForFrame = composition.activeFrame - layer.startFrame;
  const foundKeyframe = propertyData && propertyData.keyframes.find((kf) => kf.frame === searchForFrame);

  const currentValue = PropertyHelper.getPropertyValueForHuman(layer.properties, propertyId, searchForFrame);

  if (currentValue === undefined) return <></>;

  const handleFocus: FocusEventHandler<HTMLInputElement> = (e) => {
    e.target.select();
    setFocused(true);
    if (currentValue) setNewValue(currentValue.toString());
  };

  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    e.preventDefault();
    setNewValue(e.target.value);
  };

  const handleSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    setFocused(false);
    (document as any).activeElement.blur();

    dispatch(setPropertyValueThunk(layer.id, [{ propertyId, value: newValue }]));
  };

  const addNewKeyframe = () => {
    const keyframeValue = isFocused ? newValue : currentValue;
    dispatch(setPropertyValueThunk(layer.id, [{ propertyId, value: keyframeValue }], true));
  };

  if (propertySettings.types && !propertySettings.types.find((pst) => pst === layer.element.type)) {
    return <></>;
  }

  return (
    <div className="lc-property">
      <div className="name">{propertySettings.name}</div>
      <div className="value">
        <form onSubmit={handleSubmit}>
          <input
            className="input"
            type="text"
            onFocus={handleFocus}
            onBlur={() => setFocused(false)}
            onChange={handleChange}
            value={isFocused ? newValue : currentValue}
          />
        </form>
        {typeof currentValue !== 'string' && <div className="value__unit">{propertySettings.unit}</div>}
      </div>
      <div className="keyframe-controls">
        <KeyframeSymbol
          empty={!foundKeyframe}
          onClick={() => {
            if (!foundKeyframe) {
              addNewKeyframe();
            } else {
              dispatch(removeKeyframeThunk({ layerId: layer.id, propertyId, keyframeId: foundKeyframe.id, frame: searchForFrame }));
            }
          }}
        />
      </div>
    </div>
  );
};
