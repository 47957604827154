import { PropertySettings } from '../PropertySettingsService';

export const filterBlur: PropertySettings = {
  name: 'Blur',
  default: 0,
  unit: 'px',
};

export const filterBrightness: PropertySettings = {
  name: 'Brightness',
  default: 1,
  decimals: 2,
  scaleFactor: 100,
  unit: '%',
};

export const filterContrast: PropertySettings = {
  name: 'Contrast',
  default: 1,
  decimals: 2,
  scaleFactor: 100,
  unit: '%',
};

export const filterGrayscale: PropertySettings = {
  name: 'Grayscale',
  default: 0,
  decimals: 2,
  scaleFactor: 100,
  unit: '%',
};
