import lock from '../../../img/icons/lock.svg';
import { MouseEventHandler } from 'react';
import { Tooltip } from '../../common/tooltip/Tooltip';

type PropsType = {
  onClick: MouseEventHandler<HTMLDivElement>;
  locked: boolean;
};

export const LockToggle = (props: PropsType) => {
  return (
    <>
      <div className="layer-toggle" onClick={props.onClick} data-tip={props.locked ? 'Unlock' : 'Lock'}>
        <img src={lock} alt="Locked" style={{ opacity: props.locked ? 1 : 0.5 }} />
      </div>
      <Tooltip />
    </>
  );
};
