import { Layer } from '../../../models/composition';
import { ColorsService } from '../../../services/ColorsService';
import { getActiveComposition } from '../../helpers';
import { addLayerAC } from '../../reducers/projectReducer';
import { projectSelector } from '../../selectors/projectSelector';
import { MyThunkActionReturn } from '../thunkTypes';

/**
 * Creates new composition-layer
 */
export const newCompositionLayerThunk = (compositionId: number): MyThunkActionReturn => {
  return async (dispatch, getState) => {
    const state = getState();
    const project = projectSelector(state);
    const composition = getActiveComposition(project);

    if (project.activeCompositionId === compositionId) {
      console.warn('Can not import composition inside itself');
      // TODO - WARN OR DISABLE THIS POSSIBILITY
      return;
    }

    const found = project.compositions.find((composition) => composition.id === compositionId);
    if (!found) return;

    const newLayer: Layer = {
      id: composition.lastLayerId + 1,
      name: found.name,
      startFrame: 0,
      duration: found.workspaceDuration,
      color: ColorsService.getElementColorId('COMPOSITION'),
      open: false,
      visible: true,
      locked: false,
      guide: false,
      element: {
        type: 'COMPOSITION',
        dynamicId: '',
        compositionId: found.id,
      },
      properties: {
        width: {
          value: found.width,
          keyframes: [],
        },
        height: {
          value: found.height,
          keyframes: [],
        },
      },
      openPropertyGroups: [],
    };

    dispatch(addLayerAC(newLayer));
  };
};
