import { MouseEventHandler } from 'react';
import { useDispatch } from 'react-redux';
import { useContextMenu } from 'react-contexify';
import { Draggable } from 'react-beautiful-dnd';
import { LAYER_CONTEXT_MENU_ID } from '../../../context-menu/LayerContextMenu';
import { selectLayerThunk } from '../../../../redux/thunks/layersThunk';
import { LCLayer } from './LCLayer';

type PropsType = { layerId: number; index: number };

export const LCLayerWrapper = (props: PropsType) => {
  const dispatch = useDispatch();

  const { show } = useContextMenu({
    id: LAYER_CONTEXT_MENU_ID,
  });

  const handleContextMenu: MouseEventHandler<HTMLDivElement> = (event) => {
    event.preventDefault();
    show(event, {
      props: {
        id: props.layerId,
      },
    });
  };

  const handleLayerClick = () => {
    dispatch(selectLayerThunk(props.layerId));
  };

  return (
    <Draggable isDragDisabled={false} key={props.layerId} draggableId={`${props.layerId}`} index={props.index}>
      {(provided) => (
        <div
          className="composition-layer-controls-wrapper"
          onMouseDown={handleLayerClick}
          onContextMenu={handleContextMenu}
          {...provided.draggableProps}
          ref={provided.innerRef}
        >
          <LCLayer dragHandleProps={provided.dragHandleProps} layerId={props.layerId} />
        </div>
      )}
    </Draggable>
  );
};
