import { ActionCreators } from 'redux-undo';
import { ExportService } from '../../services/ExportService';
import { removeFullPageLoaderAC, setFullPageLoaderAC } from '../reducers/appReducer';
import { isProjectRedoableSelector, isProjectUndoableSelector, projectSelector } from '../selectors/projectSelector';
import { MyThunkActionReturn } from './thunkTypes';

export const saveThunk = (): MyThunkActionReturn => {
  return async (dispatch, getState) => {
    const project = projectSelector(getState());

    dispatch(setFullPageLoaderAC({ visible: true, title: 'Saving project' }));
    await ExportService.handleSaveProject(dispatch, project);
    dispatch(removeFullPageLoaderAC());
  };
};

export const saveAsThunk = (): MyThunkActionReturn => {
  return async (dispatch, getState) => {
    const project = projectSelector(getState());

    dispatch(setFullPageLoaderAC({ visible: true, title: 'Saving project' }));
    await ExportService.handleSaveAsProject(dispatch, project);
    dispatch(removeFullPageLoaderAC());
  };
};

export const undoThunk = (): MyThunkActionReturn => {
  return async (dispatch, getState) => {
    const undoImpossible = isProjectUndoableSelector(getState());

    if (undoImpossible) return;
    dispatch(ActionCreators.undo());
  };
};

export const redoThunk = (): MyThunkActionReturn => {
  return async (dispatch, getState) => {
    const redoImpossible = isProjectRedoableSelector(getState());

    if (redoImpossible) return;
    dispatch(ActionCreators.redo());
  };
};
