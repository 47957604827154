import classNames from 'classnames';
import { MouseEventHandler, RefObject, useState, WheelEventHandler } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toggleSpaceDownAC } from '../../redux/reducers/appReducer';
import { AppState } from '../../redux/AppState';
import { RenderLayers } from './RenderLayers';
import { useWindowEvent } from '../../hooks/useWindowEvent';
import {
  compBackgroundSelector,
  compHeightSelector,
  compLayersSelector,
  compWidthSelector,
} from '../../redux/selectors/compositionSelector';

type PropsType = {
  zoom: number;
  onZoom: (zoom: number) => void;
  renderPosition: { x: number; y: number };
  onSetRenderPosition: (x: number, y: number) => void;
  renderSectionRef: RefObject<HTMLDivElement>;
  renderRef: RefObject<HTMLDivElement>;
};

export const RenderSection = ({ zoom, onZoom, renderPosition, onSetRenderPosition, renderSectionRef, renderRef }: PropsType) => {
  const compositionWidth = useSelector(compWidthSelector);
  const compositionHeight = useSelector(compHeightSelector);
  const compLayers = useSelector(compLayersSelector);
  const compositionBackground = useSelector(compBackgroundSelector);
  const spaceDown = useSelector((state: AppState) => state.app.spaceDown);
  const dispatch = useDispatch();

  const [mouse, setMouse] = useState<{ x: number; y: number }>({ x: 0, y: 0 });
  const [isMouseDown, setMouseDown] = useState(false);

  /**
   * Space down detector
   */
  useWindowEvent('keydown', (event: KeyboardEvent) => {
    if (event.code === 'Space' && spaceDown === false) {
      dispatch(toggleSpaceDownAC(true));
    }
  });

  useWindowEvent('keyup', (event: KeyboardEvent) => {
    if (event.code === 'Space' && spaceDown === true) {
      dispatch(toggleSpaceDownAC(false));
    }
  });

  const handleOnWheel: WheelEventHandler<HTMLDivElement> = (event) => {
    if (event.deltaY < 0) onZoom(zoom + 0.1);
    else onZoom(zoom - 0.1);
  };

  const handleMouseDown: MouseEventHandler<HTMLDivElement> = (e) => {
    if (spaceDown) {
      setMouse({ x: e.screenX, y: e.screenY });
      setMouseDown(true);
    }
  };

  const handleMouseUp: MouseEventHandler<HTMLDivElement> = (e) => {
    if (isMouseDown) setMouseDown(false);
  };

  const handleMouseMove: MouseEventHandler<HTMLDivElement> = (e) => {
    if (!isMouseDown || !spaceDown) return;

    // Calculate delta
    const deltaX = e.screenX - mouse.x;
    const deltaY = e.screenY - mouse.y;

    // Set new render position
    onSetRenderPosition(renderPosition.x + deltaX, renderPosition.y + deltaY);

    // Set new mouse position
    setMouse({ x: e.screenX, y: e.screenY });
  };

  return (
    <div
      className={classNames({ 'render-section': true, 'space-down': spaceDown, moving: false })}
      ref={renderSectionRef}
      onMouseUp={handleMouseUp}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onWheel={handleOnWheel}
    >
      <div
        id="render"
        ref={renderRef}
        className="render"
        style={{
          top: renderPosition.y,
          left: renderPosition.x,
          transform: `scale(${zoom})`,
          width: compositionWidth,
          height: compositionHeight,
          position: 'relative',
          background: compositionBackground,
        }}
      >
        <RenderLayers layers={compLayers} zoom={zoom} renderSectionRef={renderSectionRef} nested={false} globalFrameOffset={0} />
      </div>
    </div>
  );
};
