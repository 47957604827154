type PropsType = {
  label: string;
  children: React.ReactNode;
};

export const PropertiesSection = (props: PropsType) => {
  return (
    <div className="properties-section">
      <div className="properties-section__label">{props.label}</div>
      <div className="properties-section__content">{props.children}</div>
    </div>
  );
};
