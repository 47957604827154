import { Layer } from '../../../models/composition';
import { Svg } from '../../../models/resources';
import { ColorsService } from '../../../services/ColorsService';
import { getActiveComposition } from '../../helpers';
import { addLayerAC } from '../../reducers/projectReducer';
import { addSvgAC } from '../../reducers/projectReducer';
import { projectSelector } from '../../selectors/projectSelector';
import { MyThunkActionReturn } from '../thunkTypes';

export const newSvgLayerThunk = (): MyThunkActionReturn => {
  return async (dispatch, getState) => {
    const state = getState();
    const project = projectSelector(state);
    const composition = getActiveComposition(project);

    const pickerOpts = {
      types: [
        {
          description: 'SVG',
          accept: {
            'image/svg+xml': '.svg',
          },
        },
      ],
      excludeAcceptAllOption: true,
      multiple: true,
    };
    const fileHandles = await (window as any).showOpenFilePicker(pickerOpts);
    const fileHandle = fileHandles[0];

    const singleFile = await fileHandle.getFile();

    const svg = await new Promise<string>((resolve, reject) => {
      const fr = new FileReader();
      fr.onload = async () => {
        resolve(fr.result as string);
      };
      fr.readAsText(singleFile);
    });

    const id = project.resources.lastSvgId ? project.resources.lastSvgId + 1 : 1;
    const newSvg: Svg = {
      id,
      name: singleFile.name,
      type: singleFile.type,
      lastModified: singleFile.lastModified,
      size: singleFile.size,
      content: svg,
    };

    dispatch(addSvgAC(newSvg));

    const newLayer: Layer = {
      id: composition.lastLayerId + 1,
      name: 'New svg layer',
      startFrame: 0,
      duration: composition.workspaceDuration,
      color: ColorsService.getElementColorId('IMAGE'),
      visible: true,
      locked: false,
      guide: false,
      element: {
        type: 'SVG',
        dynamicId: '',
        svgId: id,
      },
      properties: {
        width: {
          value: 100,
          keyframes: [],
        },
        height: {
          value: 50,
          keyframes: [],
        },
      },
      openPropertyGroups: [],
      open: false,
    };

    dispatch(addLayerAC(newLayer));
  };
};
