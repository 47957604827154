import classNames from 'classnames';
import { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { LOOPIC_CONFIG } from '../../config/LOOPIC_CONFIG';
import { toggleShowTutorialAC } from '../../redux/reducers/appReducer';

type PropsType = {
  steps: any;
};

export const Tutorial = ({ steps }: PropsType) => {
  const [step, setStep] = useState(0);
  const dispatch = useDispatch();
  const [edgeFinished, setEdgeFinished] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setEdgeFinished(!edgeFinished);
    }, 2000);
  });

  const next = () => {
    if (step + 1 >= steps.length) {
      dispatch(toggleShowTutorialAC(false));
      setStep(0);
      localStorage.setItem(LOOPIC_CONFIG.tutorialFinishedLocalStorageKey, 'true');
    } else {
      setStep(step + 1);
    }
  };

  let data = steps[step].domNode?.getBoundingClientRect();

  if (data) {
    const x = data.x;
    const xw = data.x + data.width;
    const y = data.y;
    const yh = data.y + data.height;

    return (
      <div className="tutorial-wrapper">
        <div
          className={classNames({ 'tutorial-shade': true })}
          style={{
            clipPath: `polygon(
              0px 0px, 0px 100%, ${x}px 100%,
              ${x}px ${y}px, ${xw}px ${y}px, ${xw}px ${yh}px, ${x}px ${yh}px, 
              ${x}px 100%, 100% 100%, 100% 0 
            )`,
          }}
        />
        <div
          className={classNames({ target: true })}
          style={{
            top: data.top,
            left: data.left,
            width: data.width,
            height: data.height,
          }}
        >
          <div className={classNames({ caption: true, [steps[step].position]: true })}>
            <div className="content">{steps[step].text}</div>
            <div className="controls">
              <div className="step">
                {step + 1}/{steps.length}
              </div>
              <button className="next" onClick={next}>
                {step + 1 === steps.length ? 'Finish tutorial' : 'Got it'}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  console.error(`Tutorial step ${step} dom node data not found`);
  return <></>;
};
