import { Composition } from '../../models/composition';
import { setModalAC } from '../reducers/appReducer';
import { addNewCompositionAC, removeCompositionAC, setActiveCompositionAC } from '../reducers/projectReducer';
import { projectSelector } from '../selectors/projectSelector';
import { MyThunkActionReturn } from './thunkTypes';

export const newCompositionThunk = (): MyThunkActionReturn => {
  return (dispatch, getState) => {
    const state = getState();
    const project = projectSelector(state);

    const newComposition: Composition = {
      id: project.lastCompositionId + 1,
      name: 'New composition',
      width: 1920,
      height: 1080,
      fps: 30,
      scale: 20,
      background: '#ffffff',
      workspaceDuration: 30,
      duration: 100,
      activeFrame: 0,
      compositionPanelHeight: 275,
      layerControlsWidth: 275,
      layers: [],
      selectedLayers: [],
      lastLayerId: 0,
      lastKeyframeId: 0,
      actions: [],
      lastActionId: 0,
      compositionAction: '',
    };
    dispatch(addNewCompositionAC(newComposition));
    dispatch(changeCompositionThunk(project.lastCompositionId + 1));
  };
};

export const duplicateCompositionThunk = (compositionId: number): MyThunkActionReturn => {
  return (dispatch, getState) => {
    const state = getState();
    const project = projectSelector(state);
    const found = project.compositions.find((prComp) => prComp.id === compositionId);

    const newComposition = JSON.parse(JSON.stringify(found)) as Composition;
    newComposition.id = project.lastCompositionId + 1;
    dispatch(addNewCompositionAC(newComposition));
  };
};

export const changeCompositionThunk = (compositionId: number): MyThunkActionReturn => {
  return (dispatch, getState) => {
    const state = getState();
    const project = projectSelector(state);

    if (project.activeCompositionId === compositionId) return;

    const composition = project.compositions.find((composition) => composition.id === compositionId);
    if (!composition) return;

    dispatch(setActiveCompositionAC(composition.id));
  };
};

export const removeCompositionThunk = (compositionId: number): MyThunkActionReturn => {
  return (dispatch, getState) => {
    const state = getState();
    const project = projectSelector(state);
    const composition = project.compositions.find((comp) => comp.id === compositionId);
    if (!composition) return;

    const deleteControl = {
      label: 'Delete',
      onClick: () => {
        if (project.activeCompositionId === compositionId) {
          // Deleted composition is currently active
          if (project.compositions.length > 1) {
            // If there are more compositions - set any other as active
            for (const prComp of project.compositions) {
              if (prComp.id !== compositionId) {
                dispatch(setActiveCompositionAC(prComp.id));
                break;
              }
            }
          } else {
            // This is the only composition
            console.error('TO DO THIS!');
            // dispatch(unsetCompositionAC());
          }
        }
        dispatch(removeCompositionAC(compositionId));
        dispatch(setModalAC(undefined));
      },
    };
    const cancelControl = {
      label: 'Cancel',
      autoFocus: true,
      onClick: () => dispatch(setModalAC(undefined)),
    };

    dispatch(
      setModalAC({
        title: 'Delete confirmation',
        content: `Are you sure to delete ${composition.name}?`,
        icon: 'warning',
        controls: [cancelControl, deleteControl],
      }),
    );
  };
};
