import { Dispatch } from 'react';
import { Project } from '../models/project';
import { setLastSavedAC, toggleTouchedAC } from '../redux/reducers/appReducer';
import { setProjectNameAC } from '../redux/reducers/projectReducer';
import { PropertyId } from './PropertySettingsService';

export class ExportService {
  static projectFileHandle: any = null;
  static options = {
    types: [
      {
        description: 'Loopic Project File',
        accept: {
          'text/json': ['.loo'],
        },
      },
    ],
  };

  static async handleSaveAsProject(dispatch: Dispatch<any>, project: Project) {
    try {
      const newProjectFileHandle = await (window as any).showSaveFilePicker(this.options);
      this.projectFileHandle = newProjectFileHandle;
      this.handleSaveProject(dispatch, project);
    } catch (error) {
      console.error('User probably aborted saving');
      return;
    }
  }

  static async handleSaveProject(dispatch: Dispatch<any>, project: Project) {
    if (!this.projectFileHandle) {
      try {
        this.projectFileHandle = await (window as any).showSaveFilePicker(this.options);
      } catch (error) {
        console.error('User probably aborted saving');
        return;
      }
    }

    const fileName = this.projectFileHandle.name;
    dispatch(setProjectNameAC(fileName));

    try {
      await ExportService.writeFile(this.projectFileHandle, ExportService.prepareProjectForExport(project));
      dispatch(setLastSavedAC(new Date().getTime()));
      dispatch(toggleTouchedAC(false));
    } catch (error) {
      console.error('No permissions to save file');
    }
  }

  /**
   * Method clears calculated keyframes for all layers.
   * @param project Project
   * @returns Project without calculated keyframes
   */
  static prepareProjectForExport(project: Project) {
    const nonReadOnlyProject = JSON.parse(JSON.stringify(project)) as Project;
    nonReadOnlyProject.compositions = nonReadOnlyProject.compositions.map((composition) => {
      composition.layers.map((layer) => {
        for (const key in layer.properties) {
          const propertyId = key as PropertyId;
          const property = layer.properties[propertyId];
          if (property) {
            if (property.calculatedKeyframes) property.calculatedKeyframes = undefined;
          }
        }
        return layer;
      });
      return composition;
    });

    return JSON.stringify(nonReadOnlyProject);
  }

  /**
   * Method executes writing content to file handle
   * @param fileHandle File handle
   * @param contents Content to write
   */
  static async writeFile(fileHandle: any, contents: any) {
    const writable = await fileHandle.createWritable();
    await writable.write(contents);
    await writable.close();
  }
}
