import { Item, ItemParams, Menu } from 'react-contexify';
import 'react-contexify/dist/ReactContexify.css';
import { useDispatch } from 'react-redux';
import { VideoResourceThunks } from '../../redux/thunks/resources/VideoResourceThunks';

export const VIDEO_CONTEXT_MENU_ID = 'VIDEO-CONTEXT-MENU-ID';

export const VideoContextMenu = () => {
  const dispatch = useDispatch();

  const handleDelete = ({ props }: ItemParams<{ id: number }>) => {
    if (props && props.id) dispatch(VideoResourceThunks.remove(props.id));
  };

  const importAsLayer = ({ props }: ItemParams<{ id: number }>) => {
    if (props && props.id) dispatch(VideoResourceThunks.toLayer(props.id));
  };

  return (
    <Menu id={VIDEO_CONTEXT_MENU_ID}>
      <Item onClick={importAsLayer}>Import as layer</Item>
      <Item onClick={handleDelete}>Delete video</Item>
    </Menu>
  );
};
