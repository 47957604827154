import reduxStore from '../redux/store';
import jsonwebtoken from 'jsonwebtoken';
import { UsersApi } from './api/UsersApi';
import { removeUserAC, setUserAC } from '../redux/reducers/userReducer';
import { UserType } from '../models/user';

export class AuthService {
  static getAuthHeader = () => ({
    headers: {
      Authorization: 'Bearer ' + AuthService.getAccessToken(),
    },
  });

  static isLoggedIn(user: UserType) {
    return AuthService.isValidToken() && user !== null && Object.keys(user).length !== 0;
  }

  static isValidToken() {
    const access_token = localStorage.getItem('access_token');
    return !!access_token;
  }

  static getAccessToken() {
    return localStorage.getItem('access_token');
  }

  static login(access_token: string, user: UserType) {
    localStorage.setItem('access_token', access_token);
    reduxStore.dispatch(setUserAC(user));
  }

  static async autoLogin() {
    const access_token = this.getAccessToken();
    if (access_token) {
      const decoded = jsonwebtoken.decode(access_token);
      const userId = decoded?.sub;

      try {
        const { data } = await UsersApi.findOne(userId);
        this.login(access_token, data);
        return true;
      } catch (error) {
        this.logout();
        return false;
      }
    }
  }

  static logout() {
    localStorage.removeItem('access_token');
    reduxStore.dispatch(removeUserAC());
  }
}
