import { Layer } from '../../../models/composition';
import { ColorsService } from '../../../services/ColorsService';
import { getActiveComposition } from '../../helpers';
import { addLayerAC } from '../../reducers/projectReducer';
import { projectSelector } from '../../selectors/projectSelector';
import { MyThunkActionReturn } from '../thunkTypes';

export const newImageLoaderThunk = (): MyThunkActionReturn => {
  return async (dispatch, getState) => {
    const state = getState();
    const composition = getActiveComposition(projectSelector(state));

    const newLayer: Layer = {
      id: composition.lastLayerId + 1,
      name: 'New image loader',
      startFrame: 0,
      duration: composition.workspaceDuration,
      color: ColorsService.getElementColorId('IMAGE_LOADER'),
      open: false,
      visible: true,
      locked: false,
      guide: false,
      element: {
        type: 'IMAGE_LOADER',
        size: 'contain',
        dynamicId: '',
        placeholderId: 0,
      },
      properties: {},
      openPropertyGroups: [],
    };
    dispatch(addLayerAC(newLayer));
  };
};
