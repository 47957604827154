import { useDispatch, useSelector } from 'react-redux';
import { addActionAC } from '../../redux/reducers/projectReducer';
import { compSelector } from '../../redux/selectors/compositionSelector';
import { ActionBtn } from '../common/inputs/ActionBtn';

export const ActionControls = () => {
  const composition = useSelector(compSelector);
  const dispatch = useDispatch();

  return (
    <div className="actions-controls">
      <ActionBtn
        label="STOP &amp; OUTRO"
        onClick={() => dispatch(addActionAC({ frame: composition.activeFrame, code: `stopAnimation();`, outro: true }))}
      />
      <ActionBtn label="CODE" onClick={() => dispatch(addActionAC({ frame: composition.activeFrame, code: ``, outro: false }))} />
    </div>
  );
};
