import { Popup } from '../common/popup/Popup';
import plus from '../../img/icons/empty-project.svg';
import open from '../../img/icons/open.svg';
import logoSymbol from '../../img/logos/logo-symbol.png';
import blt from '../../data/basic-lower-third/result.gif';
import sportIntroResult from '../../data/sport-intro/result.gif';
import { useDispatch } from 'react-redux';
import { carefullyLoadProjectThunk } from '../../redux/thunks/projectThunk';
import { BasicLowerThirdDemo } from '../../data/basic-lower-third/BasicLowerThirdDemo';
import { setModalAC, setPopupAC, toggleShowTutorialAC } from '../../redux/reducers/appReducer';
import { NewProjectDemo } from '../../data/NewProjectDemo';
import { ImportService } from '../../services/ImportService';
import { LOOPIC_CONFIG } from '../../config/LOOPIC_CONFIG';
import { SportIntroDemo } from '../../data/sport-intro/SportIntroDemo';

export const WelcomePopup = () => {
  const dispatch = useDispatch();

  const emptyProject = () => {
    dispatch(setPopupAC({ popupId: 'welcome', open: false }));
    dispatch(carefullyLoadProjectThunk(NewProjectDemo));
    playTutorial();
  };

  const playTutorial = () => {
    const value = localStorage.getItem(LOOPIC_CONFIG.tutorialFinishedLocalStorageKey);
    if (!value) {
      dispatch(
        setModalAC({
          title: 'Tutorial',
          icon: 'info',
          content: 'Would you like to take 30-seconds interactive tutorial?',
          controls: [
            {
              label: 'Yes',
              onClick: () => {
                dispatch(setModalAC(undefined));
                dispatch(toggleShowTutorialAC(true));
              },
            },
            {
              label: 'No',
              onClick: () => {
                localStorage.setItem(LOOPIC_CONFIG.tutorialFinishedLocalStorageKey, 'true');
                dispatch(setModalAC(undefined));
              },
            },
          ],
        }),
      );
    }
  };

  const openProject = () => {
    ImportService.handleImportProject(dispatch);
    dispatch(setPopupAC({ popupId: 'welcome', open: false }));
  };

  const basicLowerThird = () => {
    dispatch(setPopupAC({ popupId: 'welcome', open: false }));
    dispatch(carefullyLoadProjectThunk(BasicLowerThirdDemo));
    playTutorial();
  };

  const sportIntro = () => {
    dispatch(setPopupAC({ popupId: 'welcome', open: false }));
    dispatch(carefullyLoadProjectThunk(SportIntroDemo));
    playTutorial();
  };

  return (
    <Popup id="welcome" title="Welcome to Loopic!" disableClose>
      <div className="start-projects">
        <div className="default-options">
          <button className="empty" onClick={emptyProject}>
            <div className="img-wrapper">
              <img src={plus} alt="Plus" />
            </div>
            <div className="label">Empty project</div>
          </button>
          <button className="open" onClick={openProject}>
            <div className="img-wrapper">
              <img src={open} alt="Open" />
            </div>
            <div className="label">Open project</div>
          </button>
        </div>
        <div className="demo-projects">
          <div className="label">Demo projects</div>
          <div className="content">
            <button onClick={basicLowerThird}>
              <div className="img-wrapper">
                <img src={blt} alt="Basic Lower Third" />
              </div>
              <div className="label">Basic Lower Third</div>
            </button>
            <button onClick={sportIntro}>
              <div className="img-wrapper">
                <img src={sportIntroResult} alt="Sport intro" />
              </div>
              <div className="label">Sport intro</div>
            </button>
          </div>
        </div>
      </div>
      <div className="welcome-text">
        <div className="meta">
          <div className="title">Quick start</div>
          <ul className="links">
            <li>
              <a href="https://www.youtube.com/watch?v=UuPFpTNN1sk" target="_blank">
                YouTube Tutorial: Sport intro
              </a>
            </li>
            <li>
              <a href="https://www.youtube.com/watch?v=ZPIzVMnBBRU" target="_blank">
                YouTube Tutorial: Basic lower third
              </a>
            </li>
            <li>
              <a href="https://www.loopic.io/docs" target="_blank">
                Documentation
              </a>
            </li>
          </ul>
        </div>
        <div className="logo">
          <img src={logoSymbol} alt="Loopic logo" />
        </div>
      </div>
    </Popup>
  );
};
