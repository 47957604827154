import { ButtonHTMLAttributes, DetailedHTMLProps } from 'react';
import plusIcon from '../../../img/icons/plus.svg';

export const BtnPlus = ({ className, ...rest }: DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>) => {
  return (
    <button className={`btn plus ${className}`} {...rest}>
      <img className="icon" src={plusIcon} alt="Plus" />
    </button>
  );
};
