import classNames from 'classnames';
import { Field, Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { AppState } from '../../redux/AppState';
import { PropertyHelper } from '../../redux/helpers/PropertyHelper';
import { setElementDynamicIdAC, setTextElementContentAC } from '../../redux/reducers/projectReducer';
import {
  getAllSelectedLayersSelector,
  getOneSelectedLayerSelector,
  hasManySelectedLayersSelector,
} from '../../redux/selectors/layersSelector';
import { setPropertyValueThunk } from '../../redux/thunks/layersThunk';
import { PropertyId } from '../../services/PropertySettingsService';
import { InputFormikField } from '../common/inputs/InputFormikField';
import { PanelLayout } from '../common/panel-layout/PanelLayout';
import { CompositionSettingsPanel } from './CompositionSettingsPanel';
import { ImageLoaderPropertiesSection } from './ImageLoaderPropertiesSection';
import { InputWrapper } from '../common/inputs/InputWrapper';

import { SizeAndPositionPropertiesSection } from './SizeAndPositionPropertiesSection';
import { TextPropertiesSection } from './TextPropertiesSection';
import { ChangeEvent } from 'react';
import { ShapePropertiesSection } from './ShapePropertiesSection';
import { compSelector } from '../../redux/selectors/compositionSelector';

export const SettingsPanel = () => {
  const composition = useSelector(compSelector);
  const selectedLayer = useSelector(getOneSelectedLayerSelector);
  const selectedLayers = useSelector(getAllSelectedLayersSelector);
  const manySelected = useSelector(hasManySelectedLayersSelector);
  const dispatch = useDispatch();

  if (!selectedLayer) {
    return <CompositionSettingsPanel />;
  }

  const searchForFrame = composition.activeFrame - selectedLayer.startFrame;

  const handleSetPropertyValue = (propertyId: PropertyId, value: string | number) => {
    dispatch(setPropertyValueThunk(selectedLayer.id, [{ propertyId, value }]));
  };

  /**
   * Method defines how getting property values in the entire settings panel is defined.
   * If many selected and with different values, will return undefined.
   * @param propertyId Property ID
   * @returns value
   */
  const handleGetPropertyValue = (propertyId: PropertyId) => {
    if (manySelected) {
      let lastVal: any = null;
      for (const layer of selectedLayers) {
        const newVal = PropertyHelper.getPropertyValueForHuman(layer.properties, propertyId, searchForFrame);
        if (lastVal !== null && lastVal !== newVal) return '';
        lastVal = newVal;
      }
      return lastVal;
      // Check values of all selected layers
    } else {
      const val = PropertyHelper.getPropertyValueForHuman(selectedLayer.properties, propertyId, searchForFrame);
      return val;
    }
  };

  const validateDynamicId = ({ dynamicId }: { dynamicId: string }) => {
    let found = false;
    composition.layers.forEach((layer) => {
      if (layer.element.type === 'TEXT') {
        if (layer.element.dynamicId === dynamicId && layer.id !== selectedLayer.id) {
          console.log('Duplication found', layer.element.dynamicId, dynamicId);
          found = true;
        }
      }
    });

    let errors: any = {};
    if (found) {
      errors.dynamicId = 'Dynamic ID already exists!';
    }
    return errors;
  };

  return (
    <PanelLayout
      id="layer-settings"
      className="layer-settings"
      title="Layer settings"
      tooltip={`Layer: ${selectedLayer.name}<br />ID: ${selectedLayer.id}<br />Type: ${selectedLayer.element.type}`}
    >
      <Formik
        initialValues={{
          dynamicId: selectedLayer.element.dynamicId,
        }}
        validate={validateDynamicId}
        enableReinitialize={true}
        onSubmit={(values) => {
          console.log(selectedLayer.id);
          dispatch(setElementDynamicIdAC({ layerId: selectedLayer.id, dynamicId: values.dynamicId! }));
        }}
      >
        {({ errors, handleSubmit, handleChange, submitForm }) => (
          <Form>
            <InputWrapper label="Dynamic ID" labelPosition="left" name="dynamicId" error={errors.dynamicId}>
              <Field
                type="text"
                name="dynamicId"
                autoComplete="off"
                className={classNames({ classic: true, error: errors.dynamicId })}
                onChange={(e: ChangeEvent) => {
                  handleChange(e);
                  setTimeout(() => {
                    submitForm();
                  }, 0);
                }}
                onBlur={() => submitForm()}
                onKeyDown={(e: any) => {
                  if (e.key === 'Enter') {
                    (document as any).activeElement.blur();
                    submitForm();
                  }
                }}
              />
            </InputWrapper>
          </Form>
        )}
      </Formik>
      {selectedLayer.element.type === 'TEXT' && (
        <Formik
          initialValues={{
            content: selectedLayer.element.content,
          }}
          validationSchema={Yup.object().shape({
            fontSize: Yup.string().min(1),
          })}
          enableReinitialize={true}
          onSubmit={(values) => {
            dispatch(setTextElementContentAC({ layerId: selectedLayer.id, content: values.content }));
          }}
        >
          <Form>
            <InputFormikField name="content" type="text" label="Content" />
          </Form>
        </Formik>
      )}

      <SizeAndPositionPropertiesSection onGetPropertyValue={handleGetPropertyValue} onSetPropertyValue={handleSetPropertyValue} />

      {selectedLayer.element.type === 'TEXT' && (
        <TextPropertiesSection
          layerId={selectedLayer.id}
          disableAutoShrink={selectedLayer.element.disableAutoShrink}
          onGetPropertyValue={handleGetPropertyValue}
          onSetPropertyValue={handleSetPropertyValue}
        />
      )}

      {selectedLayer.element.type === 'IMAGE_LOADER' && (
        <ImageLoaderPropertiesSection
          size={selectedLayer.element.size}
          placeholderId={selectedLayer.element.placeholderId}
          onGetPropertyValue={handleGetPropertyValue}
          onSetPropertyValue={handleSetPropertyValue}
        />
      )}

      {selectedLayer.element.type === 'SHAPE' && (
        <ShapePropertiesSection
          layerId={selectedLayer.id}
          onGetPropertyValue={handleGetPropertyValue}
          onSetPropertyValue={handleSetPropertyValue}
        />
      )}
    </PanelLayout>
  );
};
