import { Popup } from '../common/popup/Popup';
import { TabsLayout } from '../common/layouts/tabs-layout/TabsLayout';
import { PasswordForm } from '../forms/PasswordForm';
import { UserForm } from '../forms/UserForm';
import { DeleteAccount } from '../forms/DeleteAccount';

export const AccountPopup = () => {
  return (
    <Popup id="account" title="Account settings" noContentPadding width={500}>
      <TabsLayout
        minHeight={300}
        tabs={[
          {
            id: 'general-info',
            label: 'General info',
            content: <UserForm />,
          },
          {
            id: 'password',
            label: 'Password',
            content: <PasswordForm />,
          },
          {
            id: 'advance',
            label: 'Advance',
            content: <DeleteAccount />,
          },
        ]}
      />
    </Popup>
  );
};
