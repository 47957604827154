import { Properties } from '../../models/composition';
import { ParserService } from '../../services/ParserService';
import { allProperties, PropertyId } from '../../services/PropertySettingsService';

export class PropertyHelper {
  static getPropertyValue = (properties: Properties, propertyId: PropertyId, frameNumber: number) => {
    const property = properties[propertyId];
    if (!property) return;

    if (!property.calculatedKeyframes) return undefined;
    if (!property.calculatedKeyframes[frameNumber]) return undefined;

    const propValue = property.calculatedKeyframes[frameNumber].value;

    return propValue;
  };

  static getPropertyValueForHuman = (properties: Properties, propertyId: PropertyId, frameNumber: number) => {
    const propValue = PropertyHelper.getPropertyValue(properties, propertyId, frameNumber);
    const propertySettings = allProperties[propertyId];

    /**
     * Check if needs to scale for human-readability
     */
    const sf = (propertySettings as any).scaleFactor;
    if (sf !== undefined && typeof propValue === 'number') {
      const scaledValue = propValue * sf;

      // Find out if need to round decimals
      const sfDecimals = PropertyHelper.getNumberOfDecimals(sf);
      const decimals = (propertySettings as any).decimals;
      if (decimals) {
        const diff = decimals - sfDecimals;
        return +scaledValue.toFixed(diff);
      }

      // Don't need to round decimals
      return +scaledValue.toFixed(0);
    }

    // No need to scale
    return propValue;
  };

  static normalizePropertyValue = (propertyId: PropertyId, value: string | number) => {
    const propertySettings = allProperties[propertyId];

    const nativeValue = ParserService.convertToNativeType(value);

    /**
     * Check if need to de-scale for program
     */
    const sf = (propertySettings as any).scaleFactor;
    if (sf !== undefined && typeof nativeValue === 'number') {
      return nativeValue / sf;
    }

    return nativeValue;
  };

  static getNumberOfDecimals(input: number) {
    let i = 0;
    let input2 = input;
    do {
      ++i;
      input2 = input2 / 10;
    } while (input2 > 1);

    return i;
  }
}
