import { Helmet } from 'react-helmet';
import { PopupPageLayout } from '../components/common/layouts/popup-page-layout/PopupPageLayout';
import { RegisterPopup } from '../components/popups/RegisterPopup';
import { LOOPIC_CONFIG } from '../config/LOOPIC_CONFIG';
import { SeoService } from '../services/SeoService';

export const RegisterPage = () => (
  <PopupPageLayout>
    <Helmet>
      <title>{SeoService.getPageTitle('Register')}</title>
    </Helmet>
    <RegisterPopup disableClose={!LOOPIC_CONFIG.allowAccessWithoutRegistration} />
  </PopupPageLayout>
);
