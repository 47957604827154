import { useRef, useState } from 'react';
import Draggable from 'react-draggable';

type PropsType = {
  x: number;
  y: number;
  axis: 'x' | 'y' | 'both' | 'none';
  onDrag?: (deltaX: number, deltaY: number) => void;
  scale: number;
  style?: any;
};

export const ElementControlHandle = ({ x, y, axis, onDrag, scale, style }: PropsType) => {
  const [startPos, setStartPos] = useState<{ x: number; y: number }>({ x: 0, y: 0 });
  const nodeRef = useRef(null);

  return (
    <Draggable
      position={{ x, y }}
      axis={axis}
      // This makes it pixel-almost-perfect when zoomed id
      grid={[1 * scale, 1 * scale]}
      onStart={(e, data) => {
        setStartPos({ x: data.x, y: data.y });
      }}
      onDrag={(e, data) => {
        const deltaX = data.x - startPos.x;
        const deltaY = data.y - startPos.y;
        if (onDrag) onDrag(Math.round(deltaX / scale), Math.round(deltaY / scale));
        setStartPos({ x: data.x, y: data.y });
      }}
      nodeRef={nodeRef}
    >
      <div ref={nodeRef} className="handle" style={style}></div>
    </Draggable>
  );
};
