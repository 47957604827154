import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { EasingPresetName, EasingService } from '../../../../services/EasingService';

type PropsType = {
  left: number;
  framesDistance: number;
  scale: number;
  preset: string;
  p1x: number;
  p1y: number;
  p2x: number;
  p2y: number;
  onItemClick: (presetName: string, p1x: number, p1y: number, p2x: number, p2y: number) => any;
};

export const EasingMenu = (props: PropsType) => {
  const [isOpen, setOpen] = useState(false);
  const menuRef = useRef<HTMLUListElement>(null);

  const handleMenuClick = () => {
    if (isOpen) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  };

  const handleItemClick = (presetName: EasingPresetName) => {
    const found = EasingService.findByPresetName(presetName);
    if (!found) return;

    props.onItemClick(found.presetName, found.p1x, found.p1y, found.p2x, found.p2y);
  };

  let preset = props.preset;

  if (props.framesDistance <= 1) return <></>;

  // const CUSTOM: EasingPreset[] = [{ presetName: 'CUSTOM', p1x: 0, p1y: 0, p2x: 0, p2y: 0 }];

  return (
    <div className="easing-menu-wrapper" style={{ left: props.left, zIndex: isOpen ? 110 : undefined }}>
      <ul
        className={classNames({ 'easing-menu': true, isOpen })}
        onClick={handleMenuClick}
        tabIndex={0}
        onBlur={() => setOpen(false)}
        ref={menuRef}
        style={{ maxWidth: props.framesDistance * props.scale - props.scale }}
      >
        {EasingService.EASINGS.map((easing) => {
          const selected = easing.presetName === preset;
          const custom = (easing.presetName as string) === 'CUSTOM';
          return (
            <li key={easing.presetName} className={classNames({ selected, custom })} onClick={() => handleItemClick(easing.presetName)}>
              {easing.presetName}
            </li>
          );
        })}
      </ul>
    </div>
  );
};
