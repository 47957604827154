import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveFrameAC } from '../../redux/reducers/projectReducer';
import { startAnimation, stopAnimation } from '../../services/animation';

import { togglePlayModeAC } from '../../redux/reducers/appReducer';
import { compSelector } from '../../redux/selectors/compositionSelector';
import { checkIfFocusOnInput } from '../../services/focusHelpers';

export const PlaybackControls = () => {
  const composition = useSelector(compSelector);
  const dispatch = useDispatch();

  const [isKDown, setKDown] = useState(false);
  const [isPlay, setPlay] = useState(false);
  const [isLoop, setLoop] = useState(true);
  const [isStartEndLoop, setStartEndLoop] = useState(false);

  const stateRef = useRef<number>(0);
  stateRef.current = composition.activeFrame;

  useEffect(() => {
    const upCb = (event: KeyboardEvent) => {
      switch (event.code) {
        case 'KeyK':
          setKDown(false);
          break;
        default:
          break;
      }
    };

    const downCb = (event: KeyboardEvent) => {
      if (checkIfFocusOnInput()) return;

      switch (event.code) {
        case 'KeyL':
          if (isKDown) handleNext();
          else handlePlay();
          break;
        case 'KeyK':
          setKDown(true);
          handleStop();
          break;
        case 'KeyJ':
          if (isKDown) handlePrev();
          else handlePlayPrev();
          break;
        default:
          break;
      }
    };

    document.addEventListener('keyup', upCb);
    document.addEventListener('keydown', downCb);
    return () => {
      document.removeEventListener('keyup', upCb);
      document.removeEventListener('keydown', downCb);
    };
  });

  useEffect(() => {
    stateRef.current = composition.activeFrame;
  }, [composition.activeFrame]);

  const handlePlay = () => {
    setPlay(true);
    dispatch(togglePlayModeAC({ playMode: true }));
    startAnimation(composition.fps, () => {
      if (stateRef.current < composition.workspaceDuration - 1) {
        handleNext();
      } else {
        // * On last frame - check what to do
        if (isStartEndLoop) {
          handlePlayPrev();
        } else if (isLoop) {
          dispatch(setActiveFrameAC({ activeFrame: 0 }));
        } else {
          handleStop();
        }
      }
    });
  };

  const handlePlayPrev = () => {
    setPlay(true);
    dispatch(togglePlayModeAC({ playMode: true }));
    startAnimation(composition.fps, () => {
      handlePrev();
    });
  };

  const handleStop = () => {
    setPlay(false);
    stopAnimation();
    dispatch(togglePlayModeAC({ playMode: false }));
  };

  const handleNext = () => {
    if (stateRef.current < composition.workspaceDuration - 1) {
      dispatch(setActiveFrameAC({ activeFrame: stateRef.current + 1 }));
    } else {
      dispatch(setActiveFrameAC({ activeFrame: 0 }));
    }
  };

  const handlePrev = () => {
    if (stateRef.current === 0) {
      // * On first frame - check what to do
      if (isStartEndLoop) {
        handlePlay();
      } else if (isLoop) {
        dispatch(setActiveFrameAC({ activeFrame: composition.workspaceDuration - 1 }));
      } else {
        handleStop();
      }
    } else {
      dispatch(setActiveFrameAC({ activeFrame: stateRef.current - 1 }));
    }
  };

  const handleStartEndLoop = () => {
    handleStop();
    if (!isStartEndLoop) {
      setStartEndLoop(true);
      setLoop(false);
    } else {
      setStartEndLoop(false);
    }
  };

  const handleLoop = () => {
    handleStop();
    if (!isLoop) {
      setLoop(true);
      setStartEndLoop(false);
    } else {
      setLoop(false);
    }
  };

  return (
    <div className="playback-controls-wrapper">
      <div className="playback-controls">
        <button className="btn playback-control prev" onClick={handlePrev}></button>
        <button
          className={classNames({ btn: true, 'playback-control': true, play: !isPlay, stop: isPlay })}
          onClick={() => {
            if (isPlay) handleStop();
            else handlePlay();
          }}
        ></button>
        <button className="btn playback-control next" onClick={handleNext}></button>
        <div className="separator" />
        <button
          className={classNames({ btn: true, 'playback-control': true, 'loop-start-end': true, active: isStartEndLoop })}
          onClick={handleStartEndLoop}
        />
        <button className={classNames({ btn: true, 'playback-control': true, loop: true, active: isLoop })} onClick={handleLoop} />
      </div>
    </div>
  );
};
