import { PropertiesSection } from '../common/properties/PropertiesSection';

import { PropertyId } from '../../services/PropertySettingsService';
import { InputColor } from '../common/inputs/InputColor';

import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../redux/AppState';
import { FontService } from '../../services/FontService';
import { projectSelector } from '../../redux/selectors/projectSelector';

type PropsType = {
  layerId: number;
  onSetPropertyValue: (propertyId: PropertyId, value: any) => void;
  onGetPropertyValue: (propertyId: PropertyId) => string | number | undefined;
};

export const ShapePropertiesSection = (props: PropsType) => {
  const projectFonts = useSelector(projectSelector).resources.fonts;
  const dispatch = useDispatch();

  /**
   * Get latest fonts
   */
  const allFonts = [...FontService.websafeFonts];
  projectFonts.forEach((font) => {
    allFonts.push({ id: font.name, value: font.name, highlighted: true });
  });

  return (
    <PropertiesSection label="Shape properties">
      <div className="properties-row">
        <InputColor
          label="Background color"
          name="backgroundColor"
          value={props.onGetPropertyValue('backgroundColor')}
          onChange={(color) => props.onSetPropertyValue('backgroundColor', color)}
        />
      </div>
    </PropertiesSection>
  );
};
