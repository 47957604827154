export const HTMLBuilder = (arg: { title: string; css: string; body: string; headJS: string; bodyJS: string }) => {
  return `
    <!DOCTYPE html>
    <html lang="en">
      <head>
        <meta charset="UTF-8">
        <meta http-equiv="X-UA-Compatible" content="IE=edge">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>${arg.title}</title>

        <style>
        ${arg.css}
        </style>

        <script>
        ${arg.headJS}
        </script>

      </head>
      <body>
        ${arg.body}
        
        <script>
        ${arg.bodyJS}
        getFrame(0);
        </script>

      </body>
    </html>
    `;
};
