export type EasingPreset = {
  presetName: EasingPresetName;
  p1x: number;
  p1y: number;
  p2x: number;
  p2y: number;
};

export type EasingPresetName =
  | 'ease'
  | 'linear'
  | 'ease-in'
  | 'ease-out'
  | 'ease-in-out'
  | 'ease-in-back'
  | 'ease-out-back'
  | 'ease-in-out-back';

export class EasingService {
  static EASINGS: EasingPreset[] = [
    { presetName: 'ease', p1x: 0.25, p1y: 0.1, p2x: 0.25, p2y: 1 },
    { presetName: 'linear', p1x: 0, p1y: 0, p2x: 1, p2y: 1 },
    { presetName: 'ease-in', p1x: 0.42, p1y: 0, p2x: 1, p2y: 1 },
    { presetName: 'ease-out', p1x: 0, p1y: 0, p2x: 0.58, p2y: 1 },
    { presetName: 'ease-in-out', p1x: 0.42, p1y: 0, p2x: 0.58, p2y: 1 },
    { presetName: 'ease-in-back', p1x: 0.6, p1y: -0.28, p2x: 0.735, p2y: 0.045 },
    { presetName: 'ease-out-back', p1x: 0.175, p1y: 0.885, p2x: 0.32, p2y: 1.275 },
    { presetName: 'ease-in-out-back', p1x: 0.68, p1y: -0.55, p2x: 0.265, p2y: 1.55 },
  ];

  static findByPresetName(name: EasingPresetName) {
    return EasingService.EASINGS.find((easing) => easing.presetName === name)!;
  }
}
