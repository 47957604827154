import { Project } from '../../models/project';
import { coreCSSBuilder } from './css/coreCSSBuilder';
import { fontsJSBuilder } from './js/fontsJSBuilder';
import { frameCSSBuilder } from './css/frameCSSBuilder';
import { coreJSBuilder } from './js/coreJSBuilder';
import { renderDOMBuilder } from './renderDOMBuilder';
import { HTMLBuilder } from './HTMLBuilder';
import { UtilsService } from '../../services/UtilsService';
import { setNotificationAC } from '../../redux/reducers/notificationReducer';
import { setOutputDirectoryPath } from '../../redux/reducers/appReducer';
import { playoutControlsDOMBuilder } from './playoutControlsDOMBuilder';
import { animeJSBuilder } from './js/animeJSBuilder';
import { loopicClassJSBuilder } from './js/loopicClassJSBuilder';
import { casparCGMethodsBuilder } from './js/casparCGMethodsBuilder';
import { utilsJSBuilder } from './js/utilsJSBuilder';
import { casparCGENVBuilder } from './js/casparCGEnvBuilder';

export class HTMLTemplateExporter {
  static async exportComposition(project: Project, compositionId: number) {
    const composition = project.compositions.find((comp) => comp.id === compositionId)!;

    // CSS
    const coreCSS = coreCSSBuilder(composition);
    const framesCSS = frameCSSBuilder(project, composition);
    const completeCSS = coreCSS + framesCSS;

    // DOM
    const renderDOM = renderDOMBuilder(project, composition)!;
    const playoutControlsDOM = playoutControlsDOMBuilder();
    const completeDOM = renderDOM + playoutControlsDOM;

    // Head JS
    const headJS = casparCGENVBuilder() + animeJSBuilder() + loopicClassJSBuilder() + utilsJSBuilder() + casparCGMethodsBuilder();

    // Body JS
    const coreJS = coreJSBuilder(project, composition);
    const fontsJS = fontsJSBuilder(project.resources.fonts);
    const bodyJS = coreJS + ' ' + fontsJS;

    // HTML Page
    const built = HTMLBuilder({ title: composition.name, css: completeCSS, body: completeDOM, headJS: headJS, bodyJS: bodyJS });

    const fileName = composition.name + '.html';

    // CasparCGExporter.download(fileName, built);
    await this.saveLocally(project, fileName, built);
  }

  static async saveLocally(project: Project, filename: string, text: string) {
    let outputDirectoryFH = (window as any).projectOutputDirectoryFileHandle;

    if (!outputDirectoryFH) {
      outputDirectoryFH = await this.setOutputDirectory();
    }

    const fileHandle = await outputDirectoryFH.getFileHandle(filename, { create: true });

    const writable = await fileHandle.createWritable();
    await writable.write(text);
    await writable.close();

    UtilsService.dispatch(setNotificationAC({ message: 'Graphic exported successfully', type: 'success' }));
  }

  static async setOutputDirectory(): Promise<FileSystemDirectoryHandle> {
    let outputDirectoryFH = await window.showDirectoryPicker({ id: 'outputDirectory' })!;
    (window as any).projectOutputDirectoryFileHandle = outputDirectoryFH;
    UtilsService.dispatch(setOutputDirectoryPath(outputDirectoryFH.name));

    return outputDirectoryFH;
  }

  static download(filename: string, text: string) {
    const element = document.createElement('a');
    element.setAttribute('href', 'data:text/html;charset=utf-8,' + encodeURIComponent(text));
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }
}
