import { MouseEventHandler, useRef } from 'react';
import { useContextMenu } from 'react-contexify';
import Draggable from 'react-draggable';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../redux/AppState';
import { SetPropertyValuePayload } from '../../../redux/reducers/projectReducer';
import { selectLayerThunk, setPropertyValueThunk } from '../../../redux/thunks/layersThunk';
import { PropertyId } from '../../../services/PropertySettingsService';
import { LAYER_CONTEXT_MENU_ID } from '../../context-menu/LayerContextMenu';

type PropsType = {
  zoom?: number;
  x: number;
  y: number;
  layerId: number;
  children: React.ReactNode;
  onDrag?: () => void;
  disabled?: boolean;
};

/**
 * Wrapper arounds any element to make it draggable
 */
export const DragRenderElement = (props: PropsType) => {
  const spaceDown = useSelector((state: AppState) => state.app.spaceDown);
  const dispatch = useDispatch();
  const nodeRef = useRef(null);

  const { show } = useContextMenu({
    id: LAYER_CONTEXT_MENU_ID,
  });

  const handleContextMenu: MouseEventHandler<HTMLDivElement> = (event) => {
    event.preventDefault();
    show(event, {
      props: {
        id: props.layerId,
      },
    });
  };

  return (
    <Draggable
      scale={props.zoom}
      position={{ x: props.x, y: props.y }}
      onDrag={(e, data) => {
        if (props.onDrag) props.onDrag();
      }}
      // This makes it pixel-almost-perfect when zoomed id
      grid={props.zoom ? [1 * props.zoom, 1 * props.zoom] : [1, 1]}
      disabled={spaceDown || props.disabled}
      onMouseDown={(e) => {
        if (!spaceDown) {
          dispatch(selectLayerThunk(props.layerId));
        }
      }}
      onStop={(e, data) => {
        const newX = Math.round(data.lastX);
        const newY = Math.round(data.lastY);

        const pvs: { propertyId: PropertyId; value: string | number }[] = [];

        if (newX !== props.x) {
          pvs.push({ propertyId: 'x', value: newX });
        }
        if (newY !== props.y) {
          pvs.push({ propertyId: 'y', value: newY });
        }
        if (pvs.length > 0) {
          dispatch(setPropertyValueThunk(props.layerId, pvs, false, false, true));
        }
      }}
      nodeRef={nodeRef}
    >
      <div ref={nodeRef} className="draggable-helper" onContextMenu={handleContextMenu}>
        {props.children}
      </div>
    </Draggable>
  );
};
