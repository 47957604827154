import classNames from 'classnames';
import React from 'react';

type PropsType = {
  solidBackground?: boolean;
  children: React.ReactNode;
};

export const PopupPageLayout = (props: PropsType) => (
  <div className={classNames({ 'popup-page-layout': true, solid: props.solidBackground })}>{props.children}</div>
);
