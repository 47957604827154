import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../../redux/AppState';
import { setCompositionWorkspaceDurationAC } from '../../../../redux/reducers/projectReducer';
import { compDurationSelector, compScaleSelector, compWorkspaceDurationSelector } from '../../../../redux/selectors/compositionSelector';
import { DraggableTimeline } from '../../../DraggableTimeline';

export const TfsDuration = () => {
  const compScale = useSelector(compScaleSelector);
  const compWorkspaceDuration = useSelector(compWorkspaceDurationSelector);
  const dispatch = useDispatch();

  return (
    <div className="timeline-frame-selector__duration" style={{ width: compScale * compWorkspaceDuration }}>
      <div className="start-edge" />
      <div className="filler" />
      <DraggableTimeline
        onDrag={(newSelectedFrame: number, dragStartFrame: number) => {
          const diff = newSelectedFrame - dragStartFrame;
          dispatch(setCompositionWorkspaceDurationAC({ workspaceDuration: compWorkspaceDuration + diff }));
        }}
      >
        <div className="end-edge" />
      </DraggableTimeline>
    </div>
  );
};
