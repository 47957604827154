import compositionIcon from '../../img/icons/resources/composition.svg';
import imageIcon from '../../img/icons/resources/image.svg';
import imageSequenceIcon from '../../img/icons/resources/image-sequence.svg';
import fontIcon from '../../img/icons/resources/font.svg';
import videoIcon from '../../img/icons/resources/video.svg';

import { BtnPlus } from '../common/inputs/BtnPlus';
import classNames from 'classnames';
import { useContextMenu } from 'react-contexify';
import { MouseEventHandler, useState } from 'react';
import { Tooltip } from '../common/tooltip/Tooltip';

type PropsType = {
  title: string;
  type: 'compositions' | 'image-sequences' | 'images' | 'fonts' | 'videos';
  controls: { label: string; onClick: () => void; type: 'plus' }[];
  onItemClick?: (id: number) => void;
  onItemDoubleClick?: (id: number) => void;
  content?: { id: number; name: string; active?: boolean; hoverable?: boolean }[];
  contextMenuId?: string;
};

export const ResourceSection = (props: PropsType) => {
  const [isOpen, setOpen] = useState(true);

  const handleToggleClick = () => {
    setOpen(!isOpen);
  };

  const { show } = useContextMenu({
    id: props.contextMenuId,
  });

  const handleContextMenu = (id: number) => {
    const returnFunction: MouseEventHandler<HTMLButtonElement> = (event) => {
      event.preventDefault();
      show(event, { props: { id } });
    };
    return returnFunction;
  };

  let icon: string | undefined;
  if (props.type === 'compositions') icon = compositionIcon;
  if (props.type === 'image-sequences') icon = imageSequenceIcon;
  if (props.type === 'images') icon = imageIcon;
  if (props.type === 'fonts') icon = fontIcon;
  if (props.type === 'videos') icon = videoIcon;

  return (
    <div className="resource-section">
      <div className="resource-section__header" onClick={handleToggleClick}>
        <div className={classNames({ arrow: true, open: isOpen })} />
        <div className="title">{props.title}</div>
        <div className="number-of-items" style={{ opacity: props?.content?.length ? 1 : 0.5 }}>
          {props.content ? props.content.length : 0}
        </div>
        <div className="spacer" />
        {props.controls.map((control, idx) => {
          return (
            <BtnPlus
              key={idx}
              onClick={(e) => {
                e.stopPropagation();
                control.onClick();
              }}
              data-tip={control.label}
            />
          );
        })}
        <Tooltip />
      </div>
      {isOpen && (
        <div className="resource-section__content">
          {props.content?.map((item) => (
            <button
              onContextMenu={handleContextMenu(item.id)}
              key={item.id}
              onClick={() => props.onItemClick && props.onItemClick(item.id)}
              onDoubleClick={() => props.onItemDoubleClick && props.onItemDoubleClick(item.id)}
              className={classNames({
                'resource-item': true,
                clickable: (props.onItemClick || props.onItemDoubleClick) && !item.active,
                active: item.active,
                hoverable: item.hoverable,
              })}
            >
              <img src={icon} alt="Icon" />
              <span>{item.name}</span>
            </button>
          ))}
        </div>
      )}
    </div>
  );
};
