import { Layer } from '../models/composition';
import { ClassicCSSConstructor } from './renderer/ClassicCSSConstructor';
import { FilterCSSConstructor } from './renderer/FilterCSSConstructor';

export class RendererService {
  static getCSSStylesheet(layer: Layer, forExport?: boolean): any[] {
    const calculatedStylesheet: any = [];

    for (let frame = 0; frame < layer.duration; frame++) {
      calculatedStylesheet.push({});
    }

    const classicConstructor = new ClassicCSSConstructor(layer.properties);
    const filterConstructor = new FilterCSSConstructor(layer.properties);

    for (let frame = 0; frame < layer.duration; frame++) {
      filterConstructor.setCalculatedStylesheetSmartValue(frame, calculatedStylesheet, !!forExport);
      classicConstructor.setCalculatedStylesheetSmartValue(frame, calculatedStylesheet, !!forExport);
    }

    return calculatedStylesheet;
  }
}
