import width from '../../img/attributes/width.svg';
import height from '../../img/attributes/height.svg';

import x from '../../img/attributes/x.svg';
import y from '../../img/attributes/y.svg';
import { PropertiesSection } from '../common/properties/PropertiesSection';
import { PropertyNumber } from '../common/inputs/PropertyNumber';
import { PropertyId } from '../../services/PropertySettingsService';
import { CheckboxWithLabel } from '../common/inputs/CheckboxWithLabel';

type PropsType = {
  onSetPropertyValue: (propertyId: PropertyId, value: any) => void;
  onGetPropertyValue: (propertyId: PropertyId) => string | number | undefined;
};

export const SizeAndPositionPropertiesSection = (props: PropsType) => {
  return (
    <PropertiesSection label="Size and position">
      <div className="properties-row">
        <PropertyNumber
          disabled={typeof props.onGetPropertyValue('width') === 'string'}
          name="width"
          label="Width"
          onSubmit={(value) => props.onSetPropertyValue('width', value)}
          icon={width}
          value={props.onGetPropertyValue('width')}
        />
        <CheckboxWithLabel
          checked={typeof props.onGetPropertyValue('width') === 'string'}
          name="autoWidth"
          label="Auto"
          onChange={(checked) => {
            if (checked) props.onSetPropertyValue('width', 'auto');
            else props.onSetPropertyValue('width', 200);
          }}
        />
        <PropertyNumber
          disabled={typeof props.onGetPropertyValue('height') === 'string'}
          name="height"
          label="Height"
          onSubmit={(value) => props.onSetPropertyValue('height', value)}
          icon={height}
          value={props.onGetPropertyValue('height')}
        />
        <CheckboxWithLabel
          checked={typeof props.onGetPropertyValue('height') === 'string'}
          name="autoHeight"
          label="Auto"
          onChange={(checked) => {
            if (checked) props.onSetPropertyValue('height', 'auto');
            else props.onSetPropertyValue('height', 200);
          }}
        />
      </div>

      <div className="properties-row">
        <PropertyNumber
          name="x"
          label="X position"
          onSubmit={(value) => props.onSetPropertyValue('x', value)}
          icon={x}
          value={props.onGetPropertyValue('x')}
        />
        <PropertyNumber
          name="y"
          label="Y position"
          onSubmit={(value) => props.onSetPropertyValue('y', value)}
          icon={y}
          value={props.onGetPropertyValue('y')}
        />
      </div>
    </PropertiesSection>
  );
};
