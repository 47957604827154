import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useWindowEvent } from '../../hooks/useWindowEvent';
import { Layer } from '../../models/composition';
import { AppState } from '../../redux/AppState';
import { setPropertyValueAC, SetPropertyValuePayload } from '../../redux/reducers/projectReducer';
import { compActiveFrameSelector, compIdSelector } from '../../redux/selectors/compositionSelector';
import { isLayerSelectedSelector } from '../../redux/selectors/layersSelector';
import { setPropertyValueThunk } from '../../redux/thunks/layersThunk';
import { KeyboardService } from '../../services/KeyboardService';
import { ElementControlHandle } from './ElementControlHandle';

const handlerWidth = 10;
const handlerHeight = 10;
const halfHandlerWidth = handlerWidth / 2;
const halfHandlerHeight = handlerHeight / 2;

type PropsType = {
  layer: Layer;
  zoom: number;
  width: number;
  height: number;
  x: number;
  y: number;
  /**
   * Variable whoose change indicates that there was a drag change (probably new bounds)
   */
  refreshFlag: boolean;
  hover: boolean;
  elementRef: any;
};

export const ElementControls = (props: PropsType) => {
  const compId = useSelector(compIdSelector);
  const compActFrame = useSelector(compActiveFrameSelector);
  const isLayerSelected = useSelector(isLayerSelectedSelector(props.layer.id));

  const spaceDown = useSelector((state: AppState) => state.app.spaceDown);
  const dispatch = useDispatch();

  /**
   * Bounds determine the position and the size of the ElementControls shape.
   */
  const getBounds = () => {
    const el = props.elementRef?.current;
    if (!el) return;

    const newBounds = el.getBoundingClientRect();
    if (!newBounds) return;

    return newBounds;
  };

  const [bounds, setBounds] = useState<any>();

  /**
   * UseEffect is triggered on any props update.
   * The most crucial prop is the refreshFlag.
   */
  useEffect(() => {
    setBounds(getBounds());

    // * Important - this fixes a bug with ElementControls not beeing pixel-perfect on dragging.
    // The issue is probably because
    setTimeout(() => {
      setBounds(getBounds());
    }, 1);
  }, [props]);

  const foundY = props.y;
  const foundHeight = props.height;
  const foundX = props.x;
  const foundWidth = props.width;

  /**
   * Keyboard arrow keys controls
   */
  const [lastDispatch, setLastDispatch] = useState<number>(0);

  useWindowEvent('keydown', (event: KeyboardEvent) => {
    // Fire callback only if the focus is on BODY or Timeline-Layer
    if (document.activeElement?.tagName !== 'BODY' && !document.activeElement?.classList.contains('timeline-layer')) {
      return;
    }

    if (!isLayerSelected) return;
    const currentTime = new Date().getTime();

    if (currentTime - lastDispatch > 200) {
      const incVal = KeyboardService.isKeyDown('ShiftLeft') ? 20 : 1;
      if (event.code === 'ArrowUp') {
        dispatch(setPropertyValueThunk(props.layer.id, [{ propertyId: 'y', value: foundY - incVal }], undefined, false, true));
        event.preventDefault();
      } else if (event.code === 'ArrowDown') {
        dispatch(setPropertyValueThunk(props.layer.id, [{ propertyId: 'y', value: foundY + incVal }], undefined, false, true));
        event.preventDefault();
      } else if (event.code === 'ArrowLeft') {
        dispatch(setPropertyValueThunk(props.layer.id, [{ propertyId: 'x', value: foundX - incVal }], undefined, false, true));
        event.preventDefault();
      } else if (event.code === 'ArrowRight') {
        dispatch(setPropertyValueThunk(props.layer.id, [{ propertyId: 'x', value: foundX + incVal }], undefined, false, true));
        event.preventDefault();
      }

      setLastDispatch(currentTime);
    }
  });

  const coreSettings = {
    layerId: props.layer.id,
    frame: compActFrame - props.layer.startFrame,
  };

  if (!bounds) return <div />;

  return (
    <div
      className={classNames({
        'element-controls': true,
        'focus-layer': true,
        selected: isLayerSelected && props.layer.locked === false,
        hover: props.hover && spaceDown === false,
      })}
      tabIndex={0}
      style={{ left: Math.round(bounds.x), top: Math.round(bounds.y), width: Math.round(bounds.width), height: Math.round(bounds.height) }}
    >
      <div className="element-controls__filler" />
      {isLayerSelected && props.layer.locked === false && (
        <>
          {/* Top left */}
          <ElementControlHandle
            axis="both"
            style={{ cursor: 'se-resize' }}
            onDrag={(deltaX, deltaY) => {
              const payloads: SetPropertyValuePayload[] = [];
              payloads.push({ ...coreSettings, propertyId: 'y', value: foundY + deltaY });
              payloads.push({ ...coreSettings, propertyId: 'height', value: foundHeight - deltaY });
              payloads.push({ ...coreSettings, propertyId: 'x', value: foundX + deltaX });
              payloads.push({ ...coreSettings, propertyId: 'width', value: foundWidth - deltaX });
              dispatch(setPropertyValueAC(payloads));
            }}
            scale={props.zoom}
            x={bounds.x - halfHandlerWidth}
            y={bounds.y - halfHandlerHeight}
          />
          {/* Top center */}
          <ElementControlHandle
            axis="y"
            style={{ cursor: 'n-resize' }}
            onDrag={(deltaX, deltaY) => {
              const payloads: SetPropertyValuePayload[] = [];
              payloads.push({ ...coreSettings, propertyId: 'y', value: foundY + deltaY });
              payloads.push({ ...coreSettings, propertyId: 'height', value: foundHeight - deltaY });
              dispatch(setPropertyValueAC(payloads));
            }}
            scale={props.zoom}
            x={bounds.x + bounds.width / 2 - halfHandlerWidth}
            y={bounds.y - halfHandlerHeight}
          />
          {/* Top right */}
          <ElementControlHandle
            axis="both"
            style={{ cursor: 'sw-resize' }}
            onDrag={(deltaX, deltaY) => {
              const payloads: SetPropertyValuePayload[] = [];
              payloads.push({ ...coreSettings, propertyId: 'y', value: foundY + deltaY });
              payloads.push({ ...coreSettings, propertyId: 'height', value: foundHeight - deltaY });
              payloads.push({ ...coreSettings, propertyId: 'width', value: foundWidth + deltaX });
              dispatch(setPropertyValueAC(payloads));
            }}
            scale={props.zoom}
            x={bounds.x + bounds.width - halfHandlerWidth}
            y={bounds.y - halfHandlerHeight}
          />
          {/* Middle left */}
          <ElementControlHandle
            axis="x"
            style={{ cursor: 'w-resize' }}
            onDrag={(deltaX, deltaY) => {
              const payloads: SetPropertyValuePayload[] = [];
              payloads.push({ ...coreSettings, propertyId: 'x', value: foundX + deltaX });
              payloads.push({ ...coreSettings, propertyId: 'width', value: foundWidth - deltaX });
              dispatch(setPropertyValueAC(payloads));
            }}
            scale={props.zoom}
            x={bounds.x - halfHandlerWidth}
            y={bounds.y + bounds.height / 2 - halfHandlerHeight}
          />
          {/* Middle right */}
          <ElementControlHandle
            axis="x"
            style={{ cursor: 'w-resize' }}
            onDrag={(deltaX, deltaY) => {
              const payloads: SetPropertyValuePayload[] = [];
              payloads.push({ ...coreSettings, propertyId: 'width', value: foundWidth + deltaX });
              dispatch(setPropertyValueAC(payloads));
            }}
            scale={props.zoom}
            x={bounds.x + bounds.width - halfHandlerWidth}
            y={bounds.y + bounds.height / 2 - halfHandlerHeight}
          />
          {/* Bototm left */}
          <ElementControlHandle
            axis="both"
            style={{ cursor: 'ne-resize' }}
            onDrag={(deltaX, deltaY) => {
              const payloads: SetPropertyValuePayload[] = [];
              payloads.push({ ...coreSettings, propertyId: 'height', value: foundHeight + deltaY });
              payloads.push({ ...coreSettings, propertyId: 'x', value: foundX + deltaX });
              payloads.push({ ...coreSettings, propertyId: 'width', value: foundWidth - deltaX });
              dispatch(setPropertyValueAC(payloads));
            }}
            scale={props.zoom}
            x={bounds.x - halfHandlerWidth}
            y={bounds.y + bounds.height - halfHandlerHeight}
          />
          {/* Bottom center */}
          <ElementControlHandle
            axis="y"
            style={{ cursor: 'n-resize' }}
            onDrag={(deltaX, deltaY) => {
              const payloads: SetPropertyValuePayload[] = [];
              payloads.push({ ...coreSettings, propertyId: 'height', value: foundHeight + deltaY });
              dispatch(setPropertyValueAC(payloads));
            }}
            scale={props.zoom}
            x={bounds.x + bounds.width / 2 - halfHandlerWidth}
            y={bounds.y + bounds.height - halfHandlerHeight}
          />
          {/* Bottom right */}
          <ElementControlHandle
            axis="both"
            style={{ cursor: 'se-resize' }}
            onDrag={(deltaX, deltaY) => {
              const payloads: SetPropertyValuePayload[] = [];
              payloads.push({ ...coreSettings, propertyId: 'height', value: foundHeight + deltaY });
              payloads.push({ ...coreSettings, propertyId: 'width', value: foundWidth + deltaX });
              dispatch(setPropertyValueAC(payloads));
            }}
            scale={props.zoom}
            x={bounds.x + bounds.width - halfHandlerWidth}
            y={bounds.y + bounds.height - halfHandlerHeight}
          />
        </>
      )}
    </div>
  );
};
