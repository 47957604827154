import classNames from 'classnames';
import { Field, Form, Formik } from 'formik';
import { useState } from 'react';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import { useDispatch } from 'react-redux';
import { Layer } from '../../../../models/composition';
import {
  setLayerNameAC,
  toggleLayerGuideAC,
  toggleLayerVisibilityAC,
  toggleLockedLayerAC,
} from '../../../../redux/reducers/projectReducer';
import { VisibilityToggle } from '../VisibilityToggle';
import layerControlsArrange from '../../../../img/icons/layer-controls-arrange.svg';
import { ColorsService } from '../../../../services/ColorsService';
import { LockToggle } from '../LockToggle';

import textIcon from '../../../../img/icons/layers/text-icon.svg';
import guideIcon from '../../../../img/icons/layers/guide-icon.svg';
import imageIcon from '../../../../img/icons/layers/image-icon.svg';
import shapeIcon from '../../../../img/icons/layers/shape-icon.svg';
import imageLoaderIcon from '../../../../img/icons/layers/image-loader-icon.svg';
import sequenceIcon from '../../../../img/icons/layers/sequence-icon.svg';

import { toggleOpenLayerThunk } from '../../../../redux/thunks/layersThunk';

type PropsType = { layer: Layer; dragHandleProps?: DraggableProvidedDragHandleProps };

export const LCLayerHeader = (props: PropsType) => {
  const [isLocked, setLocked] = useState(false);
  const dispatch = useDispatch();

  return (
    <div className="layer-controls__item__header">
      <div className="drag-handle" {...props.dragHandleProps}>
        <img src={layerControlsArrange} alt="Rearrange" />
      </div>
      <div
        className="layer-color"
        style={{ backgroundColor: ColorsService.getColor(props.layer.color).timeline, opacity: props.layer.visible ? 1 : 0.3 }}
      />
      <div className="layer-icon">
        {props.layer.element.type === 'TEXT' && <img src={textIcon} alt="Text layer" />}
        {props.layer.element.type === 'IMAGE_SEQUENCE' && <img src={sequenceIcon} alt="Sequence layer" />}
        {props.layer.element.type === 'IMAGE' && <img src={imageIcon} alt="Img layer" />}
        {props.layer.element.type === 'SHAPE' && <img src={shapeIcon} alt="Shape layer" />}
        {props.layer.element.type === 'IMAGE_LOADER' && <img src={imageLoaderIcon} alt="Img loader" />}
      </div>

      <div className="name">
        <Formik
          initialValues={{ name: props.layer.name }}
          onSubmit={(values) => {
            setLocked(true);
            dispatch(setLayerNameAC({ layerId: props.layer.id, name: values.name }));
          }}
        >
          {({ values, submitForm, handleSubmit }) => {
            return (
              <Form>
                <Field
                  autoComplete="off"
                  id="name"
                  name="name"
                  readOnly={isLocked}
                  placeholder="First Name"
                  onDoubleClick={() => setLocked(false)}
                  style={{ textDecoration: props.layer.visible ? 'none' : 'line-through' }}
                  onBlur={(e: any) => {
                    e.currentTarget.blur();
                    setLocked(true);
                    handleSubmit();
                  }}
                />
              </Form>
            );
          }}
        </Formik>
      </div>

      {props.layer.guide && (
        <div className="layer-toggle" onClick={() => dispatch(toggleLayerGuideAC({ layerId: props.layer.id, guide: false }))}>
          <img src={guideIcon} alt="Guide" />
        </div>
      )}
      <VisibilityToggle
        onClick={() => {
          dispatch(toggleLayerVisibilityAC({ layerId: props.layer.id, visible: !props.layer.visible }));
        }}
        visible={props.layer.visible}
      />
      <LockToggle
        onClick={() => {
          dispatch(toggleLockedLayerAC({ layerId: props.layer.id, locked: !props.layer.locked }));
        }}
        locked={props.layer.locked}
      />
      <div
        className={classNames({ arrow: true, open: props.layer.open })}
        onClick={() => dispatch(toggleOpenLayerThunk(props.layer.id, !props.layer.open))}
      />
    </div>
  );
};
