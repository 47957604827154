import { useDispatch } from 'react-redux';
import { useOnKeyDown, useOnKeyUp } from '../hooks/useWindowEvent';
import { redoThunk, saveAsThunk, saveThunk, undoThunk } from '../redux/thunks/keyboardShortcutsThunk';
import { carefullyRemoveLayersThunk, duplicateLayersThunk } from '../redux/thunks/layersThunk';
import { checkIfFocusOnInput, checkIfFocusOnLayer } from '../services/focusHelpers';
import { isKey } from '../services/KeyboardService';

// TODO: Move playback controls here

export const KeyboardShortcutsList: { combination: string; function: string }[] = [
  { combination: 'CTRL + S', function: 'Save project' },
  { combination: 'CTRL + SHIFT + N', function: 'Save project as' },
  { combination: 'CTRL + Z', function: 'Undo' },
  { combination: 'CTRL + Y', function: 'Redo' },
  { combination: 'CTRL + D', function: 'Duplicate layer' },
  { combination: 'K', function: 'Stop animation' },
  { combination: 'L', function: 'Play animation forward' },
  { combination: 'J', function: 'Play animation backwards' },
  { combination: 'K + L', function: 'Go to next frame' },
  { combination: 'K + J', function: 'Go to previous frame' },
];

export const KeyboardShortcuts = () => {
  const dispatch = useDispatch();

  useOnKeyDown((e) => {
    // Disable creating bookmark
    if (isKey(e, 'D') && e.ctrlKey && !checkIfFocusOnInput()) {
      e.preventDefault();
    }
  });

  useOnKeyUp((e) => {
    // Save or Save As
    if (isKey(e, 'S') && e.ctrlKey) {
      e.preventDefault();
      if (e.shiftKey) {
        dispatch(saveAsThunk());
      } else {
        dispatch(saveThunk());
      }
    }

    // Undo
    else if (isKey(e, 'Z') && e.ctrlKey) {
      dispatch(undoThunk());
    }

    // Redo
    else if (isKey(e, 'Y') && e.ctrlKey) {
      dispatch(redoThunk());
    }

    // Duplicate layers
    else if (isKey(e, 'D') && e.ctrlKey) {
      if (checkIfFocusOnLayer()) {
        e.preventDefault();
        dispatch(duplicateLayersThunk());
      }
    }

    // Redo
    else if (isKey(e, 'DELETE')) {
      if (checkIfFocusOnLayer()) {
        dispatch(carefullyRemoveLayersThunk());
      }
    }
  });

  return <></>;
};
