import { Item, ItemParams, Menu } from 'react-contexify';
import 'react-contexify/dist/ReactContexify.css';
import { useDispatch } from 'react-redux';
import { removeFontAC } from '../../redux/reducers/projectReducer';

export const FONT_CONTEXT_MENU_ID = 'FONT-CONTEXT-MENU-ID';

export const FontContextMenu = () => {
  const dispatch = useDispatch();

  const handleDelete = ({ props }: ItemParams<{ id: number }>) => {
    if (props && props.id) dispatch(removeFontAC(props.id));
  };

  return (
    <Menu id={FONT_CONTEXT_MENU_ID}>
      <Item onClick={handleDelete}>Delete font</Item>
    </Menu>
  );
};
