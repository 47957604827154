import { Composition } from '../../models/composition';
import { Project } from '../../models/project';

const compositionDOMBuilder = (project: Project, composition: Composition) => {
  let layersString = '';
  const layers = composition.layers;

  for (const layer of layers.slice(0).reverse()) {
    let elementString = '';

    if (layer.guide || !layer.visible) continue;

    switch (layer.element.type) {
      case 'COMPOSITION':
        const compId = layer.element.compositionId;
        const found = project.compositions.find((comp) => comp.id === compId);
        if (!found) return;
        elementString = `<div id="${layer.element.dynamicId}" data-type="composition" class="element cmp">${compositionDOMBuilder(
          project,
          found,
        )}</div>`;
        break;

      case 'TEXT':
        let dataAutoShrink = '';
        if (layer.element.disableAutoShrink) {
          dataAutoShrink = ` data-disableautoshrink="true"`;
        }
        elementString = `<div id="${layer.element.dynamicId}" data-type="text" class="element text"${dataAutoShrink}>${layer.element.content}</div>`;
        break;

      case 'IMAGE_SEQUENCE':
        let idx = 0;
        let imagesString = '';
        const imageSequenceId = layer.element.imageSequenceId;
        const imageSequence = project.resources.imageSequences.find((is) => is.id === imageSequenceId);
        if (!imageSequence) return;

        for (let i = 0; i < layer.duration; i++) {
          const imageId = layer.element.imageIds[i];
          if (!imageId) continue;

          const image = imageSequence.images.find((im) => im.id === imageId);
          imagesString += `<img class="image-sequence-frame image-sequence-f-${idx}" src="${image?.content}" />`;
          idx++;
        }

        elementString = `
          <div id="${layer.element.dynamicId}" data-type="image-sequence" class="element image-sequence">
            <div class="scroller">${imagesString}</div>
          </div>`;

        break;

      case 'VIDEO':
        const videoId = layer.element.videoId;
        const video = project.resources.videos.find((item) => item.id === videoId);
        if (!video) return;

        elementString = `
          <div id="${layer.element.dynamicId}" class="element video" data-type="video">
            <video autoplay="false" />
              <source src="${video.content}" type="video/webm" />
            </video>
          </div>`;
        break;

      case 'IMAGE':
        const imageId = layer.element.imageId;
        const image = project.resources.images.find((im) => im.id === imageId);
        if (!image) return;

        elementString = `
          <div id="${layer.element.dynamicId}" class="element image" data-type="image">
            <img class="image" src="${image.content}" />
          </div>`;
        break;

      case 'SVG':
        const svgId = layer.element.svgId;
        const svgImage = project.resources.svgs.find((svg) => svg.id === svgId);
        if (!svgImage) return;

        elementString = `
          <div id="${layer.element.dynamicId}" class="element svg" data-type="svg">
            ${svgImage.content}
          </div>`;

        break;

      case 'IMAGE_LOADER':
        elementString = `
          <div id="${layer.element.dynamicId}" class="element image-loader" data-type="image_loader">
            <img class="image" />
          </div>`;

        break;

      case 'SHAPE':
        elementString = `
          <div id="${layer.element.dynamicId}" class="element shape" data-type="shape">
          </div>`;

        break;

      default:
        break;
    }

    layersString += `<div class="layer-${layer.id} layer">${elementString}</div>\n      `;
  }

  return `<div class="cmp-${composition.id} f-0">${layersString}</div>`;
};

export const renderDOMBuilder = (project: Project, composition: Composition) => {
  return `
    <div id="render">
      ${compositionDOMBuilder(project, composition)}
    </div>
    `;
};
