import { MouseEventHandler } from 'react';
import { Item, Menu, useContextMenu } from 'react-contexify';
import { useDispatch, useSelector } from 'react-redux';
import { ImageResourceThunks } from '../../../../redux/thunks/resources/ImageResourceThunks';
import { VideoResourceThunks } from '../../../../redux/thunks/resources/VideoResourceThunks';
import { compSelector } from '../../../../redux/selectors/compositionSelector';
import { newImageLoaderThunk } from '../../../../redux/thunks/layers/imageLoaderThunk';
import { newShapeLayerThunk } from '../../../../redux/thunks/layers/shapeThunk';
import { newSvgLayerThunk } from '../../../../redux/thunks/layers/svgLayerThunk';
import { newTextLayerThunk } from '../../../../redux/thunks/layers/textLayerThunk';
import { deselectAllLayersThunk } from '../../../../redux/thunks/layersThunk';
import { BtnPlus } from '../../../common/inputs/BtnPlus';
import { ImageSequenceResourceThunks } from '../../../../redux/thunks/resources/ImageSequenceResourceThunks';

const MENU_ID = 'add-new-layer';

export const ControlsHeader = () => {
  const composition = useSelector(compSelector);
  const dispatch = useDispatch();

  const { show } = useContextMenu({
    id: MENU_ID,
  });

  const handleContextMenu: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault();
    show(event);
  };

  return (
    <>
      <div
        className="controls-header"
        onClick={() => {
          dispatch(deselectAllLayersThunk());
        }}
        style={{ width: composition.layerControlsWidth }}
      >
        <div className="current-frame">{composition.activeFrame}</div>
        <div className="end-frame">/{composition.duration}</div>
        <BtnPlus className="btn add-new-layer" onClick={handleContextMenu} />
      </div>
      <Menu id={MENU_ID}>
        <Item onClick={() => dispatch(newTextLayerThunk())}>Text</Item>
        <Item onClick={() => dispatch(newShapeLayerThunk())}>Shape</Item>
        <Item onClick={() => dispatch(ImageResourceThunks.newLayer())}>Image</Item>
        <Item onClick={() => dispatch(newSvgLayerThunk())}>SVG</Item>
        <Item onClick={() => dispatch(ImageSequenceResourceThunks.newLayer())}>Image Sequence</Item>
        <Item onClick={() => dispatch(newImageLoaderThunk())}>Image Loader</Item>
        {/* <Item onClick={() => dispatch(VideoResourceThunks.newLayer())}>Video</Item> */}
      </Menu>
    </>
  );
};
