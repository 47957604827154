import { Helmet } from 'react-helmet';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { removeFullPageLoaderAC, setFullPageLoaderAC } from '../redux/reducers/appReducer';
import { setNotificationAC } from '../redux/reducers/notificationReducer';
import { AuthApi } from '../services/api/AuthApi';
import { SeoService } from '../services/SeoService';

export const ActivateAccountPage = () => {
  const { userId, code } = useParams<{ userId: string; code: string }>();
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    const handleActivateUser = async (userId: string, code: string) => {
      dispatch(setFullPageLoaderAC({ title: 'Activating account', visible: true }));
      try {
        const res = await AuthApi.activateAccount(userId, code);
        dispatch(setNotificationAC({ type: 'success', message: 'Account activated' }));
      } catch (error) {
        dispatch(setNotificationAC({ type: 'error', message: 'An error occured' }));
      }
      dispatch(removeFullPageLoaderAC());
      history.push('/');
    };

    handleActivateUser(userId, code);
  }, []);

  return (
    <div className="activate-account-page">
      <Helmet>
        <title>{SeoService.getPageTitle('Activate acccount')}</title>
      </Helmet>
    </div>
  );
};
