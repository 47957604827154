import { PropertySettings } from '../PropertySettingsService';

export const color: PropertySettings = {
  name: 'Color',
  default: '#696969',
  types: ['TEXT'],
};

export const backgroundColor: PropertySettings = {
  name: 'Background color',
  default: 'rgb(43, 121, 176)',
  types: ['SHAPE'],
};
