import { RefObject } from 'react';
import { useSelector } from 'react-redux';
import { AppState } from '../../../redux/AppState';
import { projectSelector } from '../../../redux/selectors/projectSelector';

type PropsType = {
  id: number;
  styles: any;
  imageId: number;
  activeFrame: number;
  elementRef: RefObject<HTMLDivElement>;
};

export const ImageRenderElement = (props: PropsType) => {
  const images = useSelector(projectSelector).resources.images;

  const image = images.find((img) => img.id === props.imageId);
  if (!image) return <></>;

  return (
    <div className={`element element-${props.id} image`} style={props.styles} ref={props.elementRef}>
      <img className="image" key={`layer-image-${props.id}`} alt={`${props.id}`} draggable={false} src={image.content} />
    </div>
  );
};
