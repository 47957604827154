import * as Yup from 'yup';
import { Field, Form, Formik } from 'formik';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { AppState } from '../../redux/AppState';
import { Inforow } from '../common/inputs/Inforow';
import { InputWrapper } from '../common/inputs/InputWrapper';
import { SmartBtn } from '../common/inputs/SmartBtn';
import classNames from 'classnames';
import { UsersApi } from '../../services/api/UsersApi';
import { TopContentBottomControls } from '../common/layouts/top-content-bottom-controls/TopContentBottomControls';
import { useDispatch } from 'react-redux';
import { setNotificationAC } from '../../redux/reducers/notificationReducer';
import { setUserAC } from '../../redux/reducers/userReducer';

export const UserForm = () => {
  const [formError, setFormError] = useState('');
  const user = useSelector((state: AppState) => state.user);
  const dispatch = useDispatch();

  return (
    <Formik
      initialValues={{
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
      }}
      validationSchema={Yup.object({
        firstName: Yup.string().required('First name is required'),
        lastName: Yup.string().required('Last name is required'),
        email: Yup.string().email('Email must be valid').required('Email is required'),
      })}
      onSubmit={async (values, { setErrors, setSubmitting }) => {
        try {
          const res = await UsersApi.update(user.id, {
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
          });
          setSubmitting(false);
          setFormError('');

          console.log(res.data);
          dispatch(setUserAC(res.data));

          dispatch(setNotificationAC({ message: 'User information successfully updated', type: 'success' }));
        } catch (error) {
          setSubmitting(false);
          if (error.statusCode === 400) {
            setErrors({ email: 'Email is already registered' });
            setFormError('');
          } else {
            console.log(error);
            dispatch(setNotificationAC({ message: 'An error occured', type: 'error' }));
            setFormError('An error occured');
          }
        }
      }}
    >
      {({ isSubmitting, errors, isValid, touched }) => (
        <Form className="human-form">
          <TopContentBottomControls>
            <div className="top-content">
              <InputWrapper label="First name" labelPosition="top" name="firstName" error={touched.firstName && errors.firstName}>
                <Field
                  type="text"
                  name="firstName"
                  autoComplete="given-name"
                  className={classNames({ classic: true, error: touched.firstName && errors.firstName })}
                />
              </InputWrapper>
              <InputWrapper label="Last name" labelPosition="top" name="lastName" error={touched.lastName && errors.lastName}>
                <Field
                  type="text"
                  name="lastName"
                  autoComplete="family-name"
                  className={classNames({ classic: true, error: touched.lastName && errors.lastName })}
                />
              </InputWrapper>
              <InputWrapper label="Email" labelPosition="top" name="email" error={touched.email && errors.email}>
                <Field
                  type="text"
                  name="email"
                  autoComplete="username"
                  className={classNames({ classic: true, error: touched.email && errors.email })}
                />
              </InputWrapper>
            </div>
            <div className="bottom-content">
              {formError && <Inforow type="error" message={formError} />}
              <SmartBtn type="submit" className="submit" label="Update info" loading={isSubmitting} />
            </div>
          </TopContentBottomControls>
        </Form>
      )}
    </Formik>
  );
};
