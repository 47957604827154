import { useDispatch } from 'react-redux';
import { Layer, PropertyData } from '../../../../models/composition';
import { moveKeyframeAC, setKeyframeEasingAC } from '../../../../redux/reducers/projectReducer';
import { PropertyId } from '../../../../services/PropertySettingsService';
import { DraggableTimeline } from '../../../DraggableTimeline';
import { KeyframeSymbol } from '../../KeyframeSymbol';
import { EasingMenu } from './EasingMenu';

type PropsType = {
  scale: number;
  layer: Layer;
  propertyId: PropertyId;
  property: PropertyData;
};

export const KeyframePropertyRow = ({ layer, propertyId, property, scale }: PropsType) => {
  const dispatch = useDispatch();

  if (!property) {
    // Property does not exist
    // For example, backgroundColor on text element
    return <></>;
  }

  return (
    <div className="keyframe-property-row">
      {property.keyframes.map((keyframe) => {
        // * Draw keyframe symbols
        return (
          <DraggableTimeline
            key={'keyframe-' + keyframe.id}
            onDrag={(newSelectedFrame: number, dragStartFrame: number) => {
              dispatch(
                moveKeyframeAC({
                  layerId: layer.id,
                  propertyId: propertyId,
                  keyframeId: keyframe.id,
                  frame: newSelectedFrame - layer.startFrame,
                }),
              );
            }}
          >
            <div
              className="keyframe-wrapper"
              style={{
                left: layer.startFrame * scale + keyframe.frame * scale + scale / 2,
              }}
            >
              <KeyframeSymbol layerId={layer.id} propertyId={propertyId} keyframeId={keyframe.id} />
            </div>
          </DraggableTimeline>
        );
      })}
      {property.keyframes.map((keyframe, idx) => {
        const currentKeyframe = keyframe;
        const nextKeyframe = property.keyframes[idx + 1];

        if (nextKeyframe) {
          return (
            <EasingMenu
              left={currentKeyframe.frame * scale + ((nextKeyframe.frame - currentKeyframe.frame + 1) / 2) * scale}
              framesDistance={nextKeyframe.frame - currentKeyframe.frame}
              scale={scale}
              preset={keyframe.easing?.presetName}
              p1x={keyframe.easing?.p1x}
              p1y={keyframe.easing?.p1y}
              p2x={keyframe.easing?.p2x}
              p2y={keyframe.easing?.p2y}
              onItemClick={(presetName: string, p1x: number, p1y: number, p2x: number, p2y: number) => {
                dispatch(
                  setKeyframeEasingAC({
                    layerId: layer.id,
                    propertyId: propertyId,
                    keyframeId: keyframe.id,
                    presetName,
                    p1x,
                    p1y,
                    p2x,
                    p2y,
                  }),
                );
              }}
            />
          );
        } else {
          return <></>;
        }
      })}
      {property.keyframes.map((keyframe, idx) => {
        // * Draw lines between keyframes
        const currentKeyframe = keyframe;
        const nextKeyframe = property.keyframes[idx + 1];

        if (nextKeyframe) {
          return (
            <div
              key={'keyframe-line-' + keyframe.id}
              className="keyframes-line"
              style={{
                left: layer.startFrame * scale + (currentKeyframe.frame + 1) * scale,
                width: (nextKeyframe.frame - currentKeyframe.frame - 1) * scale,
              }}
            ></div>
          );
        }
      })}
    </div>
  );
};
