import { Layer } from '../../../models/composition';
import { ColorsService } from '../../../services/ColorsService';
import { getActiveComposition } from '../../helpers';
import { addLayerAC } from '../../reducers/projectReducer';
import { projectSelector } from '../../selectors/projectSelector';
import { MyThunkActionReturn } from '../thunkTypes';

export const newTextLayerThunk = (): MyThunkActionReturn => {
  return async (dispatch, getState) => {
    const state = getState();
    const composition = getActiveComposition(projectSelector(state));

    const newLayer: Layer = {
      id: composition.lastLayerId + 1,
      name: 'New text layer',
      startFrame: 0,
      duration: composition.workspaceDuration,
      color: ColorsService.getElementColorId('TEXT'),
      visible: true,
      locked: false,
      guide: false,
      element: {
        type: 'TEXT',
        content: 'Text layer',
        dynamicId: '',
        disableAutoShrink: false,
      },
      openPropertyGroups: [],
      properties: {},
      open: false,
    };

    dispatch(addLayerAC(newLayer));
  };
};
