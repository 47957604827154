import { filterBlur, filterBrightness, filterContrast, filterGrayscale } from './property-settings/filters';
import { color, backgroundColor } from './property-settings/appearance';
import { ElementTypes } from '../models/elements';

const width: PropertySettings = {
  name: 'Width',
  default: 400,
  unit: 'px',
  decimals: 0,
};

const height: PropertySettings = {
  name: 'Height',
  default: 120,
  unit: 'px',
  decimals: 0,
};

const x: PropertySettings = {
  name: 'X',
  default: 0,
  unit: 'px',
  decimals: 0,
};

const y: PropertySettings = {
  name: 'Y',
  unit: 'px',
  decimals: 0,
  default: 0,
};

const opacity: PropertySettings = {
  name: 'Opacity',
  unit: '%',
  decimals: 2,
  scaleFactor: 100,
  default: 1,
};

const scaleX: PropertySettings = {
  name: 'Scale X',
  decimals: 2,
  default: 1,
};

const scaleY: PropertySettings = {
  name: 'Scale Y',
  decimals: 2,
  default: 1,
};

const fontSize: PropertySettings = {
  name: 'Font size',
  default: 72,
  decimals: 2,
  types: ['TEXT'],
};

const fontFamily: PropertySettings = {
  name: 'Font Family',
  default: 'Arial',
  types: ['TEXT'],
};

const textAlign: PropertySettings = {
  name: 'Text Alignment',
  default: 'left',
  types: ['TEXT'],
};

const textDecoration: PropertySettings = {
  name: 'Text Decoration',
  default: 'none',
  types: ['TEXT'],
};

const textTransform: PropertySettings = {
  name: 'Text Transform',
  default: 'none',
  types: ['TEXT'],
};

const letterSpacing: PropertySettings = {
  name: 'Letter spacing',
  unit: 'px',
  decimals: 0,
  default: 0,
  types: ['TEXT'],
};

const lineHeight: PropertySettings = {
  name: 'Line height',
  decimals: 0,
  default: 1,
  types: ['TEXT'],
};

const fontStyle: PropertySettings = {
  name: 'Font style',
  default: 'normal',
  types: ['TEXT'],
};

const fontWeight: PropertySettings = {
  name: 'Font weight',
  decimals: 2,
  default: 'normal',
  types: ['TEXT'],
};

const fontVariant: PropertySettings = {
  name: 'Font variant',
  default: 'normal',
  types: ['TEXT'],
};

const whiteSpace: PropertySettings = {
  name: 'White space',
  default: 'nowrap',
  types: ['TEXT'],
};

const visibility: PropertySettings = {
  name: 'Visibility',
  default: 'visible',
};

export const allProperties = {
  width,
  height,
  x,
  y,
  opacity,
  scaleX,
  scaleY,
  fontSize,
  fontFamily,
  textAlign,
  textDecoration,
  textTransform,
  letterSpacing,
  lineHeight,
  fontStyle,
  fontWeight,
  fontVariant,
  whiteSpace,
  color,
  backgroundColor,
  filterBlur,
  filterBrightness,
  filterContrast,
  filterGrayscale,
  visibility,
};

export const propertiesLayout: PropertiesLayout = [
  { id: 'transform', title: 'Transform', items: ['width', 'height', 'x', 'y', 'opacity'] },
  { id: 'appearance', title: 'Appearance', types: ['TEXT'], items: ['color', 'backgroundColor'] },
  { id: 'filters', title: 'Filters', items: ['filterBlur', 'filterBrightness', 'filterContrast', 'filterGrayscale'] },
  {
    id: 'font',
    title: 'Text',
    types: ['TEXT'],
    items: [
      'fontSize',
      'fontFamily',
      'textAlign',
      'textDecoration',
      'textTransform',
      'letterSpacing',
      'lineHeight',
      'fontStyle',
      'fontWeight',
      'fontVariant',
      'whiteSpace',
    ],
  },
];

export type PropertiesLayout = LayoutItem[];

export type PropertyLayoutGroup = {
  id: string;
  title: string;
  items: LayoutItem[];
  types?: ElementTypes[];
};

export type LayoutItem = PropertyLayoutGroup | PropertyId;

export type PropertySettings = {
  name: string;
  cssId?: string;
  unit?: string;
  decimals?: number;
  scaleFactor?: number;
  default: string | number;
  types?: ElementTypes[];
};

export type PropertyId = keyof typeof allProperties;
export type PropertyValue = string | number;

export class PropertySettingsService {
  static getAllPropertiesForType(type: ElementTypes) {
    const filtered = {} as any;
    for (const propId of Object.keys(allProperties)) {
      const propertyId = propId as PropertyId;
      if ((allProperties[propertyId] as any).types) {
        if ((allProperties[propertyId] as any).types.indexOf(type) > -1) {
          // Has types and has this type
          filtered[propertyId] = allProperties[propertyId];
        }
      } else {
        // No types at all
        filtered[propertyId] = allProperties[propertyId];
      }
    }
    // console.log(filtered);
    return filtered;
  }

  static getSettings(propertyId: PropertyId) {
    return allProperties[propertyId];
  }
}
