import { ChromePicker } from 'react-color';

type PropsType = {
  color: string;
  onChange: (color: string) => void;
};

export const ColorPicker = (props: PropsType) => {
  const handleChange = (color: any) => {
    props.onChange(`rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`);
  };

  return (
    <div className="cp-wrapper">
      <ChromePicker disableAlpha={false} color={props.color} onChange={handleChange} />
    </div>
  );
};
