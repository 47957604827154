import { RefObject } from 'react';

type PropsType = {
  id: number;
  styles: any;
  content: string;
  elementRef: RefObject<HTMLDivElement>;
};

export const TextRenderElement = (props: PropsType) => {
  return (
    <div className={`element element-${props.id} text`} style={props.styles} ref={props.elementRef}>
      {props.content}
    </div>
  );
};
