import { Popup } from '../common/popup/Popup';
import { InputWrapper } from '../common/inputs/InputWrapper';
import { SmartBtn } from '../common/inputs/SmartBtn';
import { Inforow } from '../common/inputs/Inforow';
import { Field, Form, Formik } from 'formik';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { useState } from 'react';
import { setPopupAC } from '../../redux/reducers/appReducer';
import { AuthApi } from '../../services/api/AuthApi';
import { validatePassword } from '../../services/validators/PasswordValidator';
import { useHistory } from 'react-router-dom';
import { SITEMAP } from '../../config/SITEMAP';

type PropsType = {
  userId: string;
  token: string;
};

export const ResetPasswordPopup = (props: PropsType) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [formError, setFormError] = useState('');
  const [status, setStatus] = useState('');

  return (
    <Popup id="resetPassword" title="Reset password" statusText={status} disableClose>
      <Formik
        initialValues={{
          password: '',
          passwordConfirmation: '',
        }}
        validate={validatePassword}
        onSubmit={async (values, { setSubmitting, setErrors }) => {
          try {
            const res = await AuthApi.resetPassword(props.userId, props.token, {
              password: values.password,
            });
            setStatus('Password successfully reset');
            setSubmitting(false);
            history.push(SITEMAP.STUDIO);
            dispatch(setPopupAC({ popupId: 'login', open: true }));
          } catch (error) {
            setSubmitting(false);
            console.log(error);
            setFormError('An error occured');
          }
        }}
      >
        {({ isSubmitting, errors, isValid, touched }) => (
          <Form className="human-form auth">
            <h1>Set new password</h1>
            <InputWrapper label="Password" labelPosition="top" name="password" error={touched.password && errors.password}>
              <Field
                type="password"
                name="password"
                autoComplete="new-password"
                className={classNames({ classic: true, error: touched.password && errors.password })}
              />
            </InputWrapper>
            <InputWrapper
              label="Repeat password"
              labelPosition="top"
              name="passwordConfirmation"
              error={touched.passwordConfirmation && errors.passwordConfirmation}
            >
              <Field
                type="password"
                name="passwordConfirmation"
                autoComplete="new-password"
                className={classNames({ classic: true, error: touched.passwordConfirmation && errors.passwordConfirmation })}
              />
            </InputWrapper>
            {formError && <Inforow type="error" message={formError} />}
            <SmartBtn className="submit" label="Update password" loading={isSubmitting} />
          </Form>
        )}
      </Formik>
    </Popup>
  );
};
