import { MouseEventHandler } from 'react';
import flagIcon from '../../../img/icons/flag-red.svg';

type PropsType = {
  onClick: MouseEventHandler<HTMLButtonElement>;
  label: string;
};

export const ActionBtn = (props: PropsType) => {
  return (
    <button className="btn action" onClick={props.onClick}>
      <img src={flagIcon} alt="Red flag" />
      <span>{props.label}</span>
    </button>
  );
};
