import { post } from './config';

type SendFeedback = {
  email?: string;
  feedback: string;
};

export class FeedbackApi {
  static sendFeedback = async (params: SendFeedback) => await post<{}>(`/feedback/`, params);
}
