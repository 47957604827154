import { Popup } from '../common/popup/Popup';
import { InputWrapper } from '../common/inputs/InputWrapper';
import { SmartBtn } from '../common/inputs/SmartBtn';
import { Inforow } from '../common/inputs/Inforow';
import { AuthLayout } from '../common/layouts/auth-layout/AuthLayout';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useState } from 'react';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { setPopupAC } from '../../redux/reducers/appReducer';
import { UsersApi } from '../../services/api/UsersApi';
import { AuthService } from '../../services/AuthService';
import { validatePassword } from '../../services/validators/PasswordValidator';
import { setNotificationAC } from '../../redux/reducers/notificationReducer';
import { SITEMAP } from '../../config/SITEMAP';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';

type PropsType = {
  disableClose?: boolean;
};

export const RegisterPopup = (props: PropsType) => {
  const dispatch = useDispatch();
  const [formError, setFormError] = useState('');
  const history = useHistory();

  return (
    <Popup id="register" title="Register" disableClose={props.disableClose} onClose={() => history.push(SITEMAP.STUDIO)}>
      <AuthLayout
        leftChildren={
          <>
            <h1>
              Create your
              <br />
              <b>free</b> account!
            </h1>
            <div className="row">
              Already have an account?
              <div>
                <Link to={SITEMAP.LOGIN}>Log in.</Link>
              </div>
            </div>
          </>
        }
        rightChildren={
          <Formik
            initialValues={{
              firstName: '',
              lastName: '',
              email: '',
              password: '',
              passwordConfirmation: '',
            }}
            validationSchema={Yup.object({
              firstName: Yup.string().required('First name is required'),
              lastName: Yup.string().required('Last name is required'),
              email: Yup.string().email('Email must be valid').required('Email is required'),
            })}
            validate={validatePassword}
            onSubmit={async (values, { setErrors, setSubmitting }) => {
              try {
                const res = await UsersApi.create({
                  firstName: values.firstName,
                  lastName: values.lastName,
                  email: values.email,
                  password: values.password,
                });
                AuthService.login(res.data.access_token, res.data.user);
                dispatch(setNotificationAC({ message: 'Account successfully created', type: 'success' }));
                dispatch(setPopupAC({ popupId: 'register', open: false }));
                setSubmitting(false);
              } catch (error) {
                setSubmitting(false);
                if (error.statusCode === 400) {
                  setErrors({ email: 'Email is already registered' });
                } else {
                  console.log(error);
                  dispatch(setNotificationAC({ message: 'An error occured', type: 'error' }));
                  setFormError('An error occured');
                }
              }
            }}
          >
            {({ isSubmitting, errors, isValid, touched }) => (
              <Form className="human-form auth">
                <InputWrapper label="First name" labelPosition="top" name="firstName" error={touched.firstName && errors.firstName}>
                  <Field
                    type="text"
                    name="firstName"
                    autoComplete="given-name"
                    className={classNames({ classic: true, error: touched.firstName && errors.firstName })}
                  />
                </InputWrapper>
                <InputWrapper label="Last name" labelPosition="top" name="lastName" error={touched.lastName && errors.lastName}>
                  <Field
                    type="text"
                    name="lastName"
                    autoComplete="family-name"
                    className={classNames({ classic: true, error: touched.lastName && errors.lastName })}
                  />
                </InputWrapper>
                <InputWrapper label="Email" labelPosition="top" name="email" error={touched.email && errors.email}>
                  <Field
                    type="text"
                    name="email"
                    autoComplete="email"
                    className={classNames({ classic: true, error: touched.email && errors.email })}
                  />
                </InputWrapper>
                <InputWrapper label="Password" labelPosition="top" name="password" error={touched.password && errors.password}>
                  <Field
                    type="password"
                    name="password"
                    autoComplete="new-password"
                    className={classNames({ classic: true, error: touched.password && errors.password })}
                  />
                </InputWrapper>
                <InputWrapper
                  label="Repeat password"
                  labelPosition="top"
                  name="passwordConfirmation"
                  error={touched.passwordConfirmation && errors.passwordConfirmation}
                >
                  <Field
                    type="password"
                    name="passwordConfirmation"
                    autoComplete="new-password"
                    className={classNames({ classic: true, error: touched.passwordConfirmation && errors.passwordConfirmation })}
                  />
                </InputWrapper>
                {formError && <Inforow type="error" message={formError} />}
                <SmartBtn type="submit" className="submit" label="Create account" loading={isSubmitting} />
              </Form>
            )}
          </Formik>
        }
      />
    </Popup>
  );
};
