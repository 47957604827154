import { createSelector } from 'reselect';
import { Layer } from '../../models/composition';
import { AppState } from '../AppState';
import { getActiveComposition } from '../helpers';
import { projectSelector } from './projectSelector';

export const selectLayerIds = (state: AppState) => {
  const actComp = getActiveComposition(projectSelector(state));
  let layerIds = actComp.layers?.map((layer) => layer.id);
  if (!layerIds) return [];
  return layerIds;
};

const selectLayerIdsAsString = (state: AppState) => {
  return JSON.stringify(selectLayerIds(state));
};

export const allLayerIdsSelector = createSelector([selectLayerIdsAsString], (layerIds) => {
  return JSON.parse(layerIds) as number[];
});

export const getLayerByIdSelector = (layerId: number) => (state: AppState) => {
  const actComp = getActiveComposition(projectSelector(state));
  return actComp.layers.find((layer) => layer.id === layerId);
};

export const isLayerSelectedSelector = (layerId: number) => {
  return (state: AppState) => {
    const actComp = getActiveComposition(projectSelector(state));
    const idx = actComp.selectedLayers.findIndex((id) => id === layerId);
    return idx > -1;
  };
};

export const getOneSelectedLayerSelector = (state: AppState) => {
  const actComp = getActiveComposition(projectSelector(state));
  const firstLayerId = actComp.selectedLayers[0];
  if (!firstLayerId) return undefined;

  const layer = actComp.layers.find((layer) => layer.id === firstLayerId);
  return layer;
};

export const allSelectedLayerIdsSelector = (state: AppState) => {
  const actComp = getActiveComposition(projectSelector(state));
  const layerIds: number[] = [];
  actComp.layers.forEach((layer) => {
    if (actComp.selectedLayers.includes(layer.id)) {
      layerIds.push(layer.id);
    }
  });
  return layerIds;
};

export const getAllSelectedLayersSelector = (state: AppState) => {
  const actComp = getActiveComposition(projectSelector(state));
  const layers: Layer[] = [];
  actComp.layers.forEach((layer) => {
    if (actComp.selectedLayers.includes(layer.id)) {
      layers.push(layer);
    }
  });
  return layers;
};

export const hasManySelectedLayersSelector = (state: AppState) => {
  const actComp = getActiveComposition(projectSelector(state));
  return actComp.selectedLayers && actComp.selectedLayers.length > 1;
};

export const numberOfSelectedLayersSelector = (state: AppState) => {
  const actComp = getActiveComposition(projectSelector(state));
  return actComp.selectedLayers.length;
};
