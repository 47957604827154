import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ModalProps } from '../../components/common/modal/Modal';
import { AppType } from '../../models/app';

const intialState: AppType = {
  spaceDown: false,
  playMode: false,
  lastSaved: 0,
  touched: false,
  fullPageLoader: {
    visible: true,
    title: 'Loading project',
    subtitle: '',
  },
  modal: undefined,
  popup: {
    login: false,
    register: false,
    account: false,
    forgotPassword: false,
    resetPassword: false,
    casparcgExporterSettings: false,
    compositionActionEditor: false,
    actionEditor: false,
    feedback: false,
    welcome: true,
    about: false,
    keyboardShortcuts: false,
  },
  actionIdEditor: undefined,
  showTutorial: false,
  outputDirectoryPath: undefined,
};

export type CurrentAppState = typeof intialState;

const appSlice = createSlice({
  name: 'app',
  initialState: intialState,
  reducers: {
    toggleSpaceDownAC: (state, { payload }: PayloadAction<boolean>) => {
      state.spaceDown = payload;
    },

    toggleTouchedAC: (state, { payload }: PayloadAction<boolean>) => {
      state.touched = payload;
    },

    setLastSavedAC: (state, { payload }: PayloadAction<number>) => {
      state.lastSaved = payload;
    },

    setModalAC: (state, { payload }: PayloadAction<ModalProps | undefined>) => {
      state.modal = payload;
    },

    setPopupAC: (state, { payload }: PayloadAction<{ popupId: keyof typeof intialState.popup; open: boolean }>) => {
      for (const popupId in state.popup) {
        (state.popup as any)[popupId] = false;
      }
      state.popup[payload.popupId] = payload.open;
    },

    setActionEditorIdAC: (state, { payload }: PayloadAction<number | undefined>) => {
      state.actionIdEditor = payload;
    },

    togglePlayModeAC: (state, { payload }: PayloadAction<{ playMode: boolean }>) => {
      state.playMode = payload.playMode;
    },

    setFullPageLoaderAC: (state, { payload }: PayloadAction<{ visible: boolean; title: string; subtitle?: string }>) => {
      state.fullPageLoader.visible = payload.visible;
      state.fullPageLoader.title = payload.title;
      state.fullPageLoader.subtitle = payload.subtitle ? payload.subtitle : '';
    },

    removeFullPageLoaderAC: (state, { payload }: PayloadAction) => {
      state.fullPageLoader.visible = false;
      state.fullPageLoader.title = '';
      state.fullPageLoader.subtitle = '';
    },

    toggleShowTutorialAC: (state, { payload }: PayloadAction<boolean>) => {
      state.showTutorial = payload;
    },

    setOutputDirectoryPath: (state, { payload }: PayloadAction<string | undefined>) => {
      state.outputDirectoryPath = payload;
    },
  },
});

export const {
  toggleSpaceDownAC,
  toggleTouchedAC,
  setModalAC,
  setPopupAC,
  setActionEditorIdAC,
  setLastSavedAC,
  togglePlayModeAC,
  setFullPageLoaderAC,
  removeFullPageLoaderAC,
  toggleShowTutorialAC,
  setOutputDirectoryPath,
} = appSlice.actions;
export const appReducer = appSlice.reducer;
