import { ActionControls } from './ActionControls';
import { PlaybackControls } from './PlaybackControls';

export const PlaybackAndActionsPanel = () => {
  return (
    <div className="playback-and-actions-panel">
      <PlaybackControls />
      <div className="separator" />
      <ActionControls />
    </div>
  );
};
