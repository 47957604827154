import classNames from 'classnames';

type PropsType = {
  label: string;
  onClick?: () => void;
  children?: React.ReactNode;
  disabled?: boolean;
  shortcut?: string;
};

export const MenuBarItem = (props: PropsType) => {
  const handleClick = (e: any) => {
    if (props.disabled) {
      return;
    }
    if (props.onClick) {
      props.onClick();
    }
  };

  return (
    <li onClick={handleClick} className={classNames({ disabled: props.disabled })}>
      <div className="label">
        <div className="label__name">{props.label}</div>
        <div className="label__shortcut">{props.shortcut}</div>
      </div>
      {props.children && <ul>{props.children}</ul>}
    </li>
  );
};
