import { PanelLayout } from '../common/panel-layout/PanelLayout';
import { useDispatch } from 'react-redux';
import {
  alignHorizontallyCenterThunk,
  alignHorizontallyLeftThunk,
  alignHorizontallyRightThunk,
  alignVerticallyBottomThunk,
  alignVerticallyCenterThunk,
  alignVerticallyTopThunk,
} from '../../redux/thunks/alignmentThunk';

import hLeft from '../../img/icons/alignment-h-left.svg';
import hCenter from '../../img/icons/alignment-h-center.svg';
import hRight from '../../img/icons/alignment-h-right.svg';
import vTop from '../../img/icons/alignment-v-top.svg';
import vCenter from '../../img/icons/alignment-v-center.svg';
import vBottom from '../../img/icons/alignment-v-bottom.svg';

export const AlignmentPanel = () => {
  const dispatch = useDispatch();

  return (
    <PanelLayout id="alignment-panel" title="Alignment" className="no-deselect-layer">
      <button onClick={() => dispatch(alignHorizontallyLeftThunk())} className="icon-btn">
        <img src={hLeft} alt="Left" />
      </button>
      <button onClick={() => dispatch(alignHorizontallyCenterThunk())} className="icon-btn">
        <img src={hCenter} alt="Center" />
      </button>
      <button onClick={() => dispatch(alignHorizontallyRightThunk())} className="icon-btn">
        <img src={hRight} alt="Right" />
      </button>
      <div className="separator" />
      <button onClick={() => dispatch(alignVerticallyTopThunk())} className="icon-btn">
        <img src={vTop} alt="Left" />
      </button>
      <button onClick={() => dispatch(alignVerticallyCenterThunk())} className="icon-btn">
        <img src={vCenter} alt="Center" />
      </button>
      <button onClick={() => dispatch(alignVerticallyBottomThunk())} className="icon-btn">
        <img src={vBottom} alt="Right" />
      </button>
    </PanelLayout>
  );
};
