import React from 'react';
import info from '../../../img/icons/info.svg';
import { Tooltip } from '../tooltip/Tooltip';

type PropsType = {
  id: string;
  title: string;
  children: React.ReactNode;
  tooltip?: string;
  className?: string;
};

export const PanelLayout = ({ id, title, children, tooltip, className }: PropsType) => {
  return (
    <div id={id} className={'panel-layout ' + className}>
      <div className="panel-title">
        <span>{title}</span>
        {tooltip && (
          <img
            className="info"
            src={info}
            alt="Tooltip"
            data-tip={tooltip}
            data-html={true}
            data-place="left"
            data-offset="{'bottom': -3, 'top': -20}"
          />
        )}
      </div>
      <Tooltip />
      <div id={`${id}__content`} className="panel-content">
        {children}
      </div>
    </div>
  );
};
