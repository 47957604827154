import { Composition } from '../../../models/composition';

export const coreCSSBuilder = (composition: Composition) => {
  return `
      body {
        margin: 0;
        padding: 0;
        overflow: hidden;
      }
  
      #invalid-resolution {
        background-color: red;
        color: white;
        font-size: 24px;
        padding: 10px 20px;
        position: fixed;
        top: 0;
        left: 0;
      }
  
      #player {
        position: fixed;
        top: 0px;
        left: 0px;
        z-index: 999;
      }
  
      #player #message {
        background: silver;
        color: black;
        padding: 5px 10px;
        font-size: 14px;
      }
  
      #render {
        width: ${composition.width}px;
        height: ${composition.height}px;
        position: relative;
        opacity: 0;
      }
  
      #render.played {
        opacity: 1;
      }
  
      .layer {
        width: ${composition.width}px;
        height: ${composition.height}px;
        position: absolute;
        top: 0;
        left: 0;
      }
  
      .hide {
        visibility: hidden;
      }
  
      .element {
        position: absolute;
        visibility: hidden;
      }
  
      .element.image-sequence {
        visibility: visible;
        overflow: hidden;
      }
  
      .element.image-sequence .image-sequence-frame {
        display: block;
      }
  
      .element.image .image {
        width: 100%;
        height: 100%;
      }
  
      .element.image-loader .image {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: 100%;
        max-height: 100%;
      }
      `;
};
