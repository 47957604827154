import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { setModalAC } from '../../reducers/appReducer';
import { removeLayerThunk } from '../layersThunk';
import { MyThunkActionReturn } from '../thunkTypes';
import { ConflictItemType } from './ResourceThunks.interface';

export const ResourceUtils = {
  loadFile: async (options: OpenFilePickerOptions) => {
    const fileHandles = await (window as any).showOpenFilePicker(options);
    const fileHandle = fileHandles[0];

    const singleFile = await fileHandle.getFile();

    const content = await new Promise<string>((resolve, reject) => {
      const fr = new FileReader();
      fr.onload = async () => {
        resolve(fr.result as string);
      };
      fr.readAsDataURL(singleFile);
    });

    return { file: singleFile, content };
  },

  remove: (
    name: string,
    id: number,
    conflictItems: ConflictItemType[],
    removeResourceAC: ActionCreatorWithPayload<number, string>,
  ): MyThunkActionReturn => {
    return (dispatch, getState) => {
      if (conflictItems.length <= 0) {
        // Feel free to delete image
        dispatch(removeResourceAC(id));
        return;
      }
      // Image sequence is used by someo of the layers.
      const deleteControl = {
        label: 'Delete',
        onClick: () => {
          for (const item of conflictItems) {
            dispatch(removeLayerThunk(item.compositionId, item.layerId));
          }
          dispatch(removeResourceAC(id));
          dispatch(setModalAC(undefined));
        },
      };
      const cancelControl = {
        label: 'Cancel',
        autoFocus: true,
        onClick: () => dispatch(setModalAC(undefined)),
      };
      const label =
        conflictItems.length > 1
          ? `This ${name.toLowerCase()} is used by multiple layers.`
          : `This ${name.toLowerCase()} is used by the layer "${conflictItems[0].name}".`;
      dispatch(
        setModalAC({
          title: 'Delete confirmation',
          content: label + ` This will cause the deletion of the layer as well. Are you sure to delete ${name.toLowerCase()}?`,
          icon: 'warning',
          controls: [cancelControl, deleteControl],
        }),
      );
    };
  },
};
