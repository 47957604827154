import { useSelector } from 'react-redux';
import { Redirect, Route, RouteProps, useLocation } from 'react-router-dom';
import { SITEMAP } from '../config/SITEMAP';
import { AppState } from '../redux/AppState';
import { AuthService } from '../services/AuthService';

export const PrivateRoute = (props: RouteProps) => {
  const user = useSelector((state: AppState) => state.user);
  const location = useLocation();

  if (AuthService.isLoggedIn(user)) {
    return <Route {...props} />;
  }

  if (location.pathname === props.path) {
    return <Redirect to={SITEMAP.LOGIN} />;
  }

  return <></>;
};
