import ReactSlider from 'react-slider';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../redux/AppState';
import { setCompositionScaleAC } from '../../../redux/reducers/projectReducer';
import logoFooter from '../../../img/logos/logo-footer.png';
import { setPopupAC } from '../../../redux/reducers/appReducer';
import { useEffect, useState } from 'react';
import { compSelector } from '../../../redux/selectors/compositionSelector';

export const BottomControls = () => {
  const comp = useSelector(compSelector);
  const dispatch = useDispatch();
  const [minCompScale, setMinCompScale] = useState(10);

  useEffect(() => {
    const twe = document.querySelector('.timeline-wrapper')!;
    const bounds = twe.getBoundingClientRect();
    const minScale = Math.ceil(bounds.width / comp.duration);
    setMinCompScale(minScale);
  }, [comp]);

  return (
    <div className="bottom-controls-wrapper">
      <div className="signature">
        <img src={logoFooter} alt="Loopic" />
      </div>
      <div className="central">
        <button className="feedback-btn" onClick={() => dispatch(setPopupAC({ popupId: 'feedback', open: true }))}>
          Feedback
        </button>
      </div>
      <div className="timeline-zoom-controls">
        <ReactSlider
          className="horizontal-slider"
          thumbClassName="example-thumb"
          trackClassName="example-track"
          min={minCompScale}
          max={30}
          value={comp.scale}
          onChange={(value) => {
            dispatch(setCompositionScaleAC({ scale: value }));
          }}
        />
        <div className="timeline-zoom-controls__level">{comp.scale}</div>
      </div>
    </div>
  );
};
