export const playoutControlsDOMBuilder = () => `
    <div id="player" style="display: block;">
        <div id="message">
        If you see this buttons in CasparCG's output, you are using an old version of CasparCG.<br/>
        Please update your CasparCG to the latest version.
        </div>
        <div id="buttons">
            <button id="play">Play</button>
            <button id="play-from-start">Play from start</button>
            <button id="stop">Stop</button>
        </div>
    </div>

    <script>
    const playBtn = document.getElementById("play");
    playBtn.addEventListener("click", () => {
        play();
    });
    const playFromStartBtn = document.getElementById("play-from-start");
    playFromStartBtn.addEventListener("click", () => {
        currentFrame = 0;
        play();
    });
    const stopBtn = document.getElementById("stop");
        stopBtn.addEventListener("click", () => {
        stop();
    });

    if (CASPARCG_ENV) {
        document.getElementById("player").style.display = "none";
    }
    </script>
`;
