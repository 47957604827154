import { useDispatch } from 'react-redux';
import infoIcon from '../../../img/icons/modal-info.svg';
import warningIcon from '../../../img/icons/modal-warning.svg';
import errorIcon from '../../../img/icons/modal-error.svg';
import { setModalAC } from '../../../redux/reducers/appReducer';

export type ModalProps = {
  title: string;
  icon?: 'info' | 'warning' | 'error';
  content: string;
  asHtml?: boolean;
  controls: { label: string; autoFocus?: boolean; onClick: () => void }[];
};

export const Modal = (props: ModalProps) => {
  const dispatch = useDispatch();

  return (
    <div className="modal">
      <div className="modal__window">
        <div className="modal__window__header">
          <div className="modal__window__header__title">{props.title}</div>
          <button className="modal__window__header__close" onClick={() => dispatch(setModalAC(undefined))} />
        </div>
        <div className="modal__window__content">
          {props.icon && (
            <div className="modal__window__content__icon">
              {props.icon === 'info' && <img src={infoIcon} alt="Info" />}
              {props.icon === 'warning' && <img src={warningIcon} alt="Warning" />}
              {props.icon === 'error' && <img src={errorIcon} alt="Error" />}
            </div>
          )}
          <div className="modal__window__content__text">
            {props.asHtml ? <p dangerouslySetInnerHTML={{ __html: props.content }} /> : <p>{props.content}</p>}
          </div>
        </div>
        <div className="modal__window__controls">
          {props.controls.map((control) => (
            <button key={control.label} className="btn" onClick={control.onClick} autoFocus={control.autoFocus}>
              {control.label}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};
