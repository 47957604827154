import { Layer } from '../../../models/composition';
import { Image } from '../../../models/resources';
import { ColorsService } from '../../../services/ColorsService';
import { UtilsService } from '../../../services/UtilsService';
import { getActiveComposition } from '../../helpers';
import { addImageAC, addLayerAC, removeImageAC } from '../../reducers/projectReducer';
import { projectSelector } from '../../selectors/projectSelector';
import { ConflictItemType, ResourceThunksInterface } from './ResourceThunks.interface';
import { ResourceUtils } from './ResourceUtils';

export const ImageResourceThunks: ResourceThunksInterface = {
  import: () => {
    return async (dispatch, getState) => {
      const state = getState();
      const project = projectSelector(state);

      const pickerOpts = {
        types: [
          {
            description: 'Images',
            accept: {
              'image/type': ['.png', '.jpeg', '.jpg'],
            },
          },
        ],
        excludeAcceptAllOption: true,
        multiple: false,
      };

      const loaded = await ResourceUtils.loadFile(pickerOpts);
      const newImage: Image = {
        id: project.resources.lastImageId + 1,
        name: loaded.file.name,
        type: loaded.file.type,
        lastModified: loaded.file.lastModified,
        size: loaded.file.size,
        content: loaded.content,
      };
      dispatch(addImageAC(newImage));
    };
  },

  toLayer: (id: number) => {
    return async (dispatch, getState) => {
      const state = getState();
      const project = projectSelector(state);
      const composition = getActiveComposition(project);

      const image = project.resources.images.find((img) => img.id === id);
      if (!image) return;
      const imgSize = await UtilsService.getImageSize(image.content);
      const newLayer: Layer = {
        id: composition.lastLayerId + 1,
        name: 'New image layer',
        startFrame: 0,
        duration: composition.workspaceDuration,
        color: ColorsService.getElementColorId('IMAGE'),
        visible: true,
        locked: false,
        guide: false,
        element: {
          type: 'IMAGE',
          dynamicId: '',
          imageId: id,
        },
        properties: {
          width: {
            value: imgSize.width,
            keyframes: [],
          },
          height: {
            value: imgSize.height,
            keyframes: [],
          },
        },
        openPropertyGroups: [],
        open: false,
      };
      dispatch(addLayerAC(newLayer));
    };
  },

  newLayer: () => {
    return async (dispatch, getState) => {
      const state = getState();
      const project = projectSelector(state);

      await Promise.all([dispatch(ImageResourceThunks.import())]);
      dispatch(ImageResourceThunks.toLayer(project.resources.lastImageId + 1));
    };
  },

  remove: (id: number) => {
    return async (dispatch, getState) => {
      const state = getState();
      const project = projectSelector(state);

      const conflictItems: ConflictItemType[] = [];
      for (const composition of project.compositions) {
        for (const layer of composition.layers) {
          if (layer.element.type === 'IMAGE') {
            if (layer.element.imageId === id) {
              conflictItems.push({ compositionId: composition.id, layerId: layer.id, name: layer.name });
            }
          }
        }
      }

      dispatch(ResourceUtils.remove('image', id, conflictItems, removeImageAC));
    };
  },
};
