import { Popup } from '../../common/popup/Popup';
import packageJson from '../../../../package.json';
import logoSymbol from '../../../img/logos/logo-dark-bg.png';

export const AboutPopup = () => {
  return (
    <Popup id="about" title="About Loopic">
      <img src={logoSymbol} alt="Loopic logo" />
      <div className="version">Version {packageJson.version}</div>
      <ul>
        <li>
          More information about product:{' '}
          <a rel="noreferrer" href="https://wwww.loopic.io/" target="_blank">
            www.loopic.io
          </a>
        </li>
        <li>
          Documentation:
          <a rel="noreferrer" href="https://wwww.loopic.io/docs" target="_blank">
            www.loopic.io/docs
          </a>
        </li>
        <li>
          Contact: <a href="mailto:loopicapp@gmail.com">loopicapp@gmail.com</a>
        </li>
      </ul>
    </Popup>
  );
};
