import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type NotificationsStoreType = (Notification & { id: number })[];

export type Notification = {
  message: string;
  type: 'success' | 'error';
};

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState: [] as NotificationsStoreType,
  reducers: {
    setNotificationAC: (state, { payload }: PayloadAction<Notification>) => {
      state.push({ id: Date.now(), ...payload });
    },
    removeNotificationAC: (state, { payload }: PayloadAction<number>) => {
      return state.filter((notif) => notif.id !== payload);
    },
  },
});

export const { setNotificationAC, removeNotificationAC } = notificationsSlice.actions;
export const notificationsReducer = notificationsSlice.reducer;
