import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ControlsHeader } from './controls/header/ControlsHeader';
import { DragDropContext, Droppable, DropResult, ResponderProvided } from 'react-beautiful-dnd';
import { setLayerIndexAC } from '../../redux/reducers/projectReducer';
import { LayerContextMenu } from '../context-menu/LayerContextMenu';
import { Resizer } from '../common/resizer/Resizer';
import { allLayerIdsSelector } from '../../redux/selectors/layersSelector';
import { LCLayerWrapper } from './controls/layer/LCLayerWrapper';
import { BottomControls } from './bottom-controls/BottomControls';
import { TimelineFrameSelector } from './timeline/frame-selector/TimelineFrameSelector';
import { TimelineLayer } from './timeline/layer/TimelineLayer';
import { ScrollSync, ScrollSyncPane } from 'react-scroll-sync';
import {
  compDurationSelector,
  compIdSelector,
  compPanelHeightSelector,
  compScaleSelector,
} from '../../redux/selectors/compositionSelector';

export const CompositionPanel = () => {
  const layerIds = useSelector(allLayerIdsSelector);
  const compId = useSelector(compIdSelector);
  const compPanelHeight = useSelector(compPanelHeightSelector);
  const compScale = useSelector(compScaleSelector);
  const compDuration = useSelector(compDurationSelector);

  const tfsRef = useRef<HTMLDivElement>(null);
  const timelineRef = useRef<HTMLDivElement>(null);

  const [mouseOverTimeline, setMouseOverTimeline] = useState(false);
  const [mouseOverControls, setMouseOverControls] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {}, [compId]);

  if (!layerIds || !compId) return <div className="composition-panel" />;

  const handleDragEnd = (result: DropResult, provided: ResponderProvided) => {
    if (result.destination) {
      dispatch(
        setLayerIndexAC({
          layerId: parseInt(result.draggableId),
          destinationIndex: result.destination?.index,
          sourceIndex: result.source.index,
        }),
      );
    }
  };

  const handleTimelineScroll = (e: any) => {
    if (!mouseOverTimeline) return;
    const scrollTop = (e.target as any).scrollTop;
    const element = document.querySelector('.layer-controls');
    if (element) {
      element.scrollTop = scrollTop;
    }
  };

  const handleLayerControlsScroll = (e: any) => {
    if (!mouseOverControls) return;
    const scrollTop = (e.target as any).scrollTop;
    if (timelineRef.current) {
      timelineRef.current.scrollTop = scrollTop;
    }
  };

  return (
    <div className="composition-panel" key={`${compId}`} style={{ height: compPanelHeight }}>
      <Resizer />

      <div className="controls-and-timeline-wrapper">
        <div className="controls-wrapper">
          <ControlsHeader />
          {/* Layer controls - left side */}
          <DragDropContext onDragStart={handleDragEnd} onDragEnd={handleDragEnd}>
            <Droppable droppableId={'LAYERS-DROPPABLE-ID'}>
              {(provided) => (
                <div
                  className="layer-controls"
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  onMouseEnter={() => setMouseOverControls(true)}
                  onMouseLeave={() => setMouseOverControls(false)}
                  onFocus={() => setMouseOverControls(true)}
                  onBlur={() => setMouseOverControls(false)}
                  onScroll={(e) => {
                    handleLayerControlsScroll(e);
                    setTimeout(() => {
                      handleLayerControlsScroll(e);
                    }, 50);
                  }}
                >
                  {layerIds.map((layerId, idx) => {
                    return <LCLayerWrapper key={layerId} layerId={layerId} index={idx} />;
                  })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
        <ScrollSync>
          <div className="timeline-wrapper">
            <ScrollSyncPane>
              <TimelineFrameSelector tfsRef={tfsRef} timelineRef={timelineRef} />
            </ScrollSyncPane>
            <ScrollSyncPane>
              <div
                className="timeline-layers"
                ref={timelineRef}
                onMouseEnter={() => setMouseOverTimeline(true)}
                onMouseLeave={() => setMouseOverTimeline(false)}
                onFocus={() => setMouseOverTimeline(true)}
                onBlur={() => setMouseOverTimeline(false)}
                onScroll={(e) => {
                  handleTimelineScroll(e);
                  setTimeout(() => {
                    handleTimelineScroll(e);
                  }, 50);
                }}
              >
                {/* Ghost layer exists only to allow scrolling while there are no other layers */}
                {layerIds.length === 0 ? (
                  <div className="ghost-layer" style={{ minWidth: compScale * compDuration, height: 10 }}></div>
                ) : (
                  layerIds.map((layerId, idx) => {
                    return <TimelineLayer key={layerId} layerId={layerId} />;
                  })
                )}
              </div>
            </ScrollSyncPane>
          </div>
        </ScrollSync>
      </div>

      <LayerContextMenu />
      <BottomControls />
    </div>
  );
};
