import { RefObject } from 'react';
import { useSelector } from 'react-redux';
import { AppState } from '../../../redux/AppState';
import { projectSelector } from '../../../redux/selectors/projectSelector';
import { RenderLayers } from '../RenderLayers';

type PropsType = {
  id: number;
  compositionId: number;
  styles: any;
  globalFrameOffset: number;
  elementRef: RefObject<HTMLDivElement>;
};

export const CompositionRenderElement = (props: PropsType) => {
  const composition = useSelector(projectSelector).compositions.find((comp) => comp.id === props.compositionId);

  if (!composition) return <></>;

  return (
    <div className={`element element-${props.id} composition composition-${composition.id}`} style={props.styles} ref={props.elementRef}>
      <RenderLayers layers={composition.layers} nested={true} globalFrameOffset={props.globalFrameOffset} />
    </div>
  );
};
