import { Dropdown } from '../common/dropdown/Dropdown';
import { PropertiesSection } from '../common/properties/PropertiesSection';
import { PropertyId } from '../../services/PropertySettingsService';

import { useDispatch } from 'react-redux';
import { InputWrapper } from '../common/inputs/InputWrapper';
import { setImageLoaderPlaceholderIdThunk, setImageLoaderSizeThunk } from '../../redux/thunks/layersThunk';
import { ImageLoaderSize } from '../../models/elements';

type PropsType = {
  onSetPropertyValue: (propertyId: PropertyId, value: any) => void;
  onGetPropertyValue: (propertyId: PropertyId) => string | number | undefined;
  placeholderId: number;
  size: ImageLoaderSize;
};

export const ImageLoaderPropertiesSection = (props: PropsType) => {
  const dispatch = useDispatch();

  const placeholders = [
    { id: 0, value: 'None' },
    { id: 1, value: 'Demo 1' },
    { id: 2, value: 'Demo 2' },
  ];

  const imageSizes = [
    { id: 'contain', value: 'Contain' },
    { id: 'fit-width', value: 'Fit width' },
    { id: 'fit-height', value: 'Fit height' },
    { id: 'original', value: 'Original' },
    { id: 'stretch', value: 'Stretch' },
  ];

  return (
    <PropertiesSection label="Image loader properties">
      <div className="properties-row">
        <InputWrapper label="Image size" name="image-size">
          <Dropdown
            allItems={imageSizes}
            hideSearch={true}
            dropdownNo={3}
            selectedItemId={props.size}
            noneItem={{ id: 0, value: 'None' }}
            onClick={(id: string | number | undefined) => {
              dispatch(setImageLoaderSizeThunk(id as ImageLoaderSize));
            }}
            placeholder="Search fonts"
          />
        </InputWrapper>
      </div>
      <div className="properties-row">
        <InputWrapper label="Placeholder image" name="placeholder-image">
          <Dropdown
            allItems={placeholders}
            hideSearch={true}
            dropdownNo={3}
            selectedItemId={props.placeholderId}
            noneItem={{ id: 0, value: 'None' }}
            onClick={(id: string | number | undefined) => {
              dispatch(setImageLoaderPlaceholderIdThunk(id as number));
            }}
            placeholder="Search fonts"
          />
        </InputWrapper>
      </div>
    </PropertiesSection>
  );
};
