import { Popup } from '../common/popup/Popup';
import loginIllustration from '../../img/illustrations/login.svg';
import { InputWrapper } from '../common/inputs/InputWrapper';
import { SmartBtn } from '../common/inputs/SmartBtn';
import { Inforow } from '../common/inputs/Inforow';
import { AuthLayout } from '../common/layouts/auth-layout/AuthLayout';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { useState } from 'react';
import { setPopupAC } from '../../redux/reducers/appReducer';
import { AuthApi } from '../../services/api/AuthApi';
import { AuthService } from '../../services/AuthService';
import { useHistory } from 'react-router';
import { SITEMAP } from '../../config/SITEMAP';
import { Link } from 'react-router-dom';
import logoSymbol from '../../img/logos/logo-symbol.png';

type PropsType = {
  disableClose?: boolean;
};

export const LoginPopup = (props: PropsType) => {
  const dispatch = useDispatch();
  const [formError, setFormError] = useState('');
  const history = useHistory();

  return (
    <Popup id="login" title="Login" disableClose={props.disableClose} onClose={() => history.push(SITEMAP.STUDIO)}>
      <AuthLayout
        rightChildren={
          <>
            <img src={logoSymbol} alt="Login" />
            <div>
              <Link to={SITEMAP.REGISTER}>Create account.</Link>
            </div>
            <div>
              <Link to={SITEMAP.FORGOT_PASSWORD}>Forgot password?</Link>
            </div>
          </>
        }
        leftChildren={
          <Formik
            initialValues={{
              email: '',
              password: '',
            }}
            validationSchema={Yup.object({
              email: Yup.string().email('Email must be valid').required('Email is required'),
              password: Yup.string().required('Password is required'),
            })}
            onSubmit={async (values, { setSubmitting }) => {
              try {
                const res = await AuthApi.login({
                  email: values.email,
                  password: values.password,
                });
                AuthService.login(res.data.access_token, res.data.user);
                dispatch(setPopupAC({ popupId: 'login', open: false }));
                setSubmitting(false);
              } catch (error) {
                setSubmitting(false);
                if (error.statusCode === 400) {
                  setFormError('Email or password are invalid');
                  // Actually password is probably not valid
                } else if (error.statusCode === 401) {
                  setFormError('Email or password are invalid');
                } else {
                  console.log(error);
                  setFormError('An error occured');
                }
              }
            }}
          >
            {({ isSubmitting, errors, isValid, touched }) => (
              <Form className="human-form auth">
                <h1>
                  <b>Login</b> to Loopic!
                </h1>
                <InputWrapper label="Email" labelPosition="top" name="email" error={touched.email && errors.email}>
                  <Field
                    type="text"
                    name="email"
                    autoComplete="username"
                    className={classNames({ classic: true, error: touched.email && errors.email })}
                  />
                </InputWrapper>
                <InputWrapper label="Password" labelPosition="top" name="password" error={touched.password && errors.password}>
                  <Field
                    type="password"
                    name="password"
                    autoComplete="current-password"
                    className={classNames({ classic: true, error: touched.password && errors.password })}
                  />
                </InputWrapper>
                {formError && <Inforow type="error" message={formError} />}
                <SmartBtn className="submit" label="Login" loading={isSubmitting} />
              </Form>
            )}
          </Formik>
        }
      />
    </Popup>
  );
};
