import BezierEasing from 'bezier-easing';
import { Layer, PropertyData } from '../models/composition';
import { PropertyId, PropertySettingsService } from './PropertySettingsService';

export const calculatePropertyKeyframes = (layer: Layer, propertyId: PropertyId, property: PropertyData) => {
  return calculateOptionKeyframes(layer, propertyId, property)?.calculatedKeyframes;
};

export const calculateOptionKeyframes = (layer: Layer, propertyId: PropertyId, option: PropertyData) => {
  const calculatedOption: PropertyData = { value: option.value, keyframes: [], calculatedKeyframes: [] };
  calculatedOption.calculatedKeyframes = [];

  let indexCounter = 0;

  let foundOptionType = PropertySettingsService.getSettings(propertyId);

  if (!foundOptionType) {
    console.error('Cannot find settings for option ' + propertyId);
    return;
  }

  if (option.keyframes.length === 0) {
    /**
     * If there are no keyframes, make all frames have option value
     */
    for (let i = 0; i < layer.duration; i++) {
      calculatedOption.calculatedKeyframes.push({ id: i, frame: i, value: option.value });
    }
  } else {
    /**
     * If there are keyframes, calculate them
     */

    for (const keyframe of option.keyframes) {
      const currentKeyframe = keyframe;

      if (indexCounter === 0 && option.keyframes.length === 1) {
        // One and only keyframe
        for (let i = 0; i < layer.duration; i++) {
          calculatedOption.calculatedKeyframes.push({ id: i, frame: i, value: currentKeyframe.value });
        }
      } else if (indexCounter === option.keyframes.length - 1) {
        // Last keyframe (not last and first one!)
        /**
         * Iterate for all frames the same value
         */
        for (let i = currentKeyframe.frame; i < layer.duration; i++) {
          calculatedOption.calculatedKeyframes.push({ id: i, frame: i, value: currentKeyframe.value });
        }
      } else {
        // Not last keyframe
        const nextKeyframe = option.keyframes[indexCounter + 1];
        const framesDuration = nextKeyframe.frame - currentKeyframe.frame;

        /**
         * If the first keyframe and keyframe is not on frame 0, everything before should be the same as the first keyframe
         */
        if (indexCounter === 0) {
          for (let i = 0; i < currentKeyframe.frame; i++) {
            calculatedOption.calculatedKeyframes.push({ id: i, frame: i, value: currentKeyframe.value });
          }
        }

        /**
         * Get values, parse values
         */
        let currentValue = 0;
        let nextValue = 0;

        if (typeof currentKeyframe.value === 'string') {
          currentValue = parseFloat(currentKeyframe.value.substr(0, currentKeyframe.value.length - 2));
        } else {
          currentValue = currentKeyframe.value;
        }

        if (typeof nextKeyframe.value === 'string') {
          nextValue = parseFloat(nextKeyframe.value.substr(0, nextKeyframe.value.length - 2));
        } else {
          nextValue = nextKeyframe.value;
        }

        /**
         * Calculate increments
         */
        //* This now depends on the easing function
        let easing = BezierEasing(
          currentKeyframe.easing.p1x,
          currentKeyframe.easing.p1y,
          currentKeyframe.easing.p2x,
          currentKeyframe.easing.p2y,
        );

        // Used for old, linear way
        // const increment = (nextValue - currentValue) / framesDuration;

        /**
         * Iterate for all frames - increment
         */
        for (let i = currentKeyframe.frame; i < nextKeyframe.frame; i++) {
          // Old, linear way
          // const incrementIndex = i - currentKeyframe.frame;
          // let val = currentValue + incrementIndex * increment;

          let val = 0;
          if (i === currentKeyframe.frame) {
            val = currentValue;
          } else {
            const incrementIndex = i - currentKeyframe.frame;
            const percentage = incrementIndex / framesDuration;
            const easingPercentage = easing(percentage);
            val = currentValue + (nextValue - currentValue) * easingPercentage;
          }

          calculatedOption.calculatedKeyframes.push({
            id: i,
            frame: i,
            value: 'decimals' in foundOptionType ? +val.toFixed(foundOptionType.decimals) : val,
          });
        }
      }

      ++indexCounter;
    }
  }

  return calculatedOption;
};

export const calculateLayerOptionsKeyframes = (layer: Layer) => {
  const calculatedOptions: PropertyData[] = [];

  for (const propertyId in layer.properties) {
    const calculatedOption = calculateOptionKeyframes(layer, propertyId as PropertyId, layer.properties[propertyId as PropertyId]!);
    if (calculatedOption) calculatedOptions.push(calculatedOption);
  }

  return calculatedOptions;
};
