import { getActiveComposition } from '../helpers';
import { PropertyHelper } from '../helpers/PropertyHelper';
import { projectSelector } from '../selectors/projectSelector';
import { setPropertyValueThunk } from './layersThunk';
import { MyThunkActionReturn } from './thunkTypes';

export const alignHorizontallyLeftThunk = (): MyThunkActionReturn => {
  return (dispatch, getState) => {
    const composition = getActiveComposition(projectSelector(getState()));
    const selectedLayers = composition.selectedLayers;
    if (selectedLayers.length < 2) return;
    const originLayer = composition.layers.find((layer) => layer.id === selectedLayers[0]);
    if (!originLayer) return;

    const originX = PropertyHelper.getPropertyValue(originLayer.properties, 'x', composition.activeFrame)!;
    for (let i = 1; i < selectedLayers.length; i++) {
      dispatch(setPropertyValueThunk(selectedLayers[i], [{ propertyId: 'x', value: originX }], undefined, true, false));
    }
  };
};

export const alignHorizontallyCenterThunk = (): MyThunkActionReturn => {
  return (dispatch, getState) => {
    const composition = getActiveComposition(projectSelector(getState()));
    const selectedLayers = composition.selectedLayers;
    if (selectedLayers.length < 2) return;
    const originLayer = composition.layers.find((layer) => layer.id === selectedLayers[0]);
    if (!originLayer) return;

    const originX = PropertyHelper.getPropertyValue(originLayer.properties, 'x', composition.activeFrame)! as number;
    const originWidth = PropertyHelper.getPropertyValue(originLayer.properties, 'width', composition.activeFrame)! as number;

    for (let i = 1; i < selectedLayers.length; i++) {
      const targetLayer = composition.layers.find((layer) => layer.id === selectedLayers[i]);
      if (!targetLayer) return;
      const targetWidth = PropertyHelper.getPropertyValue(targetLayer.properties, 'width', composition.activeFrame)! as number;
      dispatch(
        setPropertyValueThunk(
          selectedLayers[i],
          [{ propertyId: 'x', value: originX + originWidth / 2 - targetWidth / 2 }],
          undefined,
          true,
          false,
        ),
      );
    }
  };
};

export const alignHorizontallyRightThunk = (): MyThunkActionReturn => {
  return (dispatch, getState) => {
    const composition = getActiveComposition(projectSelector(getState()));
    const selectedLayers = composition.selectedLayers;
    if (selectedLayers.length < 2) return;
    const originLayer = composition.layers.find((layer) => layer.id === selectedLayers[0]);
    if (!originLayer) return;

    const originX = PropertyHelper.getPropertyValue(originLayer.properties, 'x', composition.activeFrame)! as number;
    const originWidth = PropertyHelper.getPropertyValue(originLayer.properties, 'width', composition.activeFrame)! as number;

    for (let i = 1; i < selectedLayers.length; i++) {
      const targetLayer = composition.layers.find((layer) => layer.id === selectedLayers[i]);
      if (!targetLayer) return;
      const targetWidth = PropertyHelper.getPropertyValue(targetLayer.properties, 'width', composition.activeFrame)! as number;
      dispatch(
        setPropertyValueThunk(targetLayer.id, [{ propertyId: 'x', value: originX + originWidth - targetWidth }], undefined, true, false),
      );
    }
  };
};

export const alignVerticallyTopThunk = (): MyThunkActionReturn => {
  return (dispatch, getState) => {
    const composition = getActiveComposition(projectSelector(getState()));
    const selectedLayers = composition.selectedLayers;
    if (selectedLayers.length < 2) return;
    const originLayer = composition.layers.find((layer) => layer.id === selectedLayers[0]);
    if (!originLayer) return;

    const originY = PropertyHelper.getPropertyValue(originLayer.properties, 'y', composition.activeFrame)!;

    for (let i = 1; i < selectedLayers.length; i++) {
      dispatch(setPropertyValueThunk(selectedLayers[i], [{ propertyId: 'y', value: originY }], undefined, true, false));
    }
  };
};

export const alignVerticallyCenterThunk = (): MyThunkActionReturn => {
  return (dispatch, getState) => {
    const composition = getActiveComposition(projectSelector(getState()));
    const selectedLayers = composition.selectedLayers;
    if (selectedLayers.length < 2) return;
    const originLayer = composition.layers.find((layer) => layer.id === selectedLayers[0]);
    if (!originLayer) return;

    const originY = PropertyHelper.getPropertyValue(originLayer.properties, 'y', composition.activeFrame)! as number;
    const originHeight = PropertyHelper.getPropertyValue(originLayer.properties, 'height', composition.activeFrame)! as number;

    for (let i = 1; i < selectedLayers.length; i++) {
      const targetLayer = composition.layers.find((layer) => layer.id === selectedLayers[i]);
      if (!targetLayer) return;
      const targetHeight = PropertyHelper.getPropertyValue(targetLayer.properties, 'height', composition.activeFrame)! as number;
      dispatch(
        setPropertyValueThunk(
          selectedLayers[i],
          [{ propertyId: 'y', value: originY + originHeight / 2 - targetHeight / 2 }],
          undefined,
          true,
          false,
        ),
      );
    }
  };
};

export const alignVerticallyBottomThunk = (): MyThunkActionReturn => {
  return (dispatch, getState) => {
    const composition = getActiveComposition(projectSelector(getState()));
    const selectedLayers = composition.selectedLayers;
    if (selectedLayers.length < 2) return;
    const originLayer = composition.layers.find((layer) => layer.id === selectedLayers[0]);
    if (!originLayer) return;

    const originY = PropertyHelper.getPropertyValue(originLayer.properties, 'y', composition.activeFrame)! as number;
    const originHeight = PropertyHelper.getPropertyValue(originLayer.properties, 'height', composition.activeFrame)! as number;

    for (let i = 1; i < selectedLayers.length; i++) {
      const targetLayer = composition.layers.find((layer) => layer.id === selectedLayers[i]);
      if (!targetLayer) return;
      const targetHeight = PropertyHelper.getPropertyValue(targetLayer.properties, 'height', composition.activeFrame)! as number;
      dispatch(
        setPropertyValueThunk(
          selectedLayers[i],
          [{ propertyId: 'y', value: originY + originHeight - targetHeight }],
          undefined,
          true,
          false,
        ),
      );
    }
  };
};
