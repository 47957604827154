import { ButtonHTMLAttributes, DetailedHTMLProps } from 'react';
import minusIcon from '../../../img/icons/minus.svg';

export const BtnMinus = ({ className, ...rest }: DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>) => {
  return (
    <button className={`btn minus ${className}`} {...rest}>
      <img className="icon" src={minusIcon} alt="Minus" />
    </button>
  );
};
