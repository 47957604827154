import { MouseEventHandler, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../redux/AppState';
import { setCompositionPanelHeightAC } from '../../../redux/reducers/projectReducer';
import { compPanelHeightSelector } from '../../../redux/selectors/compositionSelector';

export const Resizer = () => {
  const cpHeight = useSelector(compPanelHeightSelector);
  const dispatch = useDispatch();

  const startDragPosRef = useRef(0);
  const originalHeightRef = useRef(0);

  const mouseMoveCb = useCallback((e: MouseEvent) => {
    const diff = startDragPosRef.current - e.clientY;
    dispatch(setCompositionPanelHeightAC(originalHeightRef.current + diff));
  }, []);

  const handleMouseDown: MouseEventHandler<HTMLDivElement> = (e) => {
    startDragPosRef.current = e.clientY;
    originalHeightRef.current = cpHeight;
    document.addEventListener('mousemove', mouseMoveCb);
  };

  const handleMouseUp: MouseEventHandler<HTMLDivElement> = (e) => {
    document.removeEventListener('mousemove', mouseMoveCb);
  };

  return <div className="resizer horizontal" onMouseDown={handleMouseDown} onMouseUp={handleMouseUp} />;
};
