import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { setPopupAC } from '../../redux/reducers/appReducer';
import { setCompositionBackgroundAC, setCompositionNameAC, setCompositionSettingsAC } from '../../redux/reducers/projectReducer';
import { compSelector } from '../../redux/selectors/compositionSelector';
import { projectSelector } from '../../redux/selectors/projectSelector';
import { selectLayerThunk } from '../../redux/thunks/layersThunk';
import { InputColor } from '../common/inputs/InputColor';
import { InputFormikField } from '../common/inputs/InputFormikField';
import { InputWrapper } from '../common/inputs/InputWrapper';
import { PanelLayout } from '../common/panel-layout/PanelLayout';
import { PropertiesSection } from '../common/properties/PropertiesSection';

export const CompositionSettingsPanel = () => {
  const project = useSelector(projectSelector);
  const composition = useSelector(compSelector);
  const dispatch = useDispatch();

  if (!composition.width) return <></>;

  return (
    <PanelLayout id="composition-settings" title="Composition settings">
      <Formik
        initialValues={{
          name: composition.name,
          width: composition.width,
          height: composition.height,
          fps: composition.fps,
          duration: composition.duration,
        }}
        enableReinitialize={true}
        onSubmit={(values) => {
          dispatch(setCompositionNameAC(values.name));
          dispatch(
            setCompositionSettingsAC({
              width: parseInt(values.width as any),
              height: parseInt(values.height as any),
              fps: parseInt(values.fps as any),
              duration: parseInt(values.duration as any),
            }),
          );
        }}
      >
        {({ values, submitForm, handleSubmit }) => {
          return (
            <Form>
              <InputFormikField name="name" type="text" label="Name" />
              <InputFormikField name="width" type="text" label="Width" />
              <InputFormikField name="height" type="text" label="Height" />
              <InputFormikField name="fps" type="text" label="FPS" />
              <InputFormikField name="duration" type="text" label="Duration" />
            </Form>
          );
        }}
      </Formik>
      <InputColor
        label="Background"
        name="color"
        value={composition.background}
        onChange={(color) => dispatch(setCompositionBackgroundAC({ background: color }))}
      />

      <InputWrapper label="Composition action" name="composition-action">
        <button className="btn" onClick={() => dispatch(setPopupAC({ popupId: 'compositionActionEditor', open: true }))}>
          Edit
        </button>
      </InputWrapper>

      <PropertiesSection label="Composition Dynamic IDs">
        <table className="mini-table">
          <thead>
            <tr>
              <th>Dynamic ID</th>
              <th>Content</th>
            </tr>
          </thead>
          <tbody>
            {composition.layers.map((layer) => {
              if (layer.element.type === 'TEXT') {
                if (layer.element.dynamicId === '') return;
                return (
                  <tr key={layer.id} onClick={() => dispatch(selectLayerThunk(layer.id))}>
                    <td>{layer.element.dynamicId}</td>
                    <td>{layer.element.content}</td>
                  </tr>
                );
              } else if (layer.element.type === 'IMAGE_LOADER') {
                return (
                  <tr key={layer.id}>
                    <td>{layer.element.dynamicId}</td>
                  </tr>
                );
              }
            })}
          </tbody>
        </table>
      </PropertiesSection>
    </PanelLayout>
  );
};
