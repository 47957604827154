import { Item, ItemParams, Menu } from 'react-contexify';
import 'react-contexify/dist/ReactContexify.css';
import { useDispatch } from 'react-redux';
import { duplicateCompositionThunk, removeCompositionThunk } from '../../redux/thunks/compositionThunk';
import { newCompositionLayerThunk } from '../../redux/thunks/layers/compositionThunk';

export const COMPOSITION_CONTEXT_MENU_ID = 'COMPOSITION-CONTEXT-MENU-ID';

export const CompositionContextMenu = () => {
  const dispatch = useDispatch();

  const handleImportAsLayer = ({ props }: ItemParams<{ id: number }>) => {
    if (!props || !props.id) return;
    dispatch(newCompositionLayerThunk(props.id));
  };

  const handleDelete = ({ props }: ItemParams<{ id: number }>) => {
    if (!props || !props.id) return;
    dispatch(removeCompositionThunk(props.id));
  };

  const handleDuplicate = ({ props }: ItemParams<{ id: number }>) => {
    if (!props || !props.id) return;
    dispatch(duplicateCompositionThunk(props.id));
  };

  return (
    <Menu id={COMPOSITION_CONTEXT_MENU_ID}>
      <Item onClick={handleImportAsLayer}>Import as layer</Item>
      <Item onClick={handleDuplicate}>Duplicate composition</Item>
      <Item onClick={handleDelete}>Delete composition</Item>
    </Menu>
  );
};
