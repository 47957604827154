import { Popup } from '../../common/popup/Popup';
import { KeyboardShortcutsList } from '../../KeyboardShortcuts';

export const KeyboardShortcutsPopup = () => {
  return (
    <Popup id="keyboardShortcuts" title="Keyboard Shortcuts">
      <table className="classic-table">
        <thead>
          <tr>
            <th>Key combination</th>
            <th>Function</th>
          </tr>
        </thead>
        <tbody>
          {KeyboardShortcutsList.map((c, i) => {
            return (
              <tr key={i}>
                <td>{c.combination}</td>
                <td>{c.function}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </Popup>
  );
};
