import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import { PopupPageLayout } from '../components/common/layouts/popup-page-layout/PopupPageLayout';
import { ResetPasswordPopup } from '../components/popups/ResetPasswordPopup';
import { SeoService } from '../services/SeoService';

export const ResetPasswordPage = () => {
  const { userId, token } = useParams<{ userId: string; token: string }>();
  return (
    <PopupPageLayout solidBackground>
      <Helmet>
        <title>{SeoService.getPageTitle('Reset password')}</title>
      </Helmet>
      <ResetPasswordPopup userId={userId} token={token} />
    </PopupPageLayout>
  );
};
