import { Helmet } from 'react-helmet';
import { ForgotPasswordPopup } from '../components/popups/ForgotPasswordPopup';
import { LOOPIC_CONFIG } from '../config/LOOPIC_CONFIG';
import { SeoService } from '../services/SeoService';

export const ForgotPasswordPage = () => (
  <div>
    <Helmet>
      <title>{SeoService.getPageTitle('Forgot password')}</title>
    </Helmet>
    <ForgotPasswordPopup disableClose={!LOOPIC_CONFIG.allowAccessWithoutRegistration} />
  </div>
);
