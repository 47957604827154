import { RefObject } from 'react';
import { Layer } from '../../models/composition';
import { RendererService } from '../../services/RendererService';
import { RenderLayer } from './RenderLayer';

type PropsType = {
  layers: Layer[];
  nested: boolean;
  globalFrameOffset: number;
  zoom?: number;
  renderSectionRef?: RefObject<HTMLDivElement>;
};

export const RenderLayers = (props: PropsType) => {
  return (
    <div className="render-layers">
      {props.layers
        ?.slice(0)
        .reverse()
        .map((layer) => {
          if (!layer.visible) return;
          return (
            <RenderLayer
              key={`render-layer-${layer.id}`}
              renderSectionRef={props.renderSectionRef}
              layer={layer}
              styles={RendererService.getCSSStylesheet(layer)}
              zoom={props.zoom}
              nested={props.nested}
              globalFrameOffset={props.globalFrameOffset}
            />
          );
        })}
    </div>
  );
};
