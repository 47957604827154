import { useSelector } from 'react-redux';
import { compSelector } from '../../redux/selectors/compositionSelector';
import { hasManySelectedLayersSelector } from '../../redux/selectors/layersSelector';
import { AlignmentPanel } from '../alignment-panel/AlignmentPanel';
import { CasparCGPanel } from '../casparcg-panel/CasparCGPanel';
import { SettingsPanel } from '../settings-panel/SettingsPanel';

export const RightSidebar = () => {
  const composition = useSelector(compSelector);
  const manySelected = useSelector(hasManySelectedLayersSelector);

  if (!composition || !composition.layers) return <></>;

  return (
    <div className="right-sidebar no-deselect-layer">
      <SettingsPanel />
      {manySelected && <AlignmentPanel />}
      <CasparCGPanel />
    </div>
  );
};
