import { RefObject } from 'react';

type PropsType = {
  id: number;
  styles: any;
  elementRef: RefObject<HTMLDivElement>;
};

export const ShapeRenderElement = (props: PropsType) => {
  return <div className={`element  element-${props.id} shape`} style={props.styles} ref={props.elementRef}></div>;
};
