import { Dispatch } from '@reduxjs/toolkit';
import opentype from 'opentype.js';
import { Font } from '../models/resources';
import { setModalAC } from '../redux/reducers/appReducer';
import { addFontAC } from '../redux/reducers/projectReducer';

export class FontService {
  static websafeFonts = [
    { id: 'Arial', value: 'Arial', highlighted: false },
    { id: 'Verdana', value: 'Verdana' },
    { id: 'Helvetica', value: 'Helvetica' },
    { id: 'Tahoma', value: 'Tahoma' },
    { id: 'Trebuchet MS', value: 'Trebuchet MS' },
    { id: 'Times New Roman', value: 'Times New Roman' },
    { id: 'Georgia', value: 'Georgia' },
    { id: 'Garamond', value: 'Garamond' },
    { id: 'Courier New', value: 'Courier New' },
    { id: 'Brush Script MT', value: 'Brush Script MT' },
  ];

  static arrayBufferToBase64(buffer: ArrayBuffer) {
    var binary = '';
    var bytes = new Uint8Array(buffer);
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  }

  static base64ToArrayBuffer(base64: string) {
    var binary_string = window.atob(base64);
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
  }

  static async handleImportFont(dispatch: Dispatch<any>, projectFonts: Font[]) {
    const options = {
      types: [
        {
          description: 'Fonts',
          accept: {
            'font/*': ['.otf', '.ttf'],
          },
        },
      ],
      excludeAcceptAllOption: true,
      multiple: true,
    };
    const fileHandles = await (window as any).showOpenFilePicker(options);

    /**
     * Import all fonts
     */
    for (const importFileHandle of fileHandles) {
      const singleFile = await importFileHandle.getFile();
      const fr = new FileReader();

      fr.onload = async () => {
        const fontArrayBuffer = fr.result as ArrayBuffer;
        const otFont = opentype.parse(fontArrayBuffer);
        const fontName = otFont.tables.name.fullName.en;

        const idx = projectFonts.findIndex((pf) => pf.name === fontName);
        if (idx > -1) {
          dispatch(
            setModalAC({
              title: 'Duplicated font',
              icon: 'warning',
              content: 'Font with the same name has been found. Please delete the old font.',
              controls: [{ label: 'OK', onClick: () => dispatch(setModalAC(undefined)) }],
            }),
          );
          return;
        }

        await FontService.loadFontFamily(fontName, fontArrayBuffer);
        const abStr = FontService.arrayBufferToBase64(fontArrayBuffer);
        dispatch(addFontAC({ name: fontName, content: abStr }));
      };
      fr.readAsArrayBuffer(singleFile);
    }
  }

  static async loadFontFamily(fontName: string, fontArrayBuffer: ArrayBuffer) {
    const newFontFamily = new FontFace(fontName, fontArrayBuffer);
    return newFontFamily.load().then((loadFace) => {
      document.fonts.add(loadFace);
    });
  }
}
