import { useDispatch, useSelector } from 'react-redux';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-javascript';
import 'ace-builds/src-noconflict/theme-monokai';
import 'ace-builds/src-noconflict/ext-language_tools';

import { AppState } from '../../redux/AppState';
import { Popup } from '../common/popup/Popup';
import { useState } from 'react';
import { setCompositionActionCodeAC } from '../../redux/reducers/projectReducer';
import { setPopupAC } from '../../redux/reducers/appReducer';
import { useEffect } from 'react';
import { compSelector } from '../../redux/selectors/compositionSelector';

export const CompositionActionEditor = () => {
  const composition = useSelector(compSelector);
  const dispatch = useDispatch();
  const [codeContent, setCodeContent] = useState('');
  const [changed, setChanged] = useState(false);

  useEffect(() => {
    setCodeContent(composition.compositionAction);
  }, [composition.compositionAction]);

  const handleOnChange = (value: string) => {
    setCodeContent(value);
    setChanged(true);
  };

  const handleSaveSubmit = () => {
    dispatch(setCompositionActionCodeAC(codeContent));
    setChanged(false);
  };

  const handleSaveAndCloseSubmit = () => {
    dispatch(setCompositionActionCodeAC(codeContent));
    dispatch(setPopupAC({ popupId: 'compositionActionEditor', open: false }));
  };

  return (
    <Popup id="actionEditor" title={`Action editor${changed ? ' - NOT SAVED' : ''}`}>
      <div className="editor">
        <div className="editor__code">
          <AceEditor
            mode="javascript"
            value={codeContent}
            theme="monokai"
            name="action-editor-input"
            onChange={handleOnChange}
            editorProps={{ $blockScrolling: true }}
            setOptions={{
              enableBasicAutocompletion: true,
              enableLiveAutocompletion: true,
              enableSnippets: true,
              showLineNumbers: true,
              tabSize: 2,
              fontSize: 14,
            }}
          />
        </div>
        <div className="editor__controls">
          <button autoFocus className="btn large" onClick={handleSaveSubmit}>
            Save
          </button>
          <button autoFocus className="btn large" onClick={handleSaveAndCloseSubmit}>
            Save and Close
          </button>
        </div>
      </div>
    </Popup>
  );
};
