import classNames from 'classnames';
import React, { useEffect, useState } from 'react';

type PropsType = {
  tabs: { id: string; label: string; content: React.ReactNode }[];
  minHeight?: number;
};

export const TabsLayout = (props: PropsType) => {
  const [activeTabId, setActiveTabId] = useState('');

  useEffect(() => {
    setActiveTabId(props.tabs[0]?.id);
  }, []);

  const foundActiveTab = props.tabs.find((tab) => tab.id === activeTabId);

  return (
    <div className="tabs-layout vertical" style={{ minHeight: props.minHeight }}>
      <div className="tabs-layout__tabs">
        {props.tabs.map((tab) => (
          <div key={tab.id} className={classNames({ tab: true, active: tab.id === activeTabId })} onClick={() => setActiveTabId(tab.id)}>
            {tab.label}
          </div>
        ))}
      </div>
      <div className="tabs-layout__content">{foundActiveTab?.content}</div>
    </div>
  );
};
