import classNames from 'classnames';
import { InputErrorWrapper } from './InputErrorWrapper';

type PropsType = {
  name: string;
  labelPosition?: 'left' | 'top';
  label: string;
  icon?: string;
  error?: string | false;
  children: React.ReactNode;
};

export const InputWrapper = (props: PropsType) => {
  return (
    <div
      className={classNames({
        'input-wrapper': true,
        'label-left': props.labelPosition === 'left' || props.labelPosition === undefined,
        'label-top': props.labelPosition === 'top',
      })}
    >
      <label className="label" htmlFor={props.name}>
        {props.label}
      </label>
      {props.icon && <img className="icon" src={props.icon} alt="Icon" />}
      <div className="control">
        <InputErrorWrapper error={props.error}>{props.children}</InputErrorWrapper>
      </div>
    </div>
  );
};
