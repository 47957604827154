import * as Yup from 'yup';
import { Field, Form, Formik } from 'formik';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { AppState } from '../../redux/AppState';
import { Inforow } from '../common/inputs/Inforow';
import { InputWrapper } from '../common/inputs/InputWrapper';
import { SmartBtn } from '../common/inputs/SmartBtn';
import classNames from 'classnames';
import { TopContentBottomControls } from '../common/layouts/top-content-bottom-controls/TopContentBottomControls';
import { validatePassword } from '../../services/validators/PasswordValidator';
import { UsersApi } from '../../services/api/UsersApi';
import { setNotificationAC } from '../../redux/reducers/notificationReducer';
import { useDispatch } from 'react-redux';

export const PasswordForm = () => {
  const [formError, setFormError] = useState('');
  const user = useSelector((state: AppState) => state.user);
  const dispatch = useDispatch();

  return (
    <Formik
      initialValues={{
        oldPassword: '',
        password: '',
        passwordConfirmation: '',
      }}
      validationSchema={Yup.object({
        oldPassword: Yup.string().required('Old password is required'),
      })}
      validate={validatePassword}
      onSubmit={async (values, { setErrors, setSubmitting }) => {
        try {
          const res = await UsersApi.updatePassword(user.id, {
            oldPassword: values.oldPassword,
            password: values.password,
          });
          setSubmitting(false);
          dispatch(setNotificationAC({ message: 'Password successfully updated', type: 'success' }));
          setFormError('');
        } catch (error) {
          setSubmitting(false);
          if (error.statusCode === 401) {
            setErrors({ oldPassword: 'Old password is incorrect' });
            setFormError('');
          } else {
            console.log(error);
            dispatch(setNotificationAC({ message: 'An error occured', type: 'error' }));
            setFormError('An error occured');
          }
        }
      }}
    >
      {({ isSubmitting, errors, isValid, touched }) => (
        <Form className="human-form">
          <TopContentBottomControls>
            <div className="top-content">
              <InputWrapper label="Old password" labelPosition="top" name="oldPassword" error={touched.oldPassword && errors.oldPassword}>
                <Field
                  type="password"
                  name="oldPassword"
                  autoComplete="current-password"
                  className={classNames({ classic: true, error: touched.oldPassword && errors.oldPassword })}
                />
              </InputWrapper>
              <InputWrapper label="New password" labelPosition="top" name="password" error={touched.password && errors.password}>
                <Field
                  type="password"
                  name="password"
                  autoComplete="new-password"
                  className={classNames({ classic: true, error: touched.password && errors.password })}
                />
              </InputWrapper>
              <InputWrapper
                label="Repeat new password"
                labelPosition="top"
                name="password"
                error={touched.passwordConfirmation && errors.passwordConfirmation}
              >
                <Field
                  type="password"
                  name="passwordConfirmation"
                  autoComplete="new-password"
                  className={classNames({ classic: true, error: touched.passwordConfirmation && errors.passwordConfirmation })}
                />
              </InputWrapper>
              {formError && <Inforow type="error" message={formError} />}
            </div>
            <div className="bottom-content">
              <SmartBtn type="submit" className="submit" label="Update password" loading={isSubmitting} />
            </div>
          </TopContentBottomControls>
        </Form>
      )}
    </Formik>
  );
};
