import classNames from 'classnames';
import React from 'react';
import { useDispatch } from 'react-redux';
import { PopupId } from '../../../models/app';
import { setPopupAC } from '../../../redux/reducers/appReducer';
import successImg from '../../../img/illustrations/success.svg';

type PropsType = {
  id: PopupId;
  title: string;
  children: React.ReactNode;
  noContentPadding?: boolean;
  width?: number;
  statusText?: string;
  disableClose?: boolean;
  onClose?: () => any;
};

export const Popup = (props: PropsType) => {
  const dispatch = useDispatch();

  const handleClose = () => {};

  return (
    <div className="popup-wrapper">
      <div id={props.id} className="popup" style={{ width: props.width }}>
        <div className="popup__header">
          <h3 className="popup__header__title">{props.title}</h3>
          {!props.disableClose && (
            <button
              className="popup__header__close"
              onClick={(e) => {
                if (props.onClose) {
                  props.onClose();
                } else {
                  dispatch(setPopupAC({ popupId: props.id, open: false }));
                }
              }}
            />
          )}
        </div>
        <div className={classNames({ popup__content: true, 'no-padding': props.noContentPadding })}>
          {props.statusText && (
            <div className="status">
              <div className="status__content">
                <img className="status-icon" src={successImg} alt="Success" />
                <div className="status-title">Password reset email has been sent. Check your inbox!</div>
              </div>
            </div>
          )}
          {props.children}
        </div>
      </div>
      <div className="popup-shade" onClick={(e) => handleClose()}></div>
    </div>
  );
};
