import { Item, ItemParams, Menu } from 'react-contexify';
import 'react-contexify/dist/ReactContexify.css';
import { useDispatch } from 'react-redux';
import { ImageSequenceResourceThunks } from '../../redux/thunks/resources/ImageSequenceResourceThunks';

export const IMAGE_SEQUENCE_CONTEXT_MENU_ID = 'IMAGE-SEQUENCE-CONTEXT-MENU-ID';

export const ImageSequenceContextMenu = () => {
  const dispatch = useDispatch();

  const handleDelete = ({ props }: ItemParams<{ id: number }>) => {
    if (props && props.id) dispatch(ImageSequenceResourceThunks.remove(props.id));
  };

  const importAsLayer = ({ props }: ItemParams<{ id: number }>) => {
    if (props && props.id) dispatch(ImageSequenceResourceThunks.toLayer(props.id));
  };

  return (
    <Menu id={IMAGE_SEQUENCE_CONTEXT_MENU_ID}>
      <Item onClick={importAsLayer}>Import as layer</Item>
      <Item onClick={handleDelete}>Delete image sequence</Item>
    </Menu>
  );
};
