import { RefObject } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../../../redux/AppState';
import { setActiveFrameAC } from '../../../../redux/reducers/projectReducer';
import {
  compActiveFrameSelector,
  compLayerControlsWidthSelector,
  compScaleSelector,
} from '../../../../redux/selectors/compositionSelector';
import { DraggableTimeline } from '../../../DraggableTimeline';

type PropsType = { scrollLeft: number; handleRef: RefObject<HTMLDivElement> };

export const TfsLine = ({ scrollLeft, handleRef }: PropsType) => {
  const compScale = useSelector(compScaleSelector);
  const compLcw = useSelector(compLayerControlsWidthSelector);
  const compActiveFrame = useSelector(compActiveFrameSelector);
  const dispatch = useDispatch();

  const handleSelectorDrag = (newSelectedFrame: number, startFrame: number) => {
    if (newSelectedFrame >= 0) dispatch(setActiveFrameAC({ activeFrame: newSelectedFrame }));
    else dispatch(setActiveFrameAC({ activeFrame: 0 }));
  };

  return (
    <div
      className="timeline-frame-selector__line no-deselect-layer"
      style={{ left: -scrollLeft + compActiveFrame * compScale + compScale / 2 + 1 }}
    >
      <DraggableTimeline onDrag={handleSelectorDrag}>
        <div className="current-frame" style={{ width: compScale, height: '20px' }} ref={handleRef}>
          {compActiveFrame}
        </div>
      </DraggableTimeline>
      <div className="frame-line"></div>
    </div>
  );
};
