import classNames from 'classnames';
import { Field, useFormikContext } from 'formik';
import { InputErrorWrapper } from './InputErrorWrapper';
import { InputWrapper } from './InputWrapper';

type PropsType = {
  name: string;
  type: string;
  label: string;
  error?: string | false;
};

export const InputFormikField = (props: PropsType) => {
  const { submitForm, handleChange } = useFormikContext();

  return (
    <InputWrapper label={props.label} name={props.name}>
      <InputErrorWrapper error={props.error}>
        <Field
          type={props.type}
          name={props.name}
          autoComplete="off"
          className={classNames({
            classic: true,
            error: props.error,
          })}
          onChange={handleChange}
          onBlur={() => submitForm()}
          onKeyDown={(e: any) => {
            if (e.key === 'Enter') {
              (document as any).activeElement.blur();
              submitForm();
            }
          }}
        />
      </InputErrorWrapper>
    </InputWrapper>
  );
};
