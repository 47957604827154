import { Project } from '../../models/project';
import { ExportService } from '../../services/ExportService';
import { FontService } from '../../services/FontService';
import { ProjectCompatibility } from '../../services/ProjectCompatibility';
import { setModalAC, setOutputDirectoryPath, toggleTouchedAC } from '../reducers/appReducer';
import { setProjectAC } from '../reducers/projectReducer';
import { projectSelector } from '../selectors/projectSelector';
import { MyThunkActionReturn } from './thunkTypes';

export const carefullyLoadProjectThunk = (project: Project): MyThunkActionReturn => {
  return (dispatch, getState) => {
    const state = getState();
    const currentProject = projectSelector(state);

    /**
     * Check if need to save current (unsaved) project
     */

    if (currentProject.name && state.app.touched === true) {
      // Unsaved roject is already open

      const saveCb = () => {
        ExportService.handleSaveProject(dispatch, currentProject);
        dispatch(loadProjectThunk(project));
        dispatch(setModalAC(undefined));
      };
      const saveControl = {
        label: 'Save',
        autoFocus: true,
        onClick: saveCb,
      };

      const dontSaveCb = () => {
        dispatch(loadProjectThunk(project));
        dispatch(setModalAC(undefined));
      };
      const dontSaveControl = {
        label: `Don't save`,
        onClick: dontSaveCb,
      };

      const cancelCb = () => {
        dispatch(setModalAC(undefined));
      };
      const cancelControl = {
        label: `Cancel`,
        onClick: cancelCb,
      };

      dispatch(
        setModalAC({
          title: 'Confirmation required',
          content: `Do you want to save changes to ${currentProject.name}?`,
          controls: [saveControl, dontSaveControl, cancelControl],
        }),
      );

      return;
    }

    // No unsaved project is currently open
    dispatch(loadProjectThunk(project));
  };
};

export const loadProjectThunk = (project: Project): MyThunkActionReturn => {
  return (dispatch, getState) => {
    ProjectCompatibility.makeSureItsCompatible(project);

    // Make sure to remove old output directory
    (window as any).projectOutputDirectoryFileHandle = undefined;
    dispatch(setOutputDirectoryPath(undefined));

    dispatch(setProjectAC({ project }));

    for (const font of project.resources.fonts) {
      FontService.loadFontFamily(font.name, FontService.base64ToArrayBuffer(font.content));
    }

    dispatch(toggleTouchedAC(false));
  };
};
