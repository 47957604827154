import { Popup } from '../common/popup/Popup';
import { InputWrapper } from '../common/inputs/InputWrapper';
import { SmartBtn } from '../common/inputs/SmartBtn';
import { Inforow } from '../common/inputs/Inforow';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import classNames from 'classnames';
import { useState } from 'react';
import { AuthApi } from '../../services/api/AuthApi';
import { useHistory } from 'react-router';
import { SITEMAP } from '../../config/SITEMAP';

type PropsType = {
  disableClose?: boolean;
};

export const ForgotPasswordPopup = (props: PropsType) => {
  const [formError, setFormError] = useState('');
  const [status, setStatus] = useState('');
  const history = useHistory();

  return (
    <Popup
      id="forgotPassword"
      title="Forgot password"
      statusText={status}
      disableClose={props.disableClose}
      onClose={() => history.push(SITEMAP.STUDIO)}
    >
      <Formik
        initialValues={{
          email: '',
          password: '',
        }}
        validationSchema={Yup.object({
          email: Yup.string().email('Email must be valid').required('Email is required'),
        })}
        onSubmit={async (values, { setSubmitting, setErrors }) => {
          try {
            const res = await AuthApi.resetPasswordRequest({
              email: values.email,
            });
            setStatus('Password reset email has been sent. Check your inbox!');
            setSubmitting(false);
          } catch (error) {
            setSubmitting(false);
            if (error.statusCode === 404) {
              setErrors({ email: 'Email does not exist' });
            } else {
              console.log(error);
              setFormError('An error occured');
            }
          }
        }}
      >
        {({ isSubmitting, errors, isValid, touched }) => (
          <Form className="human-form auth">
            <h1>Forgot password?</h1>
            <p>Enter your email used for logging into Loopic and we will send you a password reset instructions.</p>
            <InputWrapper label="Email" labelPosition="top" name="email" error={touched.email && errors.email}>
              <Field
                type="text"
                name="email"
                autoComplete="username"
                className={classNames({ classic: true, error: touched.email && errors.email })}
              />
            </InputWrapper>
            {formError && <Inforow type="error" message={formError} />}
            <SmartBtn className="submit" label="Reset password" loading={isSubmitting} />
          </Form>
        )}
      </Formik>
    </Popup>
  );
};
