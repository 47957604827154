import classNames from 'classnames';
import { CSSProperties, MouseEventHandler } from 'react';
import { Item, Menu, useContextMenu } from 'react-contexify';
import 'react-contexify/dist/ReactContexify.css';
import { useDispatch } from 'react-redux';

import flagIcon from '../../../img/icons/flag-red.svg';
import whiteFlagIcon from '../../../img/icons/flag-white.svg';
import { setActionEditorIdAC, setPopupAC } from '../../../redux/reducers/appReducer';
import { removeActionAC, toggleActionOutroAC } from '../../../redux/reducers/projectReducer';

type PropsType = {
  id: number;
  outro: boolean;
  style?: CSSProperties;
  onMouseDown?: any;
};

export const ActionFlag = (props: PropsType) => {
  const MENU_ID = 'action-menu-' + props.id;

  const dispatch = useDispatch();
  const { show } = useContextMenu({
    id: MENU_ID,
  });

  const handleContextMenu: MouseEventHandler<HTMLDivElement> = (event) => {
    event.preventDefault();
    show(event);
  };

  const handleDeleteAction = () => {
    dispatch(removeActionAC({ actionId: props.id }));
  };

  const handleOutro = (outro: boolean) => {
    dispatch(toggleActionOutroAC({ actionId: props.id, outro }));
  };

  return (
    <>
      <div
        style={props.style}
        className={classNames({ 'action-flag': true, outro: props.outro })}
        onMouseDown={props.onMouseDown}
        onContextMenu={handleContextMenu}
        onDoubleClick={() => {
          dispatch(setActionEditorIdAC(props.id));
          dispatch(setPopupAC({ popupId: 'actionEditor', open: true }));
        }}
      >
        {!props.outro && <img src={flagIcon} alt="Red flag" draggable={false} />}
        {props.outro && <img src={whiteFlagIcon} alt="White flag" draggable={false} />}
      </div>

      <Menu id={MENU_ID}>
        {!props.outro && <Item onClick={() => handleOutro(true)}>Set as outro</Item>}
        {props.outro && <Item onClick={() => handleOutro(false)}>Remove outro</Item>}
        <Item onClick={handleDeleteAction}>Delete action</Item>
      </Menu>
    </>
  );
};
