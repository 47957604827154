import classNames from 'classnames';
import { MouseEventHandler } from 'react';
import { useRef, useState } from 'react';
import { useContextMenu } from 'react-contexify';
import { useDispatch, useSelector } from 'react-redux';
import { Layer } from '../../../../models/composition';
import { ImageSequence } from '../../../../models/resources';
import { moveLayerAC, setLayerStartAndDurationAC } from '../../../../redux/reducers/projectReducer';
import { compDurationSelector, compScaleSelector } from '../../../../redux/selectors/compositionSelector';
import { getLayerByIdSelector, isLayerSelectedSelector } from '../../../../redux/selectors/layersSelector';
import { projectSelector } from '../../../../redux/selectors/projectSelector';
import { selectLayerThunk } from '../../../../redux/thunks/layersThunk';
import { ColorsService } from '../../../../services/ColorsService';
import { LayoutItem, propertiesLayout, PropertyLayoutGroup } from '../../../../services/PropertySettingsService';
import { LAYER_CONTEXT_MENU_ID } from '../../../context-menu/LayerContextMenu';
import { DraggableTimeline } from '../../../DraggableTimeline';
import { KeyframePropertyRow } from './KeyframePropertyRow';

type PropsType = {
  layerId: number;
};

export const TimelineLayer = ({ layerId, ...props }: PropsType) => {
  const layer = useSelector(getLayerByIdSelector(layerId));
  const isLayerSelected = useSelector(isLayerSelectedSelector(layerId));
  const imageSequences = useSelector(projectSelector).resources.imageSequences;
  const compScale = useSelector(compScaleSelector);
  const compDuration = useSelector(compDurationSelector);
  const dispatch = useDispatch();
  const edgeDragging = useRef<boolean>(false);
  const frameSelectorWidth = compDuration * compScale;

  const { show } = useContextMenu({
    id: LAYER_CONTEXT_MENU_ID,
  });

  const handleContextMenu: MouseEventHandler<HTMLDivElement> = (event) => {
    event.preventDefault();
    show(event, {
      props: {
        id: layerId,
      },
    });
  };

  const [isMouseOver, setMouseOver] = useState(false);
  const myRef = useRef<HTMLDivElement>(null);

  if (!layer) return <></>;

  let imageSequence: ImageSequence | undefined;
  let firstImageRepeat = 0;
  let lastImageRepeat = 0;
  if (layer.element.type === 'IMAGE_SEQUENCE') {
    const isId = layer.element.imageSequenceId;
    imageSequence = imageSequences.find((is) => is.id === isId);
    if (!imageSequence) return <></>;

    let lastId: number | undefined = undefined;
    for (const imgId of layer.element.imageIds) {
      if (lastId !== undefined && imgId === lastId) {
        ++firstImageRepeat;
        lastId = imgId;
      } else if (lastId !== undefined && imgId !== lastId) {
        break;
      } else if (lastId === undefined) {
        lastId = imgId;
      }
    }

    lastId = undefined;
    for (const imgId of [...layer.element.imageIds].reverse()) {
      if (lastId !== undefined && imgId === lastId) {
        ++lastImageRepeat;
        lastId = imgId;
      } else if (lastId !== undefined && imgId !== lastId) {
        break;
      } else {
        lastId = imgId;
      }
    }
  }

  return (
    <div
      id={layer.id.toString()}
      className={classNames({ 'timeline-layer': true, 'focus-layer': true, selected: isLayerSelected })}
      onMouseEnter={() => setMouseOver(true)}
      onMouseLeave={() => setMouseOver(false)}
      onFocus={() => setMouseOver(true)}
      onBlur={() => setMouseOver(false)}
      style={{ width: frameSelectorWidth }}
      ref={myRef}
      onMouseDown={() => {
        dispatch(selectLayerThunk(layerId));
      }}
      onContextMenu={handleContextMenu}
      // Timeline layer must be focusable; important for distingushing the difference between which elements fired a keyboard shortcut
      tabIndex={0}
    >
      <DraggableTimeline
        onDrag={(newSelectedFrame: number, dragStartFrame: number) => {
          if (edgeDragging.current) return;
          dispatch(moveLayerAC({ layerId: layer.id, startFrame: layer.startFrame - dragStartFrame + newSelectedFrame }));
        }}
      >
        <div
          className="layer"
          style={{
            backgroundColor: ColorsService.getColor(layer.color).timeline,
            marginLeft: layer.startFrame * compScale,
            width: layer.duration * compScale,
          }}
        >
          <DraggableTimeline
            onDragStart={() => {
              edgeDragging.current = true;
            }}
            onDragEnd={() => {
              edgeDragging.current = false;
            }}
            onDrag={(newSelectedFrame: number, dragStartFrame: number) => {
              const diff = newSelectedFrame - dragStartFrame;
              const imageIds = imageSequence?.images.map((img) => img.id);
              dispatch(
                setLayerStartAndDurationAC({
                  layerId: layer.id,
                  startFrame: newSelectedFrame,
                  duration: layer.duration - diff,
                  imageIds,
                }),
              );
            }}
          >
            <div className="start-edge" />
          </DraggableTimeline>

          {layer.element.type === 'IMAGE_SEQUENCE' && imageSequence && (
            <>
              <div className="shade start-shade" style={{ width: firstImageRepeat * compScale }} />
              <div className="shade end-shade" style={{ width: lastImageRepeat * compScale }} />
            </>
          )}

          <DraggableTimeline
            onDragStart={() => {
              edgeDragging.current = true;
            }}
            onDragEnd={() => {
              edgeDragging.current = false;
            }}
            onDrag={(newSelectedFrame: number, dragStartFrame: number) => {
              const diff = newSelectedFrame - dragStartFrame;
              const imageIds = imageSequence?.images.map((img) => img.id);
              dispatch(
                setLayerStartAndDurationAC({
                  layerId: layer.id,
                  startFrame: layer.startFrame,
                  duration: layer.duration + diff,
                  imageIds,
                }),
              );
            }}
          >
            <div className="end-edge" />
          </DraggableTimeline>
        </div>
      </DraggableTimeline>
      {layer.open && (
        <div className="keyframes">
          <PropertiesKeyframesLayoutItem layer={layer} scale={compScale} items={propertiesLayout} />
        </div>
      )}
    </div>
  );
};

export const PropertiesKeyframesLayoutItem = ({ layer, items, scale }: { layer: Layer; items: LayoutItem[]; scale: number }) => {
  return (
    <>
      {items.map((item) => {
        if (typeof item === 'string') {
          return <KeyframePropertyRow key={item} layer={layer} scale={scale} propertyId={item} property={layer.properties[item]!} />;
        } else {
          // Check if this item exists for the selected layer
          if ((item.types && item.types.indexOf(layer.element.type as any) > -1) || !item.types) {
            return <KeyframePropertyGroup key={item.id} layer={layer} scale={scale} propertyLayoutGroup={item} />;
          }
        }
      })}
    </>
  );
};

export const KeyframePropertyGroup = (props: { layer: Layer; scale: number; propertyLayoutGroup: PropertyLayoutGroup }) => {
  const isOpen = props.layer.openPropertyGroups.findIndex((opg) => opg === props.propertyLayoutGroup.id) >= 0;

  return (
    <div className="keyframe-property-group">
      {isOpen && <PropertiesKeyframesLayoutItem layer={props.layer} scale={props.scale} items={props.propertyLayoutGroup.items} />}
    </div>
  );
};
