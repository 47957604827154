import { useSelector } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { LOOPIC_CONFIG } from '../config/LOOPIC_CONFIG';
import { SITEMAP } from '../config/SITEMAP';
import { ActivateAccountPage } from '../pages/ActivateAccountPage';
import { ForgotPasswordPage } from '../pages/ForgotPasswordPage';
import { LoginPage } from '../pages/LoginPage';
import { RegisterPage } from '../pages/RegisterPage';
import { ResetPasswordPage } from '../pages/ResetPasswordPage';
import { StudioPage } from '../pages/StudioPage';
import { AppState } from '../redux/AppState';
import { AuthService } from '../services/AuthService';
import { PrivateRoute } from './PrivateRoute';
import { PublicRoute } from './PublicRoute';

export const AppRouter = () => {
  const user = useSelector((state: AppState) => state.user);
  const allowNoReg = LOOPIC_CONFIG.allowAccessWithoutRegistration;
  const isLoggedIn = AuthService.isLoggedIn(user);

  return (
    <Router>
      <Switch>
        <PublicRoute path={SITEMAP.LOGIN} exact component={LoginPage} />
        <PublicRoute path={SITEMAP.REGISTER} exact component={RegisterPage} />
        <PublicRoute path={SITEMAP.FORGOT_PASSWORD} exact component={ForgotPasswordPage} />
      </Switch>
      {allowNoReg ? <Route path="/" component={StudioPage} /> : <PrivateRoute path="/" component={StudioPage} />}
      <Route path="/reset-password/:userId/:token" component={ResetPasswordPage} />
      <Route path="/activate-account/:userId/:code" component={ActivateAccountPage} />
    </Router>
  );
};
