import { useEffect, useState } from 'react';
import { Tooltip } from '../tooltip/Tooltip';

type PropsType = {
  name: string;
  label: string;
  value: string | number | undefined;
  onSubmit: (value: string) => void;
  icon?: string;
  disabled?: boolean;
};

export const PropertyNumber = (props: PropsType) => {
  const [value, setValue] = useState(props.value);
  const [focused, setFocused] = useState(false);

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  return (
    <div className="property-number">
      {props.icon && <img src={props.icon} alt={props.label} />}

      <input
        data-tip={props.label}
        type="text"
        name={props.name}
        disabled={props.disabled}
        value={!focused ? props.value : value}
        autoComplete="off"
        onFocus={() => setFocused(true)}
        onBlur={() => {
          setFocused(false);
          setValue(props.value);
        }}
        onKeyDown={(e: any) => {
          if (e.key === 'Enter') {
            if (props.onSubmit) props.onSubmit(value as string);
            (document as any).activeElement.blur();
          }
        }}
        onChange={(e: any) => {
          const newValue = e.target.value;
          setValue(newValue);
        }}
      />
      <Tooltip />
    </div>
  );
};
