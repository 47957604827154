import zoomIcon from '../../img/icons/zoom.svg';
import { BtnPlus } from '../common/inputs/BtnPlus';
import { BtnMinus } from '../common/inputs/BtnMinus';

type PropsType = {
  currentZoom: number;
  onZoom: (factor: number) => void;
  onFitCanvas: () => void;
};

export const RendererControlsSection = (props: PropsType) => {
  return (
    <div className="renderer-controls-section">
      <div className="zoom-help">Pan around using SPACE key and Mouse click &amp; drag.</div>
      <img src={zoomIcon} className="zoom-icon" alt="Zoom icon" />
      <div className="current-zoom">{(props.currentZoom * 100).toFixed(0)}%</div>
      <button className="btn" onClick={() => props.onFitCanvas()}>
        Fit Canvas
      </button>
      <button className="btn" onClick={() => props.onZoom(1)}>
        100%
      </button>
      <BtnPlus
        onClick={() => {
          props.onZoom(props.currentZoom + 0.1);
        }}
      />
      <BtnMinus onClick={() => props.onZoom(props.currentZoom - 0.1)} />
    </div>
  );
};
