import React, { useState } from 'react';
import classnames from 'classnames/bind';

type SearchBoxProps = {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  size?: 's';
  onBlur?: () => void;
  placeholder?: string;
};

const SearchBox = (props: SearchBoxProps) => {
  const [isFocused, setFocus] = useState(false);

  return (
    <div
      className={classnames({
        'search-box': true,
        focus: isFocused,
      })}
    >
      <input
        type="text"
        autoFocus={true}
        value={props.value}
        onChange={props.onChange}
        onFocus={() => setFocus(true)}
        onBlur={() => {
          setFocus(false);
          if (props.onBlur) props.onBlur();
        }}
        placeholder={props.placeholder}
      />
      <div className="icon" />
    </div>
  );
};

export { SearchBox };
