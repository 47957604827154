type Keys = 'Z' | 'Y' | 'S' | 'D' | 'DELETE' | 'J' | 'K' | 'L';

export const isKey = (ev: KeyboardEvent, key: Keys) => {
  if (!ev.key) return;
  return ev.key.toUpperCase() === key.toUpperCase();
};

export class KeyboardService {
  static keysDown: string[] = [];

  static init() {
    window.addEventListener('keydown', (ev) => {
      if (!ev.key) return;
      if (!this.isKeyDown(ev.key)) KeyboardService.keysDown.push(ev.key.toUpperCase());
    });

    window.addEventListener('keyup', (ev) => {
      if (!ev.key) return;
      this.keysDown = this.keysDown.filter((key) => key !== ev.key.toUpperCase());
    });
  }

  static isKeyDown(key: string) {
    return this.keysDown.findIndex((downKey) => downKey === key.toUpperCase()) > -1;
  }
}
