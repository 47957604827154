const colors = {
  GREEN: {
    timeline: '#6ec56e',
  },
  RED: {
    timeline: '#d24e54',
  },
  BLUE: {
    timeline: '#2b79b0',
  },
  ORANGE: {
    timeline: '#df9a4b',
  },
  GREY: {
    timeline: 'silver',
  },
};

export type ColorId = keyof typeof colors;

const elementColors = {
  COMPOSITION: 'GREY',
  TEXT: 'GREEN',
  IMAGE: 'RED',
  IMAGE_SEQUENCE: 'ORANGE',
  IMAGE_LOADER: 'BLUE',
  SHAPE: 'BLUE',
};

export type ElementId = keyof typeof elementColors;

export class ColorsService {
  static getColor(colorId: ColorId) {
    return colors[colorId];
  }

  static getElementColorId(elementId: ElementId) {
    return elementColors[elementId] as ColorId;
  }
}
