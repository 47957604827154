import imageLoaderIcon from '../../../img/icons/layers/image-loader-icon.svg';
import demo1 from '../../../img/placeholders/demo_1.png';
import demo2 from '../../../img/placeholders/demo_2.png';
import { RefObject } from 'react';
import { ImageLoaderSize } from '../../../models/elements';

type PropsType = {
  id: number;
  styles: any;
  size: ImageLoaderSize;
  placeholderId: number;
  elementRef: RefObject<HTMLDivElement>;
};

export const ImageLoaderRenderElement = (props: PropsType) => {
  return (
    <div className={`element element-${props.id} image-loader ${props.size}`} style={props.styles} ref={props.elementRef}>
      {props.placeholderId === 0 && <img src={imageLoaderIcon} className="icon" alt="Icon" draggable={false} />}
      {props.placeholderId === 1 && <img src={demo1} className="image" alt="Img" draggable={false} />}
      {props.placeholderId === 2 && <img src={demo2} className="image" alt="Img" draggable={false} />}
    </div>
  );
};
