import { AuthService } from '../AuthService';
import { post } from './config';
import { UserResponse } from './UsersApi';

type AuthCredentials = {
  email: string;
  password: string;
};

export type LoginResponse = {
  access_token: string;
  user: UserResponse;
};

type UpdatePasswordRequest = {
  oldPassword: string;
  password: string;
};

type ResetPasswordRequest = {
  email: string;
};

type NewPasswordRequest = {
  password: string;
};

export class AuthApi {
  static activateUser(userId: string, code: string) {
    throw new Error('Method not implemented.');
  }
  static login = async (params: AuthCredentials) => await post<LoginResponse>('/auth/login', params);

  static updatePassword = async (userId: string, params: UpdatePasswordRequest) =>
    await post<{}>(`/auth/${userId}/change-password`, params, AuthService.getAuthHeader());

  static resetPasswordRequest = async (params: ResetPasswordRequest) => await post<{}>(`/auth/reset-password`, params);

  static resetPassword = async (userId: string, token: string, params: NewPasswordRequest) =>
    await post<{}>(`/auth/reset-password/${userId}/${token}`, params);

  static activateAccount = async (userId: string, code: string) => await post<{}>(`/auth/activate/${userId}/${code}`);
}
