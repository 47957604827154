import { AppState } from '../AppState';
import { projectSelector } from './projectSelector';

export const compSelector = (state: AppState) => {
  const project = projectSelector(state);
  const activeId = project.activeCompositionId;
  return project.compositions.find((comp) => comp.id === activeId)!;
};

export const compIdSelector = (state: AppState) => {
  const project = projectSelector(state);
  const activeId = project.activeCompositionId;
  return project.compositions.find((comp) => comp.id === activeId)!.id;
};

export const compScaleSelector = (state: AppState) => {
  const project = projectSelector(state);
  const activeId = project.activeCompositionId;
  return project.compositions.find((comp) => comp.id === activeId)!.scale;
};

export const compLCWSelector = (state: AppState) => {
  const project = projectSelector(state);
  const activeId = project.activeCompositionId;
  return project.compositions.find((comp) => comp.id === activeId)!.layerControlsWidth;
};

export const compWorkspaceDurationSelector = (state: AppState) => {
  const project = projectSelector(state);
  const activeId = project.activeCompositionId;
  return project.compositions.find((comp) => comp.id === activeId)!.workspaceDuration;
};

export const compDurationSelector = (state: AppState) => {
  const project = projectSelector(state);
  const activeId = project.activeCompositionId;
  return project.compositions.find((comp) => comp.id === activeId)!.duration;
};

export const compPanelHeightSelector = (state: AppState) => {
  const project = projectSelector(state);
  const activeId = project.activeCompositionId;
  return project.compositions.find((comp) => comp.id === activeId)!.compositionPanelHeight;
};

export const compLayerControlsWidthSelector = (state: AppState) => {
  const project = projectSelector(state);
  const activeId = project.activeCompositionId;
  return project.compositions.find((comp) => comp.id === activeId)!.layerControlsWidth;
};

export const compActiveFrameSelector = (state: AppState) => {
  const project = projectSelector(state);
  const activeId = project.activeCompositionId;
  return project.compositions.find((comp) => comp.id === activeId)!.activeFrame;
};

export const compWidthSelector = (state: AppState) => {
  const project = projectSelector(state);
  const activeId = project.activeCompositionId;
  return project.compositions.find((comp) => comp.id === activeId)!.width;
};

export const compHeightSelector = (state: AppState) => {
  const project = projectSelector(state);
  const activeId = project.activeCompositionId;
  return project.compositions.find((comp) => comp.id === activeId)!.height;
};

export const compLayersSelector = (state: AppState) => {
  const project = projectSelector(state);
  const activeId = project.activeCompositionId;
  return project.compositions.find((comp) => comp.id === activeId)!.layers;
};

export const compBackgroundSelector = (state: AppState) => {
  const project = projectSelector(state);
  const activeId = project.activeCompositionId;
  return project.compositions.find((comp) => comp.id === activeId)!.background;
};
