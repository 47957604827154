import { ImageLoaderSize } from '../../models/elements';
import { KeyboardService } from '../../services/KeyboardService';
import { PropertyId } from '../../services/PropertySettingsService';
import { getActiveComposition } from '../helpers';
import { PropertyHelper } from '../helpers/PropertyHelper';
import { setModalAC } from '../reducers/appReducer';
import {
  deselectAllLayersAC,
  deselectLayerAC,
  duplicateLayerAC,
  removeKeyframeAC,
  removeLayerAC,
  reverseLayersAC,
  selectLayerAC,
  setImageLoaderPlaceholderIdAC,
  setImageLoaderSizeAC,
  setPropertyValueAC,
  SetPropertyValuePayload,
  toggleLayerGuideAC,
  toggleOpenLayerAC,
  toggleOpenPropertyGroupAC,
} from '../reducers/projectReducer';
import { removeProjectLayerAC } from '../reducers/projectReducer';
import { allSelectedLayerIdsSelector, getAllSelectedLayersSelector, isLayerSelectedSelector } from '../selectors/layersSelector';
import { projectSelector } from '../selectors/projectSelector';
import { MyThunkActionReturn } from './thunkTypes';

export const setImageLoaderPlaceholderIdThunk = (placeholderId: number): MyThunkActionReturn => {
  return (dispatch, getState) => {
    const selectedLayers = getAllSelectedLayersSelector(getState());
    for (const layer of selectedLayers) {
      dispatch(setImageLoaderPlaceholderIdAC({ layerId: layer.id, placeholderId }));
    }
  };
};

export const setImageLoaderSizeThunk = (size: ImageLoaderSize): MyThunkActionReturn => {
  return (dispatch, getState) => {
    const selectedLayers = getAllSelectedLayersSelector(getState());
    for (const layer of selectedLayers) {
      dispatch(setImageLoaderSizeAC({ layerId: layer.id, size }));
    }
  };
};

export const duplicateLayersThunk = (): MyThunkActionReturn => {
  return (dispatch, getState) => {
    const selectedLayers = allSelectedLayerIdsSelector(getState());
    dispatch(duplicateLayerAC({ layerIds: selectedLayers }));
  };
};

export const reverseLayersThunk = (): MyThunkActionReturn => {
  return (dispatch, getState) => {
    const selectedLayers = allSelectedLayerIdsSelector(getState());
    dispatch(reverseLayersAC({ layerIds: selectedLayers }));
  };
};

export const toggleLayersGuideThunk = (guide: boolean): MyThunkActionReturn => {
  return (dispatch, getState) => {
    const selectedLayers = getAllSelectedLayersSelector(getState());
    for (const layer of selectedLayers) {
      dispatch(toggleLayerGuideAC({ layerId: layer.id, guide }));
    }
  };
};

export const removeLayerThunk = (compositionId: number, layerId: number): MyThunkActionReturn => {
  return (dispatch, getState) => {
    const state = getState();
    const project = projectSelector(state);

    const composition = getActiveComposition(project);
    const activeCompId = composition.id;
    if (activeCompId === compositionId) {
      dispatch(removeLayerAC({ layerId }));
    } else {
      dispatch(removeProjectLayerAC({ compositionId, layerId }));
    }
  };
};

export const carefullyRemoveLayersThunk = (): MyThunkActionReturn => {
  return (dispatch, getState) => {
    const selectedLayers = getAllSelectedLayersSelector(getState());
    if (selectedLayers.length === 0) return;

    const deleteControl = {
      label: 'Delete',
      onClick: () => {
        for (const layer of selectedLayers) {
          dispatch(removeLayerAC({ layerId: layer.id }));
        }
        dispatch(setModalAC(undefined));
      },
    };
    const cancelControl = {
      label: 'Cancel',
      autoFocus: true,
      onClick: () => dispatch(setModalAC(undefined)),
    };

    dispatch(
      setModalAC({
        title: 'Delete confirmation',
        content:
          selectedLayers.length > 1
            ? `Are you sure to delete ${selectedLayers.length} selected layers?`
            : `Are you sure to delete selected layer?`,
        icon: 'warning',
        controls: [cancelControl, deleteControl],
      }),
    );
  };
};

export const toggleOpenLayerThunk = (layerId: number, open: boolean): MyThunkActionReturn => {
  return (dispatch, getState) => {
    const selectedLayers = allSelectedLayerIdsSelector(getState());
    dispatch(toggleOpenLayerAC({ layerIds: [...selectedLayers, layerId], open }));
  };
};

export const toggleOpenPropertyGroupThunk = (layerId: number, propertyGroupId: string, open: boolean): MyThunkActionReturn => {
  return (dispatch, getState) => {
    const selectedLayers = getAllSelectedLayersSelector(getState());
    selectedLayers.forEach((layer) => {
      dispatch(toggleOpenPropertyGroupAC({ layerId: layer.id, propertyGroupId, open: open }));
    });
  };
};

/**
 * Layer selecting logic
 * @param layerId ID of the layer to select (target)
 */
export const selectLayerThunk = (layerId: number): MyThunkActionReturn => {
  return (dispatch, getState) => {
    const state = getState();
    const isTargetLayerSelected = isLayerSelectedSelector(layerId)(state);

    const isControlDown = KeyboardService.isKeyDown('CONTROL');

    if (!isControlDown && isTargetLayerSelected) {
      // Shift is not down and item is already selected
      //
    } else if (!isControlDown) {
      // Shift is not down
      dispatch(selectLayerAC({ layerId: layerId, single: true }));
      //
    } else if (isControlDown && isTargetLayerSelected) {
      // Shift is down and item is already selected
      dispatch(deselectLayerAC({ layerId }));
      //
    } else if (isControlDown && !isTargetLayerSelected) {
      // Shift is down and item is not selected
      dispatch(selectLayerAC({ layerId: layerId }));
    }
  };
};

/**
 * Layer selecting logic
 */
export const deselectAllLayersThunk = (): MyThunkActionReturn => {
  return (dispatch, getState) => {
    const selectedLayers = getAllSelectedLayersSelector(getState());
    if (selectedLayers.length > 0) {
      dispatch(deselectAllLayersAC());
    }
  };
};

type RemoveKeyframeThunkType = {
  layerId: number;
  propertyId: PropertyId;
  keyframeId: number;
  frame: number;
};

export const removeKeyframeThunk = (arg: RemoveKeyframeThunkType): MyThunkActionReturn => {
  return (dispatch, getState) => {
    const selectedLayers = getAllSelectedLayersSelector(getState());

    selectedLayers.forEach((layer) => {
      const property = layer.properties[arg.propertyId];
      if (property) {
        // Property found
        const keyframe = property.keyframes.find((kf) => kf.frame === arg.frame);
        if (keyframe) {
          // Keyframe found
          dispatch(removeKeyframeAC({ layerId: layer.id, propertyId: arg.propertyId, keyframeId: keyframe.id }));
        }
      }
    });
  };
};

export const setPropertyValueThunk = (
  layerId: number | undefined,
  propertiesAndValues: { propertyId: PropertyId; value: string | number }[],
  asKeyframe?: boolean,
  onlyApplyToSelected?: boolean,
  calculateDelta?: boolean,
): MyThunkActionReturn => {
  return (dispatch, getState) => {
    const state = getState();
    const project = projectSelector(state);
    const composition = getActiveComposition(project);
    const activeFrame = composition.activeFrame;

    const layer = composition.layers.find((layer) => layer.id === layerId);
    if (!layer) return;

    const selectedLayer = composition.layers.find((layer) => layer.id === layerId)!;

    const targetActiveFrame = activeFrame - layer.startFrame;

    if (onlyApplyToSelected) {
      // * Apply to only this layer
      const payloads: SetPropertyValuePayload[] = [];
      for (const pv of propertiesAndValues) {
        const normalizedValue = PropertyHelper.normalizePropertyValue(pv.propertyId, pv.value);
        payloads.push({
          layerId: selectedLayer.id,
          frame: targetActiveFrame,
          propertyId: pv.propertyId,
          value: normalizedValue,
          asKeyframe,
        });
      }
      dispatch(setPropertyValueAC(payloads));
    } else {
      // * Find all other selected layers
      const allOtherSelectedLayers = getAllSelectedLayersSelector(state).filter((layer) => layer.id !== layerId);

      const payloads: SetPropertyValuePayload[] = [];

      allOtherSelectedLayers.forEach((layer) => {
        if (calculateDelta) {
          // * Move all selected layers for the same delta
          for (const pv of propertiesAndValues) {
            const oldTargetValue = PropertyHelper.getPropertyValue(selectedLayer.properties, pv.propertyId, targetActiveFrame)!;
            const delta = (pv.value as number) - (oldTargetValue as number);
            const oldLayerValue = PropertyHelper.getPropertyValue(layer.properties, pv.propertyId, targetActiveFrame)!;
            payloads.push({
              layerId: layer.id,
              frame: targetActiveFrame,
              propertyId: pv.propertyId,
              value: (oldLayerValue as number) + delta,
            });
          }
        } else {
          // * No delta - just apply the same value to every layer
          for (const pv of propertiesAndValues) {
            const normalizedValue = PropertyHelper.normalizePropertyValue(pv.propertyId, pv.value);
            payloads.push({ layerId: layer.id, frame: targetActiveFrame, propertyId: pv.propertyId, value: normalizedValue, asKeyframe });
          }
        }
      });

      // * And change value for the original layer :)
      for (const pv of propertiesAndValues) {
        const normalizedValue = PropertyHelper.normalizePropertyValue(pv.propertyId, pv.value);
        payloads.push({
          layerId: selectedLayer.id,
          frame: targetActiveFrame,
          propertyId: pv.propertyId,
          value: normalizedValue,
          asKeyframe,
        });
      }

      dispatch(setPropertyValueAC(payloads));
    }
  };
};
