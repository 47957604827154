import { Action } from '../../../models/composition';

export const actionsJSBuilder = (actions: Action[]) => {
  let conditions = '';
  for (const action of actions) {
    conditions += `if (currentFrame === ${action.frame}) {${action.code}}`;
  }

  return conditions;
};
