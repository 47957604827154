import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { SITEMAP } from '../../../config/SITEMAP';
import loginIcon from '../../../img/icons/login.svg';
import ninja from '../../../img/icons/ninja.svg';
import { AppState } from '../../../redux/AppState';
import { setPopupAC } from '../../../redux/reducers/appReducer';
import { AuthService } from '../../../services/AuthService';

export const AuthBar = () => {
  const user = useSelector((state: AppState) => state.user);

  return (
    <div className="auth-bar">
      {user.id ? (
        <UserDD />
      ) : (
        <>
          <RegisterBtn />
          <LoginBtn />
        </>
      )}
    </div>
  );
};

const UserDD = () => {
  const user = useSelector((state: AppState) => state.user);
  const dispatch = useDispatch();

  let displayName = user.email;
  if (user.firstName && user.lastName) {
    displayName = user.firstName + ' ' + user.lastName;
  }

  return (
    <div className="user-dd">
      <ul className="dropdown-menubar to-left">
        <li>
          <div className="user-brief">
            <span className="user-name">{displayName}</span>
            <div className="user-image">
              <img src={ninja} alt="Ninja user" />
            </div>
          </div>
          <ul>
            <li onClick={() => dispatch(setPopupAC({ popupId: 'account', open: true }))}>Account settings</li>
            <li onClick={() => AuthService.logout()}>Log out</li>
          </ul>
        </li>
      </ul>
    </div>
  );
};

const LoginBtn = () => {
  const history = useHistory();

  return (
    <button className="login-btn" onClick={() => history.push(SITEMAP.LOGIN)}>
      <img src={loginIcon} alt="Login" />
      <span>Log in</span>
    </button>
  );
};

const RegisterBtn = () => {
  const history = useHistory();

  return (
    <button className="register-btn" onClick={() => history.push(SITEMAP.REGISTER)}>
      Create account
    </button>
  );
};
