export abstract class SingleCSSConstructor {
  protected getValue(frame: number) {}

  protected getValueWithoutRepeat(frame: number) {
    const currentValue = this.getValue(frame);
    if (frame > 0) {
      const prevValue = this.getValue(frame - 1);
      if (prevValue !== currentValue) {
        return currentValue;
      } else {
        return '';
      }
    } else {
      return currentValue;
    }
  }

  protected getSmartValue(frame: number, withoutRepeat: boolean) {
    if (withoutRepeat) {
      return this.getValueWithoutRepeat(frame);
    }
    return this.getValue(frame);
  }

  setCalculatedStylesheetSmartValue(frame: number, calculatedStylesheet: any, withoutRepeat: boolean) {
    const value = this.getSmartValue(frame, withoutRepeat);
    if (value) {
      calculatedStylesheet[frame] = { ...calculatedStylesheet[frame], filter: value };
    }
  }
}
