import { useEffect } from 'react';

export const useOnKeyDown = (callback: (e: KeyboardEvent) => any) => {
  useEffect(() => {
    window.addEventListener('keydown', callback);
    return () => window.removeEventListener('keydown', callback);
  }, [callback]);
};

export const useOnKeyUp = (callback: (e: KeyboardEvent) => any) => {
  useEffect(() => {
    window.addEventListener('keyup', callback);
    return () => window.removeEventListener('keyup', callback);
  }, [callback]);
};

export const useWindowEvent = (event: any, callback: any) => {
  useEffect(() => {
    window.addEventListener(event, callback);
    return () => window.removeEventListener(event, callback);
  }, [event, callback]);
};
