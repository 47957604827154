import { PanelLayout } from '../common/panel-layout/PanelLayout';
import casparcgAvatar from '../../img/casparcg-avatar.svg';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../redux/AppState';
import { LOOPIC_CONFIG } from '../../config/LOOPIC_CONFIG';
import { AuthService } from '../../services/AuthService';
import { setModalAC } from '../../redux/reducers/appReducer';
import { SITEMAP } from '../../config/SITEMAP';
import { useHistory } from 'react-router-dom';
import { compSelector } from '../../redux/selectors/compositionSelector';
import { projectSelector } from '../../redux/selectors/projectSelector';
import classNames from 'classnames';
import { useState } from 'react';
import smartBtnLoader from '../../img/loaders/smart-btn-loader.svg';
import { HTMLTemplateExporter } from '../../packages/html-template-exporter/HTMLTemplateExporter';

export const CasparCGPanel = () => {
  const project = useSelector(projectSelector);
  const composition = useSelector(compSelector);
  const user = useSelector((state: AppState) => state.user);
  const app = useSelector((state: AppState) => state.app);
  const allowWithoutReg = LOOPIC_CONFIG.allowExportWithoutRegistration;
  const isLoggedIn = AuthService.isLoggedIn(user);
  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  return (
    <PanelLayout id="casparcg-panel" title="CasparCG Export">
      <button
        id="casparcg-export"
        className={classNames({ btn: true, loading })}
        disabled={loading}
        onClick={async () => {
          if (allowWithoutReg === true || (allowWithoutReg === false && isLoggedIn === true)) {
            try {
              setLoading(true);
              await HTMLTemplateExporter.exportComposition(project, composition.id);
              setLoading(false);
            } catch (error) {
              setLoading(false);
            }
          } else {
            dispatch(
              setModalAC({
                title: 'Log in required',
                icon: 'warning',
                content: 'You must log in in order to export composition as CasparCG graphic.',
                controls: [
                  {
                    label: 'Log in',
                    onClick: () => {
                      dispatch(setModalAC(undefined));
                      history.push(SITEMAP.LOGIN);
                    },
                  },
                  { label: 'Cancel', onClick: () => dispatch(setModalAC(undefined)) },
                ],
              }),
            );
          }
        }}
      >
        <div className="loader">
          <img src={smartBtnLoader} />
        </div>
        <img src={casparcgAvatar} alt="CasparCG Avatar" />
        <span>Export as CasparCG Graphic</span>
      </button>

      {app.outputDirectoryPath && (
        <div className="output-directory">
          <div className="path">{app.outputDirectoryPath}</div>
          <button
            className="btn"
            onClick={() => {
              HTMLTemplateExporter.setOutputDirectory();
            }}
          >
            Change directory
          </button>
        </div>
      )}
    </PanelLayout>
  );
};
