import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import produce from 'immer';
import { Action, Composition, Layer } from '../../models/composition';
import { ImageLoaderSize } from '../../models/elements';
import { Project } from '../../models/project';
import { Image, ImageSequence, Svg, Video } from '../../models/resources';
import { calculatePropertyKeyframes } from '../../services/calculateLayerOptions';

import { ColorId } from '../../services/ColorsService';
import { EasingService } from '../../services/EasingService';
import { PropertyId, PropertySettingsService, PropertyValue } from '../../services/PropertySettingsService';
import {
  constructNewDynamicId,
  constructNewLayerName,
  getActiveComposition,
  getLayersWithDefaultOrCalculatedValues,
  getLayerWithDefaultOrCalculatedValues,
} from '../helpers';

export type SetPropertyValuePayload = {
  layerId: number;
  propertyId: PropertyId;
  frame: number;
  value: PropertyValue;
  asKeyframe?: boolean;
};

const projectSlice = createSlice({
  name: 'project',
  initialState: {} as Project,
  reducers: {
    setProjectAC: (state, { payload }: PayloadAction<{ project: Project }>) => {
      const newState = produce(payload.project, (draftState) => {
        for (const comp of draftState.compositions) {
          comp.layers = getLayersWithDefaultOrCalculatedValues(comp.layers);
        }
      });

      return newState;
    },

    setActiveCompositionAC: (state, { payload }: PayloadAction<number>) => {
      state.activeCompositionId = payload;
    },

    setProjectNameAC: (state, { payload }: PayloadAction<string>) => {
      state.name = payload;
    },

    addNewCompositionAC: (state, { payload }: PayloadAction<Composition>) => {
      ++state.lastCompositionId;
      state.compositions.push(payload);
    },

    removeCompositionAC: (state, { payload }: PayloadAction<number>) => {
      state.compositions = state.compositions.filter((comp) => comp.id !== payload);
    },

    remove: (state) => {
      state = {} as Project;
    },

    addOrUpdateProjectCompositionAC: (state, { payload }: PayloadAction<{ composition: Composition }>) => {
      const foundIndex = state.compositions.findIndex((composition) => composition.id === payload.composition.id);
      if (foundIndex > -1) {
        state.compositions[foundIndex] = payload.composition;
      } else {
        state.compositions.push(payload.composition);
      }
    },

    addFontAC: (state, { payload }: PayloadAction<{ name: string; content: string }>) => {
      state.resources.fonts.push({ id: ++state.resources.lastFontId, name: payload.name, content: payload.content });
    },

    removeFontAC: (state, { payload }: PayloadAction<number>) => {
      state.resources.fonts = state.resources.fonts.filter((font) => font.id !== payload);
    },

    addImageSequenceAC: (state, { payload }: PayloadAction<ImageSequence>) => {
      ++state.resources.lastImageSequenceId;
      state.resources.imageSequences.push(payload);
    },

    removeImageSequenceAC: (state, { payload }: PayloadAction<number>) => {
      state.resources.imageSequences = state.resources.imageSequences.filter((is) => is.id !== payload);
    },

    addImageAC: (state, { payload }: PayloadAction<Image>) => {
      ++state.resources.lastImageId;
      state.resources.images.push(payload);
    },

    removeImageAC: (state, { payload }: PayloadAction<number>) => {
      state.resources.images = state.resources.images.filter((img) => img.id !== payload);
    },

    addVideoAC: (state, { payload }: PayloadAction<Video>) => {
      ++state.resources.lastVideoId;
      state.resources.videos.push(payload);
    },

    removeVideoAC: (state, { payload }: PayloadAction<number>) => {
      state.resources.videos = state.resources.videos.filter((video) => video.id !== payload);
    },

    addSvgAC: (state, { payload }: PayloadAction<Svg>) => {
      if (!state.resources.lastSvgId) {
        state.resources.lastSvgId = 0;
      }
      ++state.resources.lastSvgId;
      if (!state.resources.svgs) {
        state.resources.svgs = [payload];
      } else {
        state.resources.svgs.push(payload);
      }
    },

    removeSvgAC: (state, { payload }: PayloadAction<number>) => {
      state.resources.svgs = state.resources.svgs.filter((svg) => svg.id !== payload);
    },

    removeProjectLayerAC: (state, { payload }: PayloadAction<{ compositionId: number; layerId: number }>) => {
      const found = state.compositions.find((composition) => (composition.id = payload.compositionId));
      if (!found) return;
      found.layers = found.layers.filter((layer) => layer.id !== payload.layerId);
    },

    /**
     * Composition settings
     */
    setCompositionPanelHeightAC: (state, { payload }: PayloadAction<number>) => {
      const actComp = getActiveComposition(state);
      actComp.compositionPanelHeight = payload;
    },

    setActiveFrameAC: (state, { payload }: PayloadAction<{ activeFrame: number }>) => {
      const actComp = getActiveComposition(state);
      actComp.activeFrame = payload.activeFrame;
    },

    setCompositionScaleAC: (state, { payload }: PayloadAction<{ scale: number }>) => {
      const actComp = getActiveComposition(state);
      actComp.scale = payload.scale;
    },

    setCompositionNameAC: (state, { payload }: PayloadAction<string>) => {
      const actComp = getActiveComposition(state);
      actComp.name = payload;
    },

    setCompositionWorkspaceDurationAC: (state, { payload }: PayloadAction<{ workspaceDuration: number }>) => {
      const actComp = getActiveComposition(state);
      actComp.workspaceDuration = payload.workspaceDuration;
    },

    setCompositionDurationAC: (state, { payload }: PayloadAction<{ duration: number }>) => {
      const actComp = getActiveComposition(state);
      actComp.duration = payload.duration;
    },

    setCompositionSettingsAC: (state, { payload }: PayloadAction<{ width: number; height: number; fps: number; duration: number }>) => {
      const actComp = getActiveComposition(state);
      actComp.width = payload.width;
      actComp.height = payload.height;
      actComp.fps = payload.fps;

      if (payload.duration < actComp.workspaceDuration) {
        actComp.duration = actComp.workspaceDuration;
      } else {
        actComp.duration = payload.duration;
      }
    },

    setCompositionBackgroundAC: (state, { payload }: PayloadAction<{ background: string }>) => {
      const actComp = getActiveComposition(state);
      actComp.background = payload.background;
    },

    /**
     * Layer settings
     */

    setLayerNameAC: (state, { payload }: PayloadAction<{ layerId: number; name: string }>) => {
      const actComp = getActiveComposition(state);
      const layer = actComp.layers.find((layer) => layer.id === payload.layerId);
      if (layer) layer.name = payload.name;
    },

    addLayerAC: (state, { payload }: PayloadAction<Layer>) => {
      const actComp = getActiveComposition(state);
      ++actComp.lastLayerId;
      actComp.layers.unshift(getLayerWithDefaultOrCalculatedValues(payload));
    },

    setLayerIndexAC: (state, { payload }: PayloadAction<{ layerId: number; destinationIndex: number; sourceIndex: number }>) => {
      const actComp = getActiveComposition(state);

      const layer = actComp.layers.find((layer) => layer.id === payload.layerId);
      if (!layer) return;

      if (payload.destinationIndex < payload.sourceIndex) {
        actComp.layers.splice(payload.destinationIndex, 0, layer);
        actComp.layers.splice(payload.sourceIndex + 1, 1);
      } else {
        actComp.layers.splice(payload.destinationIndex + 1, 0, layer);
        actComp.layers.splice(payload.sourceIndex, 1);
      }
    },

    setElementDynamicIdAC: (state, { payload }: PayloadAction<{ layerId: number; dynamicId: string }>) => {
      const actComp = getActiveComposition(state);

      const layer = actComp.layers.find((layer) => layer.id === payload.layerId);
      if (!layer) return;
      layer.element.dynamicId = payload.dynamicId;
    },

    setTextElementContentAC: (state, { payload }: PayloadAction<{ layerId: number; content: string }>) => {
      const actComp = getActiveComposition(state);

      const layer = actComp.layers.find((layer) => layer.id === payload.layerId);
      if (!layer) return;
      if (layer.element.type !== 'TEXT') return;

      layer.element.content = payload.content;
    },

    toggleTextElementAutoShrinkAC: (state, { payload }: PayloadAction<{ layerId: number; disable: boolean }>) => {
      const actComp = getActiveComposition(state);

      const layer = actComp.layers.find((layer) => layer.id === payload.layerId);
      if (!layer) return;
      if (layer.element.type !== 'TEXT') return;

      layer.element.disableAutoShrink = !payload.disable;
    },

    removeLayerAC: (state, { payload }: PayloadAction<{ layerId: number }>) => {
      const actComp = getActiveComposition(state);

      actComp.layers = actComp.layers.filter((layer) => layer.id !== payload.layerId);
    },

    duplicateLayerAC: (state, { payload }: PayloadAction<{ layerIds: number[] }>) => {
      const actComp = getActiveComposition(state);

      const layers = actComp.layers.filter((layer) => payload.layerIds.includes(layer.id));
      for (const layer of layers) {
        const newLayer = { ...layer, element: { ...layer.element } };
        newLayer.id = ++actComp.lastLayerId;
        newLayer.name = constructNewLayerName(newLayer.name);
        newLayer.element.dynamicId = constructNewDynamicId(newLayer.element.dynamicId);
        actComp.layers.unshift(newLayer);
        actComp.selectedLayers.push(newLayer.id);
        // Deselect original layer
        actComp.selectedLayers = actComp.selectedLayers.filter((id) => id !== layer.id);
      }
    },

    reverseLayersAC: (state, { payload }: PayloadAction<{ layerIds: number[] }>) => {
      const actComp = getActiveComposition(state);

      actComp.layers = actComp.layers.map((layer) => {
        if (payload.layerIds.indexOf(layer.id) > -1) {
          if (layer.element.type === 'IMAGE_SEQUENCE') {
            layer.element.imageIds = layer.element.imageIds.reverse();
          }
        }
        return layer;
      });
    },

    changeLayerColorAC: (state, { payload }: PayloadAction<{ layerId: number; color: ColorId }>) => {
      const actComp = getActiveComposition(state);

      actComp.layers = actComp.layers.map((layer) => {
        if (layer.id === payload.layerId) layer.color = payload.color;
        return layer;
      });
    },

    /**
     * Selecting layers.
     * LayerId - ID of the layer to select.
     * Single? - if only this layer should be selected
     */
    selectLayerAC: (state, { payload }: PayloadAction<{ layerId: number; single?: boolean }>) => {
      const actComp = getActiveComposition(state);

      if (payload.single) {
        actComp.selectedLayers = [payload.layerId];
      } else {
        actComp.selectedLayers.push(payload.layerId);
      }
    },

    deselectLayerAC: (state, { payload }: PayloadAction<{ layerId: number }>) => {
      const newState = produce(state, (draftState) => {
        const actComp = getActiveComposition(draftState);
        actComp.selectedLayers = actComp.selectedLayers.filter((id) => id !== payload.layerId);
      });
      return newState;
    },

    deselectAllLayersAC: (state, { payload }: PayloadAction) => {
      const newState = produce(state, (draftState) => {
        const actComp = getActiveComposition(draftState);
        actComp.selectedLayers = [];
      });
      return newState;
    },

    toggleOpenLayerAC: (state, { payload }: PayloadAction<{ layerIds: number[]; open: boolean }>) => {
      const actComp = getActiveComposition(state);

      const layers = actComp.layers.filter((layer) => payload.layerIds.includes(layer.id));
      for (const layer of layers) {
        layer.open = payload.open;
      }
    },

    toggleOpenPropertyGroupAC: (state, { payload }: PayloadAction<{ layerId: number; propertyGroupId: string; open: boolean }>) => {
      const actComp = getActiveComposition(state);

      const layer = actComp.layers.find((layer) => layer.id === payload.layerId);
      if (!layer) return;

      if (payload.open) {
        layer.openPropertyGroups.push(payload.propertyGroupId);
      } else {
        layer.openPropertyGroups = layer.openPropertyGroups.filter((opg) => opg !== payload.propertyGroupId);
      }
    },

    toggleLockedLayerAC: (state, { payload }: PayloadAction<{ layerId: number; locked: boolean }>) => {
      const actComp = getActiveComposition(state);

      const layer = actComp.layers.find((layer) => layer.id === payload.layerId);
      if (!layer) return;
      layer.locked = payload.locked;
    },

    toggleLayerVisibilityAC: (state, { payload }: PayloadAction<{ layerId: number; visible: boolean }>) => {
      const actComp = getActiveComposition(state);

      const layer = actComp.layers.find((layer) => layer.id === payload.layerId);
      if (layer) layer.visible = payload.visible;
    },

    toggleLayerGuideAC: (state, { payload }: PayloadAction<{ layerId: number; guide: boolean }>) => {
      const actComp = getActiveComposition(state);

      const layer = actComp.layers.find((layer) => layer.id === payload.layerId);
      if (layer) layer.guide = payload.guide;
    },

    moveLayerAC: (state, { payload }: PayloadAction<{ layerId: number; startFrame: number }>) => {
      const actComp = getActiveComposition(state);

      const layer = actComp.layers.find((layer) => layer.id === payload.layerId);
      if (layer) layer.startFrame = payload.startFrame;
    },

    setLayerStartAndDurationAC: (
      state,
      { payload }: PayloadAction<{ layerId: number; startFrame: number; duration: number; imageIds?: number[] }>,
    ) => {
      const actComp = getActiveComposition(state);

      const layer = actComp.layers.find((layer) => layer.id === payload.layerId);
      if (!layer) return;

      if (payload.duration < 1) return;

      const oldStartFrame = layer.startFrame;
      const oldDuration = layer.duration;

      if (oldStartFrame !== payload.startFrame) {
        /**
         * Moving start edge
         */
        const diff = oldStartFrame - payload.startFrame;

        // Move all keyframes
        for (const [_propertyId, propertyData] of Object.entries(layer.properties)) {
          if (!propertyData) continue;
          for (const kf of propertyData?.keyframes) {
            kf.frame = kf.frame + diff;
          }
        }

        if (layer.element.type === 'IMAGE_SEQUENCE' && payload.imageIds) {
          if (diff < 0) {
            // Shrinking to the right
            for (let i = 0; i < diff * -1; i++) {
              layer.element.imageIds.shift(); // Pop first element
            }
          } else {
            // Sizing up to the left
            for (let i = 0; i < diff; i++) {
              const firstImageId = layer.element.imageIds[0];
              const foundIdx = payload.imageIds.findIndex((imgId) => imgId === firstImageId);
              if (foundIdx > 0) {
                layer.element.imageIds.unshift(payload.imageIds[foundIdx - 1]);
              } else if (foundIdx === 0) {
                layer.element.imageIds.unshift(firstImageId);
              }
            }
          }
        }
      } else if (oldDuration !== payload.duration) {
        /**
         * Moving end edge
         */
        const diff = payload.duration - oldDuration;

        if (layer.element.type === 'IMAGE_SEQUENCE' && payload.imageIds) {
          if (diff < 0) {
            // Shrinking to the left
            for (let i = 0; i < diff * -1; i++) {
              layer.element.imageIds.pop(); // Pop last element
            }
          } else {
            // Sizing up to the right
            for (let i = 0; i < diff; i++) {
              const lastImageId = layer.element.imageIds[layer.element.imageIds.length - 1];
              const foundIdx = payload.imageIds.findIndex((imgId) => imgId === lastImageId);
              if (foundIdx < payload.imageIds.length - 1) {
                layer.element.imageIds.push(payload.imageIds[foundIdx + 1]);
              } else if (foundIdx === payload.imageIds.length - 1) {
                layer.element.imageIds.push(lastImageId);
              }
            }
          }
        }
      }

      layer.startFrame = payload.startFrame;
      layer.duration = payload.duration;

      const newProps = getLayerWithDefaultOrCalculatedValues(layer);
      layer.properties = newProps.properties;
    },

    setPropertyCalculatedKeyframesAC: (
      state,
      { payload }: PayloadAction<{ layerId: number; propertyId: PropertyId; calculatedKeyframes: Keyframe[] }>,
    ) => {
      const actComp = getActiveComposition(state);

      const layer = actComp.layers && actComp.layers.find((layer) => layer.id === payload.layerId);
      if (!layer) return;
      const property = layer.properties[payload.propertyId];
      if (!property) return;

      property.calculatedKeyframes = calculatePropertyKeyframes(layer, payload.propertyId, property);
    },

    /**
     * Actions
     */

    addActionAC: (state, { payload }: PayloadAction<{ frame: number; code: string; outro: boolean }>) => {
      const actComp = getActiveComposition(state);

      const newAction: Action = {
        id: ++actComp.lastLayerId,
        frame: payload.frame,
        code: payload.code,
        outro: payload.outro,
      };
      actComp.actions.push(newAction);
    },

    toggleActionOutroAC: (state, { payload }: PayloadAction<{ actionId: number; outro: boolean }>) => {
      const actComp = getActiveComposition(state);

      const action = actComp.actions.find((action) => action.id === payload.actionId);
      if (action) action.outro = payload.outro;
    },

    setActionFrameAC: (state, { payload }: PayloadAction<{ actionId: number; frame: number }>) => {
      const actComp = getActiveComposition(state);

      const action = actComp.actions.find((action) => action.id === payload.actionId);
      if (action) action.frame = payload.frame;
    },

    setActionCodeAC: (state, { payload }: PayloadAction<{ actionId: number; code: string }>) => {
      const actComp = getActiveComposition(state);

      const action = actComp.actions.find((action) => action.id === payload.actionId);
      if (action) action.code = payload.code;
    },

    removeActionAC: (state, { payload }: PayloadAction<{ actionId: number }>) => {
      const actComp = getActiveComposition(state);

      actComp.actions = actComp.actions.filter((action) => action.id !== payload.actionId);
    },

    setCompositionActionCodeAC: (state, { payload }: PayloadAction<string>) => {
      const actComp = getActiveComposition(state);

      actComp.compositionAction = payload;
    },

    setPropertyValueAC: (state, { payload }: PayloadAction<SetPropertyValuePayload[]>) => {
      const linear = EasingService.findByPresetName('linear');
      const easing = {
        presetName: linear.presetName,
        p1x: linear.p1x,
        p1y: linear.p1y,
        p2x: linear.p2x,
        p2y: linear.p2y,
      };

      const newState = produce(state, (draftState) => {
        for (const singlePayload of payload) {
          const propertySettings = PropertySettingsService.getSettings(singlePayload.propertyId);
          if (!propertySettings) return;

          const actComp = getActiveComposition(draftState);

          const layer = actComp.layers.find((layer) => layer.id === singlePayload.layerId);
          if (!layer) return;

          const property = layer.properties[singlePayload.propertyId];
          if (!property) return;

          let newValue = singlePayload.value;

          if (property.keyframes.length === 0 && !singlePayload.asKeyframe) {
            // * Set the property value only
            property.value = newValue;
          } else {
            // * Add new or edit keyframe
            // Find the correct spot to insert
            let idx = 0;
            let gotcha = false;
            for (const keyframe of property.keyframes) {
              if (keyframe.frame === singlePayload.frame) {
                keyframe.value = newValue;
                gotcha = true;
                break;
              } else if (keyframe.frame > singlePayload.frame) {
                property.keyframes.splice(idx, 0, {
                  id: actComp.lastKeyframeId + 1,
                  frame: singlePayload.frame,
                  value: newValue,
                  easing,
                });
                actComp.lastKeyframeId += 1;
                gotcha = true;
                break;
              }
              ++idx;
            }
            // or, Insert at the end
            if (!gotcha) {
              property.keyframes.push({
                id: actComp.lastKeyframeId + 1,
                frame: singlePayload.frame,
                value: newValue,
                easing,
              });
              actComp.lastKeyframeId += 1;
            }
          }
          property.calculatedKeyframes = calculatePropertyKeyframes(layer, singlePayload.propertyId, property);
        }
      });
      return newState;
    },

    removeKeyframeAC: (state, { payload }: PayloadAction<{ layerId: number; propertyId: PropertyId; keyframeId: number }>) => {
      const actComp = getActiveComposition(state);

      const layer = actComp.layers.find((layer) => layer.id === payload.layerId);
      if (!layer) return;
      const property = layer.properties[payload.propertyId];
      if (!property) return;

      property.keyframes = property.keyframes.filter((kf) => kf.id !== payload.keyframeId);

      // Find old calculated values and replace them
      property.calculatedKeyframes = calculatePropertyKeyframes(layer, payload.propertyId, property);
    },

    setKeyframeEasingAC: (
      state,
      {
        payload,
      }: PayloadAction<{
        layerId: number;
        propertyId: PropertyId;
        keyframeId: number;
        presetName: string;
        p1x: number;
        p1y: number;
        p2x: number;
        p2y: number;
      }>,
    ) => {
      const actComp = getActiveComposition(state);

      const layer = actComp.layers.find((layer) => layer.id === payload.layerId);
      if (!layer) return;
      const property = layer.properties[payload.propertyId];
      if (!property) return;

      const found = property.keyframes.find((kf) => kf.id === payload.keyframeId);
      if (!found) return;

      found.easing = { presetName: payload.presetName, p1x: payload.p1x, p1y: payload.p1y, p2x: payload.p2x, p2y: payload.p2y };

      // Find old calculated values and replace them
      property.calculatedKeyframes = calculatePropertyKeyframes(layer, payload.propertyId, property);
    },

    moveKeyframeAC: (state, { payload }: PayloadAction<{ layerId: number; propertyId: PropertyId; keyframeId: number; frame: number }>) => {
      const actComp = getActiveComposition(state);

      const layer = actComp.layers.find((layer) => layer.id === payload.layerId);
      if (!layer) return;
      const property = layer.properties[payload.propertyId];
      if (!property) return;
      const keyframe = property.keyframes.find((kf) => kf.id === payload.keyframeId);
      if (!keyframe) return;

      // If there is already a keyframe on this frame, skip
      for (const keyframeItem of property.keyframes) {
        if (keyframeItem.frame === payload.frame) return state;
      }

      // Change frame
      keyframe.frame = payload.frame;

      // Sort the array - it is important that keyframes are in the ascending order like their frame numbers
      property.keyframes.sort(function (a, b) {
        return a.frame - b.frame;
      });

      // Find old calculated values and replace them
      property.calculatedKeyframes = calculatePropertyKeyframes(layer, payload.propertyId, property);
    },

    setImageLoaderPlaceholderIdAC: (state, { payload }: PayloadAction<{ layerId: number; placeholderId: number }>) => {
      const actComp = getActiveComposition(state);

      const found = actComp.layers.find((layer) => layer.id === payload.layerId);
      if (!found) return;
      if (found.element.type !== 'IMAGE_LOADER') return;
      found.element.placeholderId = payload.placeholderId;
    },

    setImageLoaderSizeAC: (state, { payload }: PayloadAction<{ layerId: number; size: ImageLoaderSize }>) => {
      const actComp = getActiveComposition(state);

      const found = actComp.layers.find((layer) => layer.id === payload.layerId);
      if (!found) return;
      if (found.element.type !== 'IMAGE_LOADER') return;
      found.element.size = payload.size;
    },
  },
});

export const {
  setProjectAC,
  setActiveCompositionAC,
  setProjectNameAC,
  addNewCompositionAC,
  removeCompositionAC,
  addOrUpdateProjectCompositionAC,
  addFontAC,
  removeFontAC,
  addImageSequenceAC,
  removeImageSequenceAC,
  addImageAC,
  removeImageAC,
  addVideoAC,
  removeVideoAC,
  addSvgAC,
  removeSvgAC,
  removeProjectLayerAC,
  addLayerAC,
  setCompositionPanelHeightAC,
  setCompositionSettingsAC,
  setCompositionBackgroundAC,
  setActiveFrameAC,
  setCompositionScaleAC,
  setCompositionNameAC,
  setCompositionWorkspaceDurationAC,
  setCompositionDurationAC,
  setLayerNameAC,
  setLayerIndexAC,
  setElementDynamicIdAC,
  setTextElementContentAC,
  toggleTextElementAutoShrinkAC,
  removeLayerAC,
  duplicateLayerAC,
  changeLayerColorAC,
  reverseLayersAC,
  selectLayerAC,
  deselectLayerAC,
  deselectAllLayersAC,
  moveLayerAC,
  setLayerStartAndDurationAC,
  toggleOpenLayerAC,
  toggleOpenPropertyGroupAC,
  toggleLockedLayerAC,
  toggleLayerVisibilityAC,
  toggleLayerGuideAC,
  setPropertyCalculatedKeyframesAC,
  setPropertyValueAC,
  removeKeyframeAC,
  setKeyframeEasingAC,
  moveKeyframeAC,
  addActionAC,
  toggleActionOutroAC,
  setActionFrameAC,
  setActionCodeAC,
  removeActionAC,
  setCompositionActionCodeAC,
  setImageLoaderPlaceholderIdAC,
  setImageLoaderSizeAC,
} = projectSlice.actions;
export const projectReducer = projectSlice.reducer;
