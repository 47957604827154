import { AppState } from '../AppState';

export const projectSelector = (state: AppState) => {
  return state.project.present;
};

export const isProjectUndoableSelector = (state: AppState) => {
  const project = state.project;
  return project.past.length <= 1;
};

export const isProjectRedoableSelector = (state: AppState) => {
  const project = state.project;
  return project.future.length < 1;
};
